import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { NotificationsIcon, NotificationsActiveIcon } from "./Icons";

interface NotificationsSectionProps {
    notificationsCount: number;
    open: boolean;
}

export function NotificationsSection({
    notificationsCount,
    open,
}: NotificationsSectionProps) {
    return (
        <ListItem
            key={"Notificaciones"}
            disablePadding
            sx={{ display: "block" }}
        >
            <Tooltip title="Notificaciones" placement="right" arrow>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {notificationsCount > 0 ? (
                            <Box sx={{ position: "relative" }}>
                                <NotificationsActiveIcon
                                    sx={{
                                        transform: "translateY(3px)",
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: "14px",
                                        height: "14px",
                                        position: "absolute",
                                        top: -5,
                                        right: -7,
                                        borderRadius: "50%",
                                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "10px",
                                            color: "white",
                                            textAlign: "center",
                                        }}
                                    >
                                        {notificationsCount}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <NotificationsIcon />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={"Notificaciones"}
                        sx={{ opacity: open ? 1 : 0 }}
                    />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}
