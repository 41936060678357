import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
    AddRoadIcon,
    StorefrontIcon,
    LocalShippingIcon,
    LocalPostOfficeIcon,
} from "./Icons";

interface CarrierSectionProps {
    open: boolean;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function CarrierSection({
    open,
    currentPathLocation,
    changeLocation,
}: CarrierSectionProps) {
    return (
        <>
            <List>
                <ListItem key={"Ruta"} disablePadding sx={{ display: "block" }}>
                    <Tooltip title="Verificar Ruta" placement="right" arrow>
                        <ListItemButton
                            selected={currentPathLocation === "/carrier/route"}
                            onClick={() => changeLocation("/carrier/route")}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <AddRoadIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Ruta"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Órdenes de Recogida"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Órdenes de Recogida de Clientes"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/carrier/pickups"
                            }
                            onClick={() => changeLocation("/carrier/pickups")}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <StorefrontIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Recogida"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Órdenes de Envío"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Órdenes de Envío a Clientes Finales"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/carrier/deliveries"
                            }
                            onClick={() =>
                                changeLocation("/carrier/deliveries")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <LocalShippingIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Envío"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Historial de Actividades"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Historial de Actividades"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/carrier/activity-history"
                            }
                            onClick={() =>
                                changeLocation("/carrier/activity-history")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <LocalPostOfficeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Historial de Actividades"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>

            <Divider />
        </>
    );
}
