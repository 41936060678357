import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import MainLogo from "@/shared/assets/logiflex_logo_v1.png";
import { useToast } from "@/shared/utils/classes";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box,
    InputLabel,
    Button,
    FormGroup,
    TextField,
    Typography,
} from "@mui/material";
import { UpdatePassword } from "@/central-app/actions/update-password/UpdatePassword.action";
import { UpdatePasswordResponseInterface } from "@/central-app/actions/update-password/UpdatePasswordResponse.interface";

//Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

interface SetPasswordBodyProps {
    platformTheme: PlatformTheme;
}

export default function SetPasswordBody({
    platformTheme,
}: SetPasswordBodyProps) {
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isUpdatingPassword, setIsUpdatingPassword] =
        useState<boolean>(false);
    const [passwordHasDigit, setPasswordHasDigit] = useState<boolean>(false);
    const [passwordHasLowerCase, setPasswordHasLowerCase] =
        useState<boolean>(false);
    const [passwordHasUpperCase, setPasswordHasUpperCase] =
        useState<boolean>(false);
    const [passwordHasCorrectLength, setPasswordHasCorrectLength] =
        useState<boolean>(false);
    const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] =
        useState<boolean>(false);
    const [passwordAreEqual, setPasswordAreEqual] = useState<boolean>(false);

    const { accessToken } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsUpdatingPassword(true);
        const isFormValid = checkIfFormIsValid();

        if (isFormValid) {
            await updatePassword();
        } else {
            useToast.toastRequest({
                message:
                    "Verifica los campos y asegúrate de que las contraseñas coincidan",
                type: "warning",
            });
        }

        setIsUpdatingPassword(false);
    };

    const changeLocation = (path: string): void => {
        navigate(path);
    };

    const updatePassword = async (): Promise<void> => {
        console.log(accessToken);

        const { httpStatusCode }: UpdatePasswordResponseInterface =
            await useToast.toastPromiseRequest({
                promise: UpdatePassword({
                    accessToken: accessToken || "",
                    password,
                }),
                pendingMessage:
                    "Actualizando tu contraseña. Por favor, espera un momento.",
                successMessage: "Tu contraseña ha sido actualizada con éxito.",
            });

        handleUpdatePasswordResult(httpStatusCode);
    };

    const handleUpdatePasswordResult = (httpStatusCode: number) => {
        if (httpStatusCode === 201) {
            changeLocation("/");
        }

        if (httpStatusCode === 410) {
            changeLocation("/recover-password");
        }
    };

    const checkIfFormIsValid = (): boolean => {
        if (!passwordHasDigit) {
            return false;
        }

        if (!passwordHasCorrectLength) {
            return false;
        }

        if (!passwordHasLowerCase) {
            return false;
        }

        if (!passwordHasUpperCase) {
            return false;
        }

        if (!passwordHasSpecialCharacter) {
            return false;
        }

        if (!passwordAreEqual) {
            return false;
        }

        if (!verifyPassword(password)) {
            return false;
        }

        return true;
    };

    const verifyPassword = (password: string): boolean => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,128}$/;
        return passwordRegex.test(password);
    };

    const validatePassword = (password: string): void => {
        setPassword(password);

        checkIfPasswordHasDigit(password);
        checkIfPasswordHasLowerCase(password);
        checkIfPasswordHasUpperCase(password);
        checkIfPasswordHasCorrectLength(password);
        checkIfPasswordHasSpecialCharacter(password);
    };

    const checkIfPasswordHasDigit = (password: string): void => {
        const digitRegex = /\d/;
        setPasswordHasDigit(digitRegex.test(password));
    };

    const checkIfPasswordHasLowerCase = (password: string): void => {
        const lowerCaseRegex = /[a-z]/;
        setPasswordHasLowerCase(lowerCaseRegex.test(password));
    };

    const checkIfPasswordHasUpperCase = (password: string): void => {
        const upperCaseRegex = /[A-Z]/;
        setPasswordHasUpperCase(upperCaseRegex.test(password));
    };

    const checkIfPasswordHasCorrectLength = (password: string): void => {
        setPasswordHasCorrectLength(
            password.length >= 8 && password.length <= 128
        );
    };

    const checkIfPasswordHasSpecialCharacter = (password: string): void => {
        const specialCharacterRegex = /(?=.*[!@#$%^&*(),.?":{}|<>])/;
        setPasswordHasSpecialCharacter(specialCharacterRegex.test(password));
    };

    const checkIfPasswordsAreEqual = (confirmPassword: string): void => {
        setConfirmPassword(confirmPassword);

        setPasswordAreEqual(
            password === confirmPassword && confirmPassword !== ""
        );
    };

    return (
        <>
            <Box
                className={`set-password__set-password-form ${platformTheme === PlatformTheme.LIGHT ? "set-password__recover-set-form-light" : "set-password__recover-set-form-dark"}`}
                sx={{
                    minWidth: "510px",
                }}
            >
                <Box
                    className="set-password-form__set-password-image-container"
                    sx={{ marginBottom: "15px" }}
                >
                    <img
                        className="set-password-form__set-password-image"
                        src={MainLogo}
                        alt="Logiflex Logo"
                        style={{ width: "100%", height: "100%" }}
                    />
                </Box>

                <Box className="recover-password-form__subtitle-container">
                    <Typography
                        className={`recover-password-form__title ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        sx={{
                            fontSize: "25px",
                        }}
                    >
                        ¡Casi listo!
                    </Typography>

                    <Typography
                        className={`recover-password-form__subtitle ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        sx={{
                            fontSize: "20px",
                            maxWidth: "90%",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        Ingresa tu nueva contraseña y estarás listo.
                    </Typography>
                </Box>

                <Box
                    className="recover-password-form__container"
                    sx={{
                        marginTop: "10px",
                    }}
                >
                    <FormGroup className="recover-password-form__input-container">
                        <InputLabel
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            htmlFor="recover-password-form__email-input"
                            sx={{
                                marginTop: "15px",
                            }}
                        >
                            Nueva Contraseña
                        </InputLabel>

                        <TextField
                            id="recover-password-form__email-input"
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            placeholder="Introduce tu nueva contraseña"
                            variant="standard"
                            type="password"
                            onChange={(e) => validatePassword(e.target.value)}
                            disabled={isUpdatingPassword}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit();
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="recover-password-form__input-container">
                        <InputLabel
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            htmlFor="recover-password-form__email-input"
                            sx={{
                                marginTop: "20px",
                            }}
                        >
                            Confirmar Contraseña
                        </InputLabel>

                        <TextField
                            id="recover-password-form__email-input"
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            placeholder="Introduce tu contraseña nuevamente"
                            variant="standard"
                            type="password"
                            onChange={(e) =>
                                checkIfPasswordsAreEqual(e.target.value)
                            }
                            disabled={isUpdatingPassword}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit();
                            }}
                        />
                    </FormGroup>

                    <Box
                        sx={{
                            width: "95%",
                            marginTop: "25px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                display: "grid",
                                width: "95%",
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridTemplateColumns: "1fr 1fr",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {passwordHasLowerCase ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Al menos una letra minúscula
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                {passwordHasDigit ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Al menos un dígito (número)
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "grid",
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridTemplateColumns: "1fr 1fr",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {passwordHasUpperCase ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Al menos una letra mayúscula
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                {passwordHasCorrectLength ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Entre 8 y 128 caracteres
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "grid",
                                width: "95%",
                                marginTop: "10px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                gridTemplateColumns: "1fr 1fr",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {passwordHasSpecialCharacter ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Al menos un carácter especial
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                {passwordAreEqual ? (
                                    <CheckCircleIcon sx={{ fill: "#6ADA84" }} />
                                ) : (
                                    <RadioButtonUncheckedIcon
                                        sx={{ fill: "#6ADA84" }}
                                    />
                                )}

                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#FFFFFF"}`,
                                    }}
                                >
                                    Las contraseñas coinciden
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <FormGroup className="recover-password-form__recover-password-button-container">
                        <Button
                            className={`recover-password-form__recover-password-button-container ${platformTheme === PlatformTheme.LIGHT ? "recover-password-form__recover-password-button-light-theme" : "recover-password-form__recover-password-button-dark-theme"}`}
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isUpdatingPassword}
                        >
                            Restablecer Contraseña
                        </Button>
                    </FormGroup>
                </Box>
            </Box>
        </>
    );
}
