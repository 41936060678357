import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { MoreHorizIcon } from "./Icons";
import { UserDetails } from "./UserDetails";
import { ProfileMenu } from "./ProfileMenu";
import { AvatarContent } from "./AvatarContent";
import { logout } from "@/shared/utils/classes";
import { UserInterface } from "@/shared/utils/interfaces";
import { useNavigate } from "react-router-dom";

interface AvatarSectionProps {
    open: boolean;
    user: UserInterface;
}

export function AvatarSection({ open, user }: AvatarSectionProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (shouldLogout(event)) {
                logout();
                handleProfileImageClose();
            }

            if (shouldNavigateToAccountSettings(event)) {
                navigate("/account-settings");
            }

            if (shouldCloseMenu(event)) {
                handleProfileImageClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        handleProfileImageClose();
    }, [open]);

    const shouldLogout = (event: MouseEvent): boolean => {
        const currentTargetId = (event.target as HTMLElement).id;
        return currentTargetId === "profile-picture-menu-logout";
    };

    const shouldNavigateToAccountSettings = (event: MouseEvent): boolean => {
        const currentTargetId = (event.target as HTMLElement).id;
        return currentTargetId === "profile-picture-menu-account-settings";
    };

    const shouldCloseMenu = (event: MouseEvent): boolean => {
        const isOutsideFirstTarget = isOutsideMenu(
            event.target as HTMLElement,
            2
        );
        const isOutsideSecondTarget = isOutsideMenu(
            event.target as HTMLElement,
            3
        );

        return isOutsideFirstTarget && isOutsideSecondTarget;
    };

    const isOutsideMenu = (
        element: HTMLElement,
        parentLevels: number
    ): boolean => {
        let targetElement: HTMLElement | null | undefined = element;

        for (let i = 0; i < parentLevels; i++) {
            targetElement = targetElement?.parentElement;
        }

        return (
            !targetElement ||
            !targetElement.id ||
            targetElement.id !== "profile-picture-menu"
        );
    };

    const handleProfileImageClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setIsAccountMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleProfileImageClose = () => {
        setIsAccountMenuOpen(false);
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <AvatarContent
                user={user}
                onClick={handleProfileImageClick}
                open={open}
            />
            {open && <UserDetails user={user} />}
            {open && (
                <Box
                    onClick={handleProfileImageClick}
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "10px",
                        cursor: "pointer",
                    }}
                >
                    <MoreHorizIcon />
                </Box>
            )}
            <ProfileMenu
                anchorEl={anchorEl}
                asideMenuOpen={open}
                isAccountMenuOpen={isAccountMenuOpen}
                handleProfileImageClose={handleProfileImageClose}
            />
        </Box>
    );
}
