//#region Format Functions
export const formatIdentityDocumentNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setIdentityDocumentNumber: (value: string) => void
) => {
    const lastChar = event.target.value[event.target.value.length - 1];
    const penultimateChar = event.target.value[event.target.value.length - 2];
    const lastCharRegex = /^[\s\S]*[0-9kK.-]$/;

    if (
        (!lastCharRegex.test(lastChar) && event.target.value.length > 0) ||
        penultimateChar === "K" ||
        penultimateChar === "k"
    ) {
        return;
    }

    const identityDocumentNumber = event.target.value
        .replace(/\./g, "")
        .replace("-", "");

    if (identityDocumentNumber.length >= 10) {
        return;
    }

    if (identityDocumentNumber.length <= 2) {
        setIdentityDocumentNumber(identityDocumentNumber);
        return;
    }

    const identityDocumentNumberBody = identityDocumentNumber
        .slice(0, -1)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const verificationDigit = identityDocumentNumber.slice(-1).toUpperCase();

    setIdentityDocumentNumber(
        `${identityDocumentNumberBody}-${verificationDigit}`
    );
};

export const formatPassportNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setPassport: (value: string) => void
) => {
    const lastChar = event.target.value[event.target.value.length - 1];
    const lastCharRegex = /^[\s\S]*[a-zA-Z0-9]$/;

    if (!lastCharRegex.test(lastChar) && event.target.value.length > 0) {
        return;
    }

    const passport = event.target.value;

    if (passport.length > 10) {
        return;
    }

    setPassport(passport);
};

export const formatPhoneNumber = (
    phoneNumber: string,
    setPhoneNumber: (value: string) => void
) => {
    const lastChar = phoneNumber[phoneNumber.length - 1];

    if (
        !/^[0-9]$/.test(lastChar) &&
        !(phoneNumber.length === 1 && lastChar === "+") &&
        phoneNumber.length > 0
    ) {
        return;
    }

    if (phoneNumber.length > 15) {
        return;
    }

    const startsWithPlus = phoneNumber.startsWith("+");
    const cleanedNumber = startsWithPlus ? phoneNumber.slice(1) : phoneNumber;

    const numberRegex = /^[0-9]*$/;
    if (!numberRegex.test(cleanedNumber)) {
        return;
    }

    setPhoneNumber(startsWithPlus ? `+${cleanedNumber}` : cleanedNumber);
};

export const formatStreetNumber = (
    streetNumber: string,
    setStreetNumber: (value: string) => void
) => {
    if (streetNumber.length > 10) {
        return;
    }

    setStreetNumber(streetNumber);
};

export const formatApartmentNumber = (
    apartmentNumber: string,
    setApartmentNumber: (value: string) => void
) => {
    if (apartmentNumber.length > 15) {
        return;
    }

    setApartmentNumber(apartmentNumber);
};
//#endregion Format Functions

//#region Validation Functions
export const validateFirstName = (
    firstName: string,
    setFirstNameValid: (value: boolean) => void
): boolean => {
    if (!firstName || firstName.length < 2) {
        setFirstNameValid(false);
        return false;
    }

    setFirstNameValid(true);
    return true;
};

export const validateLastName = (
    lastName: string,
    setLastNameValid: (value: boolean) => void
): boolean => {
    if (!lastName || lastName.length < 2) {
        setLastNameValid(false);
        return false;
    }

    setLastNameValid(true);
    return true;
};

export const validateCompanyName = (
    companyName: string,
    setCompanyNameValid: (value: boolean) => void
): boolean => {
    if (!companyName || companyName.length < 2) {
        setCompanyNameValid(false);
        return false;
    }

    setCompanyNameValid(true);
    return true;
};

export const validateCompanyWebsite = (
    companyWebsite: string,
    setCompanyWebsiteValid: (value: boolean) => void
): boolean => {
    const websiteRegex =
        /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (companyWebsite && !websiteRegex.test(companyWebsite)) {
        setCompanyWebsiteValid(false);
        return false;
    }

    setCompanyWebsiteValid(true);
    return true;
};

export const validateCompanyId = (
    companyId: string,
    setCompanyIdValid: (value: boolean) => void
): boolean => {
    if (!companyId) {
        setCompanyIdValid(false);
        return false;
    }

    setCompanyIdValid(true);
    return true;
};

export const validateEmployeeType = (
    employeeType: string,
    setEmployeeTypeValid: (value: boolean) => void
): boolean => {
    const employeeTypes = [
        "Administrador",
        "Operador de Almacén",
        "Servicio al Cliente",
        "Transportista",
    ];

    if (!employeeType || !employeeTypes.includes(employeeType)) {
        setEmployeeTypeValid(false);
        return false;
    }

    setEmployeeTypeValid(true);
    return true;
};

export const validateEmail = (
    email: string,
    setEmailValid: (value: boolean) => void
): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        setEmailValid(false);
        return false;
    }

    setEmailValid(true);
    return true;
};

export const validateIdentityNumber = (
    identityNumber: string,
    setIdentityDocumentNumberValid: (value: boolean) => void
): boolean => {
    identityNumber = identityNumber.replace(/\./g, "").replace("-", "");

    const body = identityNumber.slice(0, -1);
    const verificationDigit = identityNumber.slice(-1).toUpperCase();

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
        sum += parseInt(body[i], 10) * multiplier;
        multiplier = multiplier < 7 ? multiplier + 1 : 2;
    }

    const mod = 11 - (sum % 11);
    const expectedDigit = mod === 11 ? "0" : mod === 10 ? "K" : mod.toString();

    if (verificationDigit !== expectedDigit) {
        setIdentityDocumentNumberValid(false);
        return false;
    }

    setIdentityDocumentNumberValid(true);
    return true;
};

export const validatePassport = (
    passport: string,
    setPassportValid: (value: boolean) => void
): boolean => {
    const passportRegex = /^[a-zA-Z0-9]{6,10}$/;

    if (!passportRegex.test(passport)) {
        setPassportValid(false);
        return false;
    }

    setPassportValid(true);

    return true;
};

export const validatePhoneNumber = (
    phoneNumber: string,
    setPhoneNumberValid: (value: boolean) => void
): boolean => {
    phoneNumber = phoneNumber.replace(/[^0-9+]/g, "");

    const phoneRegex = /^\+?[0-9]{8,15}$/;

    if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumberValid(false);
        return false;
    }

    setPhoneNumberValid(true);
    return true;
};

export const validateRegion = (
    region: string,
    setRegionValid: (value: boolean) => void
) => {
    if (!region) {
        setRegionValid(false);
        return false;
    }

    setRegionValid(true);
    return true;
};

export const validateCity = (
    city: string,
    setCityValid: (value: boolean) => void
) => {
    if (!city) {
        setCityValid(false);
        return false;
    }

    setCityValid(true);
    return true;
};

export const validateStreet = (
    street: string,
    setStreetValid: (value: boolean) => void
): boolean => {
    if (!street) {
        setStreetValid(false);
        return false;
    }

    setStreetValid(true);
    return true;
};

export const validateStreetNumber = (
    streetNumber: string,
    setStreetNumberValid: (value: boolean) => void
): boolean => {
    if (!streetNumber || streetNumber.length > 10) {
        setStreetNumberValid(false);
        return false;
    }

    setStreetNumberValid(true);
    return true;
};

export const validateApartmentNumber = (
    apartmentNumber: string,
    setApartmentNumberValid: (value: boolean) => void
): boolean => {
    if (apartmentNumber.length > 15) {
        setApartmentNumberValid(false);
        return false;
    }

    setApartmentNumberValid(true);
    return true;
};
//#endregion Validation Functions
