import { useEffect, useState } from "react";
import { useToast } from "@/shared/utils/classes";
import { CreateCompany } from "@/admin-portal/actions/create-company/CreateCompany.action";
import { CreateCompanyRequestInterface } from "@/admin-portal/actions/create-company/CreateCompanyRequest.interface";
import { CreateCompanyResponseInterface } from "@/admin-portal/actions/create-company/CreateCompanyResponse.interface";
import {
    Box,
    TextField,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
    OutlinedInput,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from "@mui/material";
import {
    formatPhoneNumber,
    formatPassportNumber,
    formatIdentityDocumentNumber,
    validateFirstName,
    validateLastName,
    validateEmail,
    validateIdentityNumber,
    validatePassport,
    validatePhoneNumber,
    validateStreet,
    formatStreetNumber,
    validateStreetNumber,
    validateApartmentNumber,
    formatApartmentNumber,
    validateRegion,
    validateCity,
    validateCompanyName,
    validateCompanyWebsite,
} from "@/admin-portal/utils/classes";
import {
    CityInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";

//Icons
import TagIcon from "@mui/icons-material/Tag";
import WebIcon from "@mui/icons-material/Web";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

interface AdminCustomerFormProps {
    creatingUser: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    cities: Map<string, CityInterface[]>;
    resetPageNumberAndFetchUsers: () => void;
    setCreatingUser: (creatingUser: boolean) => void;
    setIsCreateUserDrawerOpen: (isOpen: boolean) => void;
}

const AdminCustomerForm = ({
    regions,
    cities,
    creatingUser,
    platformTheme,
    setCreatingUser,
    setIsCreateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: AdminCustomerFormProps) => {
    //#region States
    const [companyName, setCompanyName] = useState<string>("");
    const [companyNameValid, setCompanyNameValid] = useState<boolean>(true);

    const [companyRut, setCompanyRut] = useState<string>("");
    const [companyRutValid, setCompanyRutValid] = useState<boolean>(true);

    const [companyEmail, setCompanyEmail] = useState<string>("");
    const [companyEmailValid, setCompanyEmailValid] = useState<boolean>(true);

    const [companyWebsite, setCompanyWebsite] = useState<string>("");
    const [companyWebsiteValid, setCompanyWebsiteValid] =
        useState<boolean>(true);

    const [companyRegionId, setCompanyRegionId] = useState<string>("");
    const [companyRegionIdValid, setCompanyRegionIdValid] =
        useState<boolean>(true);

    const [companyCityId, setCompanyCityId] = useState<string>("");
    const [companyCityIdValid, setCompanyCityIdValid] = useState<boolean>(true);

    const [companyStreet, setCompanyStreet] = useState<string>("");
    const [companyStreetValid, setCompanyStreetValid] = useState<boolean>(true);

    const [companyStreetNumber, setCompanyStreetNumber] = useState<string>("");
    const [companyStreetNumberValid, setCompanyStreetNumberValid] =
        useState<boolean>(true);

    const [companyApartment, setCompanyApartment] = useState<string>("");
    const [companyApartmentValid, setCompanyApartmentValid] =
        useState<boolean>(true);

    const [firstName, setFirstName] = useState<string>("");
    const [firstNameValid, setFirstNameValid] = useState<boolean>(true);

    const [lastName, setLastName] = useState<string>("");
    const [lastNameValid, setLastNameValid] = useState<boolean>(true);

    const [email, setEmail] = useState<string>("");
    const [emailValid, setEmailValid] = useState<boolean>(true);

    const [identityDocumentNumber, setIdentityDocumentNumber] =
        useState<string>("");
    const [identityDocumentNumberValid, setIdentityDocumentNumberValid] =
        useState<boolean>(true);

    const [passport, setPassport] = useState<string>("");
    const [passportValid, setPassportValid] = useState<boolean>(true);

    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);

    const [userRegionId, setUserRegionId] = useState<string>("");
    const [userRegionIdValid, setUserRegionIdValid] = useState<boolean>(true);

    const [userCityId, setUserCityId] = useState<string>("");
    const [userCityIdValid, setUserCityIdValid] = useState<boolean>(true);

    const [street, setStreet] = useState<string>("");
    const [streetValid, setStreetValid] = useState<boolean>(true);

    const [streetNumber, setStreetNumber] = useState<string>("");
    const [streetNumberValid, setStreetNumberValid] = useState<boolean>(true);

    const [apartment, setApartment] = useState<string>("");
    const [apartmentValid, setApartmentValid] = useState<boolean>(true);

    const [selectedDocumentType, setSelectedDocumentType] = useState<
        "nationalIdentityNumber" | "passport"
    >("nationalIdentityNumber");
    const [alreadyValidated, setAlreadyValidated] = useState<boolean>(false);
    //#endregion States

    useEffect(() => {
        if (creatingUser) {
            setAlreadyValidated(true);

            const isFormValid = validateAdminCustomerForm();

            if (!isFormValid) {
                useToast.toastRequest({
                    message:
                        "Por favor, revisa los campos del formulario y asegúrate de que toda la información sea correcta antes de continuar.",
                    type: "warning",
                });

                setCreatingUser(false);
                return;
            }

            createCompany({
                companyName,
                companyIdentityDocumentNumber: companyRut,
                companyEmail,
                companyWebsite,
                companyCityId,
                companyStreet,
                companyStreetNumber,
                companyApartmentNumber: companyApartment,
                adminFirstName: firstName,
                adminLastName: lastName,
                adminEmail: email,
                adminIdentityDocumentNumber:
                    selectedDocumentType === "nationalIdentityNumber"
                        ? identityDocumentNumber
                        : passport,
                adminPhoneNumber: phoneNumber,
                adminCityId: userCityId,
                adminStreet: street,
                adminStreetNumber: streetNumber,
                adminApartmentNumber: apartment,
            });
        }
    }, [creatingUser]);

    const createCompany = async (
        companyData: CreateCompanyRequestInterface
    ) => {
        const { httpStatusCode }: CreateCompanyResponseInterface =
            await useToast.toastPromiseRequest({
                promise: CreateCompany(companyData),
                pendingMessage: "Creación de empresa en progreso",
                successMessage: "Creación de empresa exitosa",
            });

        handleCompanyCreationResult(httpStatusCode);
    };

    const handleCompanyCreationResult = (httpStatusCode: number) => {
        const wasCompanyCreationSuccessful =
            checkIfCompanyCreationWasSuccessful(httpStatusCode);

        if (wasCompanyCreationSuccessful) {
            setIsCreateUserDrawerOpen(false);
            resetPageNumberAndFetchUsers();
        }

        setCreatingUser(false);
    };

    const checkIfCompanyCreationWasSuccessful = (
        httpStatusCode: number
    ): boolean => {
        if (httpStatusCode === 200 || httpStatusCode === 201) {
            return true;
        }

        return false;
    };

    const validateAdminCustomerForm = (): boolean => {
        const validations = [
            //Company
            validateCompanyName(companyName, setCompanyNameValid),
            validateIdentityNumber(companyRut, setCompanyRutValid),
            validateEmail(companyEmail, setCompanyEmailValid),
            validateCompanyWebsite(companyWebsite, setCompanyWebsiteValid),
            validateRegion(companyRegionId, setCompanyRegionIdValid),
            validateCity(companyCityId, setCompanyCityIdValid),
            validateStreet(companyStreet, setCompanyStreetValid),
            validateStreetNumber(
                companyStreetNumber,
                setCompanyStreetNumberValid
            ),
            validateApartmentNumber(companyApartment, setCompanyApartmentValid),
            //User
            validateFirstName(firstName, setFirstNameValid),
            validateLastName(lastName, setLastNameValid),
            validateEmail(email, setEmailValid),
            selectedDocumentType === "nationalIdentityNumber"
                ? validateIdentityNumber(
                      identityDocumentNumber,
                      setIdentityDocumentNumberValid
                  )
                : validatePassport(passport, setPassportValid),
            validatePhoneNumber(phoneNumber, setPhoneNumberValid),
            validateRegion(userRegionId, setUserRegionIdValid),
            validateCity(userCityId, setUserCityIdValid),
            validateStreet(street, setStreetValid),
            validateStreetNumber(streetNumber, setStreetNumberValid),
            validateApartmentNumber(apartment, setApartmentValid),
        ];

        return validations.every((isValid) => isValid);
    };

    return (
        <Box>
            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-company-name-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Nombre de la Empresa
                    </InputLabel>
                    <TextField
                        id="admin-create-user-company-name-input"
                        size="small"
                        placeholder="Ingresa el nombre"
                        autoComplete="nope"
                        value={companyName}
                        error={!companyNameValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateCompanyName(
                                    e.target.value,
                                    setCompanyNameValid
                                );
                            }

                            setCompanyName(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <BusinessIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-company-rut-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Rut de la empresa
                    </InputLabel>
                    <TextField
                        id="admin-create-user-company-rut-input"
                        size="small"
                        placeholder="Ingresa el Rut"
                        autoComplete="nope"
                        value={companyRut}
                        error={!companyRutValid}
                        onChange={(e) => {
                            const lastChar =
                                e.target.value[e.target.value.length - 1];
                            const lastCharRegex = /^[\s\S]*[0-9kK.-]$/;

                            if (
                                alreadyValidated &&
                                lastCharRegex.test(lastChar)
                            ) {
                                validateIdentityNumber(
                                    e.target.value,
                                    setCompanyRutValid
                                );
                            }

                            formatIdentityDocumentNumber(e, setCompanyRut);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RecentActorsIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-company-email-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Correo electrónico de la Empresa
                </InputLabel>
                <TextField
                    id="admin-create-user-company-email-input"
                    size="small"
                    placeholder="Ingresa el correo electrónico"
                    autoComplete="nope"
                    value={companyEmail}
                    error={!companyEmailValid}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateEmail(e.target.value, setCompanyEmailValid);
                        }

                        setCompanyEmail(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-company-website-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Website de Empresa (Opcional)
                </InputLabel>
                <TextField
                    id="admin-create-user-company-website-input"
                    size="small"
                    placeholder="Ingresa el website"
                    autoComplete="nope"
                    value={companyWebsite}
                    error={!companyWebsiteValid}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateCompanyWebsite(
                                e.target.value,
                                setCompanyWebsiteValid
                            );
                        }

                        setCompanyWebsite(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <WebIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box
                    sx={{ marginRight: "10px", maxWidth: "50%", width: "50%" }}
                >
                    <InputLabel
                        htmlFor="admin-create-user-company-region-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Región de la Empresa
                    </InputLabel>
                    <Select
                        id="admin-create-user-company-region-select"
                        displayEmpty
                        error={!companyRegionIdValid}
                        onChange={(e) => {
                            const regionId =
                                regions.find(
                                    (region) => region.name === e.target.value
                                )?.id || "";

                            if (alreadyValidated) {
                                validateRegion(
                                    regionId,
                                    setCompanyRegionIdValid
                                );
                            }

                            setCompanyRegionId(regionId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la región
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        size="small"
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la región</em>
                        </MenuItem>
                        {regions.map((region) => (
                            <MenuItem key={region.id} value={region.name}>
                                {region.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box sx={{ marginLeft: "10px", maxWidth: "50%", width: "50%" }}>
                    <InputLabel
                        htmlFor="admin-create-user-user-city-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Comuna de la Empresa
                    </InputLabel>
                    <Select
                        id="admin-create-user-user-city-select"
                        displayEmpty
                        error={!companyCityIdValid}
                        onChange={(e) => {
                            const cityId =
                                cities
                                    .get(companyRegionId!)!
                                    .find(
                                        (city) => city.name === e.target.value
                                    )?.id || "";

                            if (alreadyValidated) {
                                validateCity(cityId, setCompanyCityIdValid);
                            }

                            setCompanyCityId(cityId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la comuna
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        disabled={!companyRegionId}
                        size="small"
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && companyRegionId && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "#333333"}`,
                            },
                            "&.Mui-disabled .MuiInputBase-input": {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                                "-webkit-text-fill-color": `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la comuna</em>
                        </MenuItem>
                        {companyRegionId &&
                            cities.get(companyRegionId!)!.map((city) => (
                                <MenuItem key={city.id} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-company-street-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Calle de la empresa
                </InputLabel>
                <TextField
                    id="admin-create-user-company-street-input"
                    size="small"
                    placeholder="Ingresa la calle"
                    autoComplete="nope"
                    error={!companyStreetValid}
                    value={companyStreet}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateStreet(
                                e.target.value,
                                setCompanyStreetValid
                            );
                        }

                        setCompanyStreet(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-company-street-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número de Calle
                    </InputLabel>
                    <TextField
                        id="admin-create-user-company-street-number-input"
                        size="small"
                        placeholder="Número de calle"
                        autoComplete="nope"
                        value={companyStreetNumber}
                        error={!companyStreetNumberValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateStreetNumber(
                                    e.target.value,
                                    setCompanyStreetNumberValid
                                );
                            }

                            formatStreetNumber(
                                e.target.value,
                                setCompanyStreetNumber
                            );
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-company-apartment-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número (Apto/Oficina) (Opcional)
                    </InputLabel>
                    <TextField
                        id="admin-create-user-company-apartment-input"
                        size="small"
                        placeholder="Ingresa el número"
                        autoComplete="nope"
                        value={companyApartment}
                        error={!companyApartmentValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateApartmentNumber(
                                    e.target.value,
                                    setCompanyApartmentValid
                                );
                            }

                            formatApartmentNumber(
                                e.target.value,
                                setCompanyApartment
                            );
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Divider
                sx={{
                    marginTop: "20px",
                    borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                }}
            />

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-name-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Nombre de Administrador
                    </InputLabel>
                    <TextField
                        id="admin-create-user-name-input"
                        size="small"
                        placeholder="Ingresa el nombre"
                        autoComplete="nope"
                        error={!firstNameValid}
                        value={firstName}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateFirstName(
                                    e.target.value,
                                    setFirstNameValid
                                );
                            }

                            setFirstName(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-lastname-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Apellido de Administrador
                    </InputLabel>
                    <TextField
                        id="admin-create-user-lastname-input"
                        size="small"
                        placeholder="Ingresa el apellido"
                        autoComplete="nope"
                        error={!lastNameValid}
                        value={lastName}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateLastName(
                                    e.target.value,
                                    setLastNameValid
                                );
                            }

                            setLastName(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-email-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Email de Administrador
                </InputLabel>
                <TextField
                    id="admin-create-user-email-input"
                    size="small"
                    placeholder="Ingresa el correo electrónico"
                    autoComplete="nope"
                    error={!emailValid}
                    value={email}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateEmail(e.target.value, setEmailValid);
                        }

                        setEmail(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ display: "flex" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <RadioGroup
                        row
                        value={selectedDocumentType}
                        onChange={() =>
                            setSelectedDocumentType(
                                selectedDocumentType ===
                                    "nationalIdentityNumber"
                                    ? "passport"
                                    : "nationalIdentityNumber"
                            )
                        }
                        sx={{ marginTop: "10px" }}
                    >
                        <FormControlLabel
                            value="nationalIdentityNumber"
                            control={
                                <Radio
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                />
                            }
                            label="Rut"
                            sx={{
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                ".Mui-checked": {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                },
                            }}
                        />
                        <FormControlLabel
                            value="passport"
                            control={
                                <Radio
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                />
                            }
                            label="Pasaporte"
                            sx={{
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                ".Mui-checked": {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                },
                            }}
                        />
                    </RadioGroup>

                    {selectedDocumentType === "nationalIdentityNumber" ? (
                        <Box sx={{ width: "100%" }}>
                            <InputLabel
                                htmlFor="admin-create-user-identity-input"
                                sx={{
                                    marginBottom: "5px",
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                }}
                            >
                                Rut de Administrador
                            </InputLabel>
                            <TextField
                                id="admin-create-user-identity-input"
                                size="small"
                                placeholder="Ingresa el Rut"
                                autoComplete="nope"
                                fullWidth
                                error={!identityDocumentNumberValid}
                                value={identityDocumentNumber}
                                onChange={(e) => {
                                    const lastChar =
                                        e.target.value[
                                            e.target.value.length - 1
                                        ];
                                    const lastCharRegex = /^[\s\S]*[0-9kK.-]$/;

                                    if (
                                        alreadyValidated &&
                                        lastCharRegex.test(lastChar)
                                    ) {
                                        validateIdentityNumber(
                                            e.target.value,
                                            setIdentityDocumentNumberValid
                                        );
                                    }

                                    formatIdentityDocumentNumber(
                                        e,
                                        setIdentityDocumentNumber
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RecentActorsIcon
                                                sx={{
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                            },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ width: "100%" }}>
                            <InputLabel
                                htmlFor="admin-create-user-passport-input"
                                sx={{
                                    marginBottom: "5px",
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                }}
                            >
                                Pasaporte de Administrador
                            </InputLabel>
                            <TextField
                                id="admin-create-user-passport-input"
                                size="small"
                                placeholder="Ingresa el Pasaporte"
                                autoComplete="nope"
                                fullWidth
                                value={passport}
                                error={!passportValid}
                                onChange={(e) => {
                                    const lastChar =
                                        e.target.value[
                                            e.target.value.length - 1
                                        ];
                                    const lastCharRegex =
                                        /^[\s\S]*[a-zA-Z0-9]$/;

                                    if (
                                        alreadyValidated &&
                                        lastCharRegex.test(lastChar)
                                    ) {
                                        validatePassport(
                                            e.target.value,
                                            setPassportValid
                                        );
                                    }

                                    formatPassportNumber(e, setPassport);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RecentActorsIcon
                                                sx={{
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                            },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    )}
                </Box>

                <Box sx={{ marginLeft: "10px", marginTop: "52px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-phone-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Teléfono de Administrador
                    </InputLabel>
                    <TextField
                        id="admin-create-user-phone-number-input"
                        size="small"
                        placeholder="Ingresa el número"
                        autoComplete="nope"
                        value={phoneNumber}
                        error={!phoneNumberValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validatePhoneNumber(
                                    e.target.value,
                                    setPhoneNumberValid
                                );
                            }

                            formatPhoneNumber(e.target.value, setPhoneNumber);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ContactPhoneIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box
                    sx={{
                        marginRight: "10px",
                        maxWidth: "50%",
                        width: "50%",
                    }}
                >
                    <InputLabel
                        htmlFor="admin-create-user-user-region-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Región de Administrador
                    </InputLabel>
                    <Select
                        id="admin-create-user-user-region-select"
                        displayEmpty
                        error={!userRegionIdValid}
                        onChange={(e) => {
                            const regionId =
                                regions.find(
                                    (region) => region.name === e.target.value
                                )?.id || "";

                            if (alreadyValidated) {
                                validateRegion(regionId, setUserRegionIdValid);
                            }

                            setUserRegionId(regionId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la región
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        size="small"
                        inputProps={{
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la región</em>
                        </MenuItem>
                        {regions.map((region) => (
                            <MenuItem key={region.id} value={region.name}>
                                {region.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box
                    sx={{
                        marginLeft: "10px",
                        maxWidth: "50%",
                        width: "50%",
                    }}
                >
                    <InputLabel
                        htmlFor="admin-create-user-user-city-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Comuna de Administrador
                    </InputLabel>
                    <Select
                        id="admin-create-user-user-city-select"
                        displayEmpty
                        error={!userCityIdValid}
                        onChange={(e) => {
                            const cityId =
                                cities
                                    .get(userRegionId!)!
                                    .find(
                                        (city) => city.name === e.target.value
                                    )?.id || "";

                            if (alreadyValidated) {
                                validateCity(cityId, setUserCityIdValid);
                            }

                            setUserCityId(cityId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la comuna
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        disabled={!userRegionId}
                        size="small"
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && userRegionId && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "#333333"}`,
                            },
                            "&.Mui-disabled .MuiInputBase-input": {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                                "-webkit-text-fill-color": `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la comuna</em>
                        </MenuItem>
                        {userRegionId &&
                            cities.get(userRegionId!)!.map((city) => (
                                <MenuItem key={city.id} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-street-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Calle de Administrador
                </InputLabel>
                <TextField
                    id="admin-create-user-street-input"
                    size="small"
                    placeholder="Ingresa la calle"
                    autoComplete="nope"
                    error={!streetValid}
                    value={street}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateStreet(e.target.value, setStreetValid);
                        }

                        setStreet(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-street-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número de Calle
                    </InputLabel>
                    <TextField
                        id="admin-create-user-street-number-input"
                        size="small"
                        placeholder="Número de calle"
                        autoComplete="nope"
                        value={streetNumber}
                        error={!streetNumberValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateStreetNumber(
                                    e.target.value,
                                    setStreetNumberValid
                                );
                            }

                            formatStreetNumber(e.target.value, setStreetNumber);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-apartment-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número (Apto/Oficina) (Opcional)
                    </InputLabel>
                    <TextField
                        id="admin-create-user-apartment-input"
                        size="small"
                        placeholder="Ingresa el número"
                        autoComplete="nope"
                        value={apartment}
                        error={!apartmentValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateApartmentNumber(
                                    e.target.value,
                                    setApartmentValid
                                );
                            }

                            formatApartmentNumber(e.target.value, setApartment);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AdminCustomerForm;
