import { useState, useEffect } from "react";
import {
    Box,
    Drawer,
    IconButton,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    DrawerFooter,
    DrawerHeader,
} from "@/shared/components/aside-menu/AsideMenuStyles";
import UserTypeSelector from "./CreateUserTypeSelector";
import UserForm from "./CreateUserForm";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";

interface CreateUserDrawerProps {
    isCreateUserDrawerOpen: boolean;
    regions: RegionInterface[];
    companies: CompanyInterface[];
    cities: Map<string, CityInterface[]>;
    platformTheme: PlatformTheme;
    resetPageNumberAndFetchUsers: () => void;
    setIsCreateUserDrawerOpen: (isOpen: boolean) => void;
}

const CreateUserDrawer = ({
    cities,
    regions,
    companies,
    platformTheme,
    isCreateUserDrawerOpen,
    setIsCreateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: CreateUserDrawerProps) => {
    const [selectedUserType, setSelectedUserType] = useState<
        "Employee" | "Admin Customer" | "Customer"
    >("Employee");
    const [creatingUser, setCreatingUser] = useState<boolean>(false);

    useEffect(() => {
        const shouldCloseDrawer = (event: MouseEvent) => {
            const targetClass = (event.target as HTMLElement).classList;
            const parentId = (event.target as HTMLElement).parentElement?.id;

            if (
                targetClass.contains("MuiBackdrop-root") &&
                parentId === "admin-create-user-drawer"
            ) {
                setIsCreateUserDrawerOpen(false);
            }
        };

        window.addEventListener("mousedown", shouldCloseDrawer);
    }, []);

    const closeCreateUserDrawer = () => {
        setIsCreateUserDrawerOpen(false);
    };

    return (
        <Drawer
            id="admin-create-user-drawer"
            open={isCreateUserDrawerOpen}
            anchor="right"
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#fff" : "#121212"}`,
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <DrawerHeader
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        onClick={closeCreateUserDrawer}
                        sx={{
                            transform: "translateX(-170px)",
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.08)" : "#333333"}`,
                            },
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "#FFFFFF"}`,
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="h5"
                        color="#656565"
                        sx={{
                            textAlign: "center",
                            transform: "translateX(-20px)",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#656565" : "#FFFFFF"}`,
                        }}
                    >
                        {selectedUserType === "Employee" ||
                        selectedUserType === "Customer"
                            ? "Crear Usuario"
                            : "Crear Empresa"}
                    </Typography>
                </DrawerHeader>

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        padding: "0px 16px 16px 16px",
                        flexGrow: 1,
                        overflowY: "auto",
                    }}
                >
                    <Divider
                        sx={{
                            marginBottom: "20px",
                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                        }}
                    />

                    <UserTypeSelector
                        platformTheme={platformTheme}
                        selectedUserType={selectedUserType}
                        setSelectedUserType={setSelectedUserType}
                    />

                    <Divider
                        sx={{
                            marginTop: "17.5px",
                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                        }}
                    />

                    <UserForm
                        selectedUserType={selectedUserType}
                        cities={cities}
                        regions={regions}
                        companies={companies}
                        platformTheme={platformTheme}
                        creatingUser={creatingUser}
                        setCreatingUser={setCreatingUser}
                        setIsCreateUserDrawerOpen={setIsCreateUserDrawerOpen}
                        resetPageNumberAndFetchUsers={
                            resetPageNumberAndFetchUsers
                        }
                    />
                </Box>

                <DrawerFooter
                    sx={{
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: "100%",
                            margin: "16px 0px",
                            backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(21, 102, 193)" : "rgb(76, 0, 153)"}`,
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210)" : "#720ED8"}`,
                            },
                        }}
                        disabled={creatingUser}
                        onClick={() => setCreatingUser(true)}
                    >
                        {selectedUserType === "Employee" ||
                        selectedUserType === "Customer"
                            ? "Crear Usuario"
                            : "Crear Empresa"}
                    </Button>
                </DrawerFooter>
            </Box>
        </Drawer>
    );
};

export default CreateUserDrawer;
