import { useAxios } from "@/shared/utils/classes";
import { CreateCompanyUserRequestInterface } from "@/admin-portal/actions/create-company-user/CreateCompanyUserRequest.interface";
import { CreateCompanyUserResponseInterface } from "@/admin-portal/actions/create-company-user/CreateCompanyUserResponse.interface";

export async function CreateCompanyUser(
    companyUserData: CreateCompanyUserRequestInterface
): Promise<CreateCompanyUserResponseInterface> {
    const {
        firstName,
        lastName,
        email,
        companyId,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    } = companyUserData;

    const axios = new useAxios();

    const response = await axios.post("user/create-company-user", {
        firstName,
        lastName,
        email,
        companyId,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
