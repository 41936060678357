import { toast, Flip } from "react-toastify";
import { PlatformTheme } from "@/shared/utils/enums";
import { getPlatformTheme } from "@/shared/utils/classes";
import { UseToastInterface } from "@/shared/utils/classes/toast/UseToast.interface";
import { UseToastPromiseInterface } from "@/shared/utils/classes/toast/UseToastPromise.interface";

export class useToast {
    public static toastRequest({ message, type }: UseToastInterface): void {
        const platformTheme = getPlatformTheme();

        toast(message, {
            type,
            theme: platformTheme === PlatformTheme.LIGHT ? "light" : "dark",
            transition: Flip,
            position: "top-right",
        });
    }

    public static async toastPromiseRequest({
        promise,
        pendingMessage,
        successMessage,
    }: UseToastPromiseInterface): Promise<any> {
        const platformTheme = getPlatformTheme();

        const promiseResponse = await toast
            .promise(
                promise,
                {
                    pending: pendingMessage,
                    success: {
                        render(data: any) {
                            if (
                                data.data.httpStatusCode !== 200 &&
                                data.data.httpStatusCode !== 201
                            ) {
                                console.log(data.data);
                                throw new Error(data.data.message);
                            }

                            return successMessage;
                        },
                    },
                    error: {
                        render(data: any) {
                            return data.data.message;
                        },
                    },
                },
                {
                    theme:
                        platformTheme === PlatformTheme.LIGHT
                            ? "light"
                            : "dark",
                    transition: Flip,
                    position: "top-right",
                }
            )
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.log(error.message);
            });

        return promiseResponse;
    }
}
