import { useEffect, useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Checkbox,
    Divider,
    InputAdornment,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { ColumnFilter, UserInterface } from "@/shared/utils/interfaces";
import { DeleteUser } from "@/admin-portal/actions/delete-user/DeleteUser.action";
import { ExportUsersToXlsx } from "@/admin-portal/actions/export-users-to-xlsx/ExportUsersToXlsx.action";
import { DeleteUserResponseInterface } from "@/admin-portal/actions/delete-user/DeleteUserResponse.interface";

// Components
import CreateUserDrawer from "@/admin-portal/components/extras/user-management/CreateUserDrawer";
import UpdateUserDrawer from "@/admin-portal/components/extras/user-management/UpdateUserDrawer";

// Icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useToast } from "@/shared/utils/classes";

interface UserListProps {
    searchInput: string;
    regions: RegionInterface[];
    companies: CompanyInterface[];
    platformTheme: PlatformTheme;
    columnFilters: ColumnFilter[];
    selectedUser: UserInterface | undefined;
    cities: Map<string, CityInterface[]>;
    resetPageNumberAndFetchUsers: () => void;
    setSearchInput: (searchInput: string) => void;
    setUsersUpdated: (usersUpdated: boolean) => void;
    setColumnFilters: (columnFilters: ColumnFilter[]) => void;
}

export default function UserListHeader({
    cities,
    regions,
    companies,
    searchInput,
    selectedUser,
    platformTheme,
    columnFilters,
    setSearchInput,
    setUsersUpdated,
    setColumnFilters,
    resetPageNumberAndFetchUsers,
}: UserListProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isExportingUsers, setIsExportingUsers] = useState<boolean>(false);
    const [deleteUserInput, setDeleteUserInput] = useState<string>("");
    const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);
    const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] =
        useState<boolean>(false);
    const [isSearchInputDisable, setIsSearchInputDisable] =
        useState<boolean>(false);

    const [isCreateUserDrawerOpen, setIsCreateUserDrawerOpen] =
        useState<boolean>(false);
    const [isUpdateUserDrawerOpen, setIsUpdateUserDrawerOpen] =
        useState<boolean>(false);

    const isFilterMenuOpen = Boolean(anchorEl);

    const handleClickFilterMenu = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilterMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteUser = async () => {
        setIsDeletingUser(true);

        const { httpStatusCode }: DeleteUserResponseInterface =
            await useToast.toastPromiseRequest({
                promise: DeleteUser({ id: selectedUser!.id }),
                successMessage: `${selectedUser!.fullName} fue eliminado del sistema`,
                pendingMessage: `Eliminación de ${selectedUser!.fullName} en progreso`,
            });

        if (httpStatusCode === 200) {
            setIsDeleteUserDialogOpen(false);
            resetPageNumberAndFetchUsers();
        }

        setIsDeletingUser(false);
    };

    const openCreateUserDrawer = () => {
        setIsCreateUserDrawerOpen(true);
    };

    const getFieldLabel = (field: string): string => {
        switch (field) {
            case "firstName":
                return "Primer Nombre";
            case "lastName":
                return "Apellido";
            case "userType":
                return "Tipo de Usuario";
            case "identityDocumentNumber":
                return "Número de Identificación";
            case "company":
                return "Empresa";
            case "email":
                return "Correo Electrónico";
            case "phoneNumber":
                return "Número de Teléfono";
            case "address":
                return "Dirección";
            default:
                return field;
        }
    };

    const getFilterLabel = (field: string): string => {
        switch (field) {
            case "prefix":
                return "Empieza por";
            case "contains":
                return "Contiene";
            case "equal":
                return "Es Igual";
            case "not":
                return "No es Igual";
            default:
                return "Operador";
        }
    };

    const getEnglishFilterLabel = (
        field: string
    ): "none" | "prefix" | "contains" | "equal" | "not" => {
        switch (field) {
            case "Empieza por":
                return "prefix";
            case "Contiene":
                return "contains";
            case "Es Igual":
                return "equal";
            case "No Es Igual":
                return "not";
            default:
                return "none";
        }
    };

    const handleFilterChange = (
        field: string,
        operator: "none" | "equal" | "not" | "contains" | "prefix"
    ) => {
        const updatedFilters = columnFilters.map((filter) => {
            if (filter.field === field) {
                return {
                    ...filter,
                    operator,
                };
            }

            return filter;
        });

        const activeFilters = updatedFilters.filter(
            (filter) => filter.isActive && filter.operator !== "none"
        ).length;

        activeFilters === 0
            ? setIsSearchInputDisable(true)
            : setIsSearchInputDisable(false);

        setColumnFilters(updatedFilters);
    };

    const handleFilterActiveChange = (field: string, isActive: boolean) => {
        const updatedFilters = columnFilters.map((filter) => {
            if (filter.field === field) {
                return {
                    ...filter,
                    operator: !isActive ? "none" : filter.operator,
                    isActive,
                };
            }

            return filter;
        });

        const activeFilters = updatedFilters.filter(
            (filter) => filter.isActive && filter.operator !== "none"
        ).length;

        activeFilters === 0
            ? setIsSearchInputDisable(true)
            : setIsSearchInputDisable(false);

        setColumnFilters(updatedFilters);
    };

    const exportUsersToExcel = async () => {
        setIsExportingUsers(true);

        await useToast.toastPromiseRequest({
            promise: ExportUsersToXlsx(),
            successMessage: "Usuarios descargados",
            pendingMessage: "Descarga de usuarios en progreso",
        });

        setIsExportingUsers(false);
    };

    return (
        <div
            className={`${platformTheme === PlatformTheme.LIGHT ? "admin-user-list-header-light" : "admin-user-list-header-dark"}`}
            style={headerStyle}
        >
            <Typography variant="h5">Usuarios</Typography>

            <Box>
                <TextField
                    disabled={isSearchInputDisable}
                    className="admin-user-list-header-search-input"
                    label="Busca aquí"
                    variant="outlined"
                    size="small"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                        endAdornment: isSearchInputDisable && (
                            <Tooltip
                                title="Debes seleccionar al menos un filtro"
                                placement="top"
                                arrow
                            >
                                <InputAdornment position="end">
                                    <PriorityHighIcon
                                        sx={{ fill: "#FF9933" }}
                                    />
                                </InputAdornment>
                            </Tooltip>
                        ),
                    }}
                    sx={{
                        width: "211.5px",
                        "& .MuiOutlinedInput-root": {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#535353 !important" : "#FFFFFF !important"}`,
                        },
                    }}
                />

                <Tooltip title="Filtros" placement="top" arrow>
                    <IconButton
                        sx={getButtonStyle(platformTheme)}
                        onClick={handleClickFilterMenu}
                    >
                        <FilterAltOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    open={isFilterMenuOpen}
                    onClose={handleCloseFilterMenu}
                    sx={{ transform: "translate(-45px, 5px)" }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "20px",
                            marginBottom: "3px",
                        }}
                    >
                        Filtros
                    </Typography>

                    <Divider />

                    {columnFilters.map((filter) => (
                        <MenuItem key={filter.field} sx={{ width: "100%" }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Box display="flex" alignItems="center">
                                    <Checkbox
                                        defaultChecked={filter.isActive}
                                        onChange={(e) =>
                                            handleFilterActiveChange(
                                                filter.field,
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <Typography>
                                        {getFieldLabel(filter.field)}
                                    </Typography>
                                </Box>

                                <Select
                                    input={<OutlinedInput />}
                                    disabled={!filter.isActive}
                                    value={getFilterLabel(filter.operator)}
                                    size="small"
                                    onChange={(e) =>
                                        handleFilterChange(
                                            filter.field,
                                            getEnglishFilterLabel(
                                                e.target.value as any
                                            )
                                        )
                                    }
                                    renderValue={() => {
                                        return filter.operator === "none" ? (
                                            <em>Operador</em>
                                        ) : (
                                            getFilterLabel(filter.operator)
                                        );
                                    }}
                                    sx={{ marginLeft: "20px", width: "150px" }}
                                >
                                    <MenuItem disabled value="Operador">
                                        <em>Operador</em>
                                    </MenuItem>

                                    <MenuItem value="Es Igual">
                                        Es Igual
                                    </MenuItem>

                                    <MenuItem value="No Es Igual">
                                        No Es Igual
                                    </MenuItem>

                                    <MenuItem value="Contiene">
                                        Contiene
                                    </MenuItem>

                                    <MenuItem value="Empieza por">
                                        Empieza Por
                                    </MenuItem>
                                </Select>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>

                <Tooltip
                    title="Descarga de Usuarios (Formato: Excel)"
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={exportUsersToExcel}
                        disabled={isExportingUsers}
                        sx={getButtonStyle(platformTheme)}
                    >
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                </Tooltip>

                {selectedUser && (
                    <>
                        <Tooltip title="Editar Usuario" placement="top" arrow>
                            <IconButton
                                onClick={() => setIsUpdateUserDrawerOpen(true)}
                                sx={getButtonStyle(platformTheme)}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Eliminar Usuario" placement="top" arrow>
                            <IconButton
                                onClick={() => setIsDeleteUserDialogOpen(true)}
                                sx={getButtonStyle(platformTheme)}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}

                <Button
                    onClick={openCreateUserDrawer}
                    variant="contained"
                    sx={getCreateButtonStyle(platformTheme)}
                >
                    <AddOutlinedIcon sx={{ fill: "#fff" }} />
                    Crear Usuario
                </Button>
            </Box>

            <CreateUserDrawer
                cities={cities}
                regions={regions}
                companies={companies}
                platformTheme={platformTheme}
                isCreateUserDrawerOpen={isCreateUserDrawerOpen}
                setIsCreateUserDrawerOpen={setIsCreateUserDrawerOpen}
                resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
            />

            <UpdateUserDrawer
                cities={cities}
                regions={regions}
                selectedUser={selectedUser!}
                platformTheme={platformTheme}
                isUpdateUserDrawerOpen={isUpdateUserDrawerOpen}
                setUsersUpdated={setUsersUpdated}
                setIsUpdateUserDrawerOpen={setIsUpdateUserDrawerOpen}
                resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
            />

            <Dialog
                open={isDeleteUserDialogOpen}
                onClose={() =>
                    !isDeletingUser && setIsDeleteUserDialogOpen(false)
                }
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#FFFFFF" : "#1E1E1E"}`,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        color: `${platformTheme === PlatformTheme.LIGHT ? "#333333" : "#FFFFFF"}`,
                    }}
                >
                    Confirmar Eliminación
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "5px" }}>
                    <DialogContentText
                        sx={{
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#666666" : "#DDDDDD"}`,
                        }}
                    >
                        Para confirmar la eliminación de este usuario de la
                        plataforma, por favor escribe{" "}
                        <b>"{selectedUser?.fullName}"</b> en el campo a
                        continuación. Esta acción es irreversible y eliminará
                        permanentemente el acceso y los datos del usuario.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="email"
                        type="text"
                        placeholder={`Escribe "${selectedUser?.fullName}" aquí`}
                        fullWidth
                        variant="outlined"
                        autoComplete="nope"
                        onChange={(e) => setDeleteUserInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (
                                e.key === "Enter" &&
                                deleteUserInput === selectedUser?.fullName
                            ) {
                                handleDeleteUser();
                            }
                        }}
                        sx={{
                            marginTop: "25px",
                            "& .MuiOutlinedInput-root": {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "#000000 !important" : "#FFFFFF !important"}`,

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#CCCCCC" : "#555555"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#999999" : "#AAAAAA"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#007BFF" : "#80BFFF"}`,
                                    },
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsDeleteUserDialogOpen(false)}
                        sx={{
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#666666" : "#DDDDDD"}`,
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={
                            deleteUserInput !== selectedUser?.fullName ||
                            isDeletingUser
                        }
                        onClick={handleDeleteUser}
                        sx={{
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#007BFF" : "#80BFFF"}`,
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
};

const getButtonStyle = (platformTheme: PlatformTheme) => ({
    marginLeft: "10px",
    border: `${platformTheme === PlatformTheme.LIGHT ? "1px solid #33333350" : "1px solid #ffffff50"}`,
    ".MuiTouchRipple-child": {
        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "#ffffff"}`,
    },
});

const getCreateButtonStyle = (platformTheme: PlatformTheme) => ({
    width: "150px",
    marginLeft: "10px",
    borderRadius: "6px",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#3399FF" : "#4C0099"}`,
    ":hover": {
        bgcolor: `${platformTheme === PlatformTheme.LIGHT ? "#2877CC" : "#3B007A"}`,
        color: "white",
    },
});
