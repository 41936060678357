import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import {
    InventoryIcon,
    AttachMoneyIcon,
    StorefrontIcon,
    LowPriorityIcon,
    ReceiptIcon,
    PeopleAltIcon,
} from "./Icons";

interface CustomerSectionProps {
    open: boolean;
    userRole: string;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function CustomerSection({
    open,
    userRole,
    currentPathLocation,
    changeLocation,
}: CustomerSectionProps) {
    return (
        <>
            <List>
                <ListItem
                    key={"Inventario"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Inventario"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/customer/inventory"
                            }
                            onClick={() =>
                                changeLocation("/customer/inventory")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <InventoryIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Inventario"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Órdenes de Recogida"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Gestionar Órdenes de Recogida"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/customer/products"
                            }
                            onClick={() => changeLocation("/customer/products")}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <StorefrontIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Recogida"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Órdenes de Venta"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Gestionar Órdenes de Venta"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/customer/sales-order"
                            }
                            onClick={() =>
                                changeLocation("/customer/sales-order")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Venta"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Órdenes de Devolución"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Gestionar Órdenes de Devolución"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/customer/return-orders"
                            }
                            onClick={() =>
                                changeLocation("/customer/return-orders")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <LowPriorityIcon
                                    style={{ transform: "rotate(180deg)" }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Devolución"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                {userRole.toLowerCase() === "admin customer" ||
                    (userRole.toLowerCase() === "admin" && (
                        <ListItem
                            key={"Gestión de Usuarios"}
                            disablePadding
                            sx={{ display: "block" }}
                        >
                            <Tooltip
                                title="Gestionar Usuarios"
                                placement="right"
                                arrow
                            >
                                <ListItemButton
                                    selected={
                                        currentPathLocation ===
                                        "/customer/user-management"
                                    }
                                    onClick={() =>
                                        changeLocation(
                                            "/customer/user-management"
                                        )
                                    }
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : "auto",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <PeopleAltIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Gestión de Usuarios"}
                                        sx={{ opacity: open ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    ))}

                {userRole.toLowerCase() === "admin customer" ||
                    (userRole.toLowerCase() === "admin" && (
                        <ListItem
                            key={"Facturación"}
                            disablePadding
                            sx={{ display: "block" }}
                        >
                            <Tooltip
                                title="Verificar Facturación"
                                placement="right"
                                arrow
                            >
                                <ListItemButton
                                    selected={
                                        currentPathLocation ===
                                        "/customer/billing"
                                    }
                                    onClick={() =>
                                        changeLocation("/customer/billing")
                                    }
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                            ? "initial"
                                            : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : "auto",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Facturación"}
                                        sx={{ opacity: open ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    ))}
            </List>

            <Divider />
        </>
    );
}
