import React, { useEffect, useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import {
    UserInterface,
    ColumnFilter,
    OrderInterface,
    RegionInterface,
    CityInterface,
} from "@/shared/utils/interfaces";

//components
import { Box } from "@mui/material";
import PageHeader from "@/shared/components/PageHeader";
import PageHeaderAdminCompany from "@/shared/components/PageHeaderAdminCompany";
import SalesOrderListHeader from "@/customer-portal/components/extras/sales-orders/OrderListHeader";
import { useAxios } from "@/shared/utils/classes";

const INITIAL_COLUMN_FILTERS: ColumnFilter[] = [
    { field: "internalId", isActive: true, operator: "prefix" },
    { field: "status", isActive: false, operator: "none" },
    { field: "totalPrice", isActive: false, operator: "none" },
    { field: "createdByName", isActive: false, operator: "none" },
    { field: "createdById", isActive: false, operator: "none" },
    { field: "executionDate", isActive: false, operator: "none" },
    { field: "transport", isActive: false, operator: "none" },
];

interface SalesOrdersProps {
    currentTime: Date;
    user: UserInterface;
    platformTheme: PlatformTheme;
    isCurrentTimeLoading: boolean;
}

export default function SalesOrders({
    user,
    currentTime,
    platformTheme,
    isCurrentTimeLoading,
}: SalesOrdersProps) {
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchInput, setSearchInput] = useState<string>();
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [cities, setCities] = useState<Map<string, CityInterface[]>>(
        new Map<string, CityInterface[]>()
    );

    const [selectedOrder, setSelectedOrder] = useState<
        OrderInterface | undefined
    >();
    const [selectedCompany, setSelectedCompany] = useState<string>(
        user.role.toLowerCase() !== "admin" ? user.company : ""
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(
        INITIAL_COLUMN_FILTERS
    );

    useEffect(() => {
        fetchLocations();
    }, []);

    const fetchLocations = async (): Promise<void> => {
        const regions = await fetchRegions();
        const cities = await fetchCities(regions);

        setRegions(regions);
        setCities(cities);
    };

    const fetchRegions = async (): Promise<RegionInterface[]> => {
        const useAxiosInstance = new useAxios();

        //Falta cambiar la carpeta de las interfaces de region y cities
        const activeRegions: RegionInterface[] = await useAxiosInstance
            .get("regions/active")
            .then((data) => data.data)
            .catch((error) => {
                console.error(error);
                return [];
            });

        return activeRegions;
    };

    const fetchCities = async (
        regions: RegionInterface[]
    ): Promise<Map<string, CityInterface[]>> => {
        const useAxiosInstance = new useAxios();
        const cities: Map<string, CityInterface[]> = new Map<
            string,
            CityInterface[]
        >();

        for (const region of regions) {
            const citiesByRegion: CityInterface[] = await useAxiosInstance
                .get(`cities/active/${region.id}`)
                .then((data) => data.data)
                .catch((error) => {
                    console.error(error);
                    return [];
                });

            cities.set(region.id, citiesByRegion);
        }

        return cities;
    };

    return (
        <Box>
            {user.role.toLowerCase() === "admin" ? (
                <PageHeaderAdminCompany
                    platformTheme={platformTheme}
                    title="Gestión Integral de Órdenes de Venta"
                    description={`Administre eficientemente las órdenes de venta de todas las empresas registradas en la plataforma. Esta herramienta le permite supervisar, modificar y optimizar las transacciones comerciales de manera centralizada, asegurando un control total y una gestión simplificada de todas las operaciones de venta.`}
                    setSelectedCompany={setSelectedCompany}
                />
            ) : (
                <PageHeader
                    title="Gestión de Órdenes de Venta"
                    description="Bienvenido a su panel de control de órdenes de venta. Aquí puede administrar fácilmente todas sus transacciones comerciales. Supervise, actualice y gestione sus órdenes de manera eficiente, asegurando que cada proceso se realice sin contratiempos y con total transparencia."
                />
            )}

            <SalesOrderListHeader
                cities={cities}
                regions={regions}
                currentTime={currentTime}
                searchInput={searchInput!}
                columnFilters={columnFilters}
                platformTheme={platformTheme}
                selectedOrder={selectedOrder}
                currentCompany={selectedCompany}
                isCurrentTimeLoading={isCurrentTimeLoading}
                setSearchInput={setSearchInput}
                setColumnFilters={setColumnFilters}
            />
        </Box>
    );
}
