import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { getRandomAvatarColor } from "@/shared/components/aside-menu/AvatarUtils";

interface NameCellProps {
    name: string;
    avatarUrl: string;
}

const NameCell: React.FC<NameCellProps> = ({ name, avatarUrl }) => {
    const [avatarName] = useState<string>(
        `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    );

    const [randomColor] = useState<{ background: string; text: string }>(
        getRandomAvatarColor()
    );

    return (
        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Avatar
                alt={name}
                src={avatarUrl}
                sx={{
                    color: avatarUrl ? undefined : `${randomColor.text}`,
                    bgcolor: avatarUrl
                        ? undefined
                        : `${randomColor.background}`,
                }}
            >
                {!avatarUrl && avatarName}
            </Avatar>

            <Typography variant="body2" style={{ marginLeft: 8 }}>
                {name}
            </Typography>
        </Box>
    );
};

export default NameCell;
