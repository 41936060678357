import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PlatformTheme } from "@/shared/utils/enums";
import SwitchTheme from "@/shared/components/SwitchTheme";
import { Divider } from "@mui/material";

interface PageHeaderProps {
    title: string;
    description: string;
}

export default function PageHeader({ title, description }: PageHeaderProps) {
    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    fontSize: "26px",
                    marginBottom: "10px",
                    marginTop: "10px",
                }}
            >
                {title}
            </Typography>

            <Typography>{description}</Typography>

            <Divider
                sx={{
                    marginTop: "15px",
                    marginBottom: "20px",
                }}
            />
        </Box>
    );
}
