export const avatarColors: { background: string; text: string }[] = [
    { background: "#87CEEB", text: "#FFFFFF" }, // Azul Claro, Blanco
    { background: "#98FF98", text: "#2F4F4F" }, // Verde Menta, Gris Oscuro
    { background: "#FFD700", text: "#2F4F4F" }, // Amarillo Pastel, Gris Oscuro
    { background: "#D3D3D3", text: "#000080" }, // Gris Claro, Azul Marino
    { background: "#FFA500", text: "#FFFFFF" }, // Naranja Claro, Blanco
    { background: "#9370DB", text: "#FFFFFF" }, // Púrpura Claro, Blanco
];

export const getRandomAvatarColor = () => {
    const randomIndex = Math.floor(Math.random() * avatarColors.length);
    return avatarColors[randomIndex];
};

export const getTranslatedRole = (role: string): string => {
    switch (role.toLowerCase()) {
        case "admin":
            return "Administrador";
        case "carrier":
            return "Transportista";
        case "customer service":
            return "Servicio al Cliente";
        case "warehouse operator":
            return "Operador de Almacén";
        case "customer":
            return "Cliente";
        case "admin customer":
            return "Administrador de Cliente";
        default:
            return role;
    }
};

export const capitalizeString = (text: string): string => {
    return text
        .split(" ")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");
};

export const capitalizeFirstLetter = (text: string): string => {
    if (text.length === 0) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
