import React, { useState, useEffect } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { Auth } from "@/central-app/actions/auth/Auth.action";
import MainLogo from "@/shared/assets/logiflex_logo_v1.png";
import { useToast } from "@/shared/utils/classes";
import { useNavigate } from "react-router-dom";
import { AuthResponseInterface } from "@/central-app/actions/auth/AuthResponse.interface";
import {
    InputLabel,
    Button,
    Switch,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
} from "@mui/material";

interface CentralAppBodyProps {
    platformTheme: PlatformTheme;
    setCheckAuth: (checkAuth: boolean) => void;
}

export default function CentralAppBody({
    platformTheme,
    setCheckAuth,
}: CentralAppBodyProps) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isFirstTime, setIsFirstTime] = useState<boolean>();
    const [isLogging, setIsLogging] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [keepConnection, setKeepConnection] = useState<boolean>(true);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const isFirstTime = localStorage.getItem("isFirstTime");

        if (isFirstTime === undefined || isFirstTime === null) {
            setIsFirstTime(false);
        }

        setIsFirstTime(isFirstTime === "false" ? false : true);
    }, []);

    const handleSubmit = async () => {
        setIsLogging(true);
        const isFormValid = checkIfFormIsValid();

        if (isFormValid) {
            authenticate();
        } else {
            useToast.toastRequest({
                message:
                    "Por favor, completa todos los campos correctamente antes de continuar.",
                type: "warning",
            });
        }

        setIsLogging(false);
    };

    const authenticate = async () => {
        const { httpStatusCode, accessToken }: AuthResponseInterface =
            await useToast.toastPromiseRequest({
                promise: Auth({ email, password }),
                pendingMessage: "Inicio de sesión en progreso",
                successMessage: "Inicio de sesión exitoso",
            });

        handleAuthenticationResult(httpStatusCode, accessToken);
    };

    const handleAuthenticationResult = (
        httpStatusCode: number,
        accessToken: string | undefined
    ) => {
        const wasAuthSuccessful = checkIfAuthWasSuccessful(httpStatusCode);

        if (wasAuthSuccessful) {
            localStorage.setItem("accessToken", accessToken!);
            localStorage.setItem("isFirstTime", "false");
            localStorage.setItem("keepConnection", keepConnection.toString());

            setCheckAuth(true);
        }
    };

    const verifyEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const verifyPassword = (password: string): boolean => {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,128}$/;
        return passwordRegex.test(password);
    };

    const checkIfFormIsValid = (): boolean => {
        const isEmailValid = verifyEmail(email);
        const isPasswordValid = verifyPassword(password);

        setIsEmailValid(isEmailValid);
        setIsPasswordValid(isPasswordValid);

        if (!isEmailValid || !isPasswordValid) {
            return false;
        }

        return true;
    };

    const checkIfAuthWasSuccessful = (httpStatusCode: number): boolean => {
        if (httpStatusCode === 200) {
            return true;
        }

        return false;
    };

    const changeLocation = (path: string): void => {
        navigate(path);
    };

    return (
        <>
            <form
                className={`login-body__login-form ${platformTheme === PlatformTheme.LIGHT ? "login-body__login-form-light" : "login-body__login-form-dark"}`}
            >
                <div className="login-form__logo-image-container">
                    <img
                        className="login-form__logo-image"
                        src={MainLogo}
                        alt="Logiflex Logo"
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>

                {isFirstTime ? (
                    <div className="login-form__subtitle-container">
                        <p
                            className={`login-form__title ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        >
                            ¡Bienvenido a LogiFlex!
                        </p>
                        <p
                            className={`login-form__subtitle ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        >
                            Comencemos tu viaje logístico
                        </p>
                    </div>
                ) : (
                    <div className="login-form__subtitle-container">
                        <p
                            className={`login-form__title ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        >
                            ¡Bienvenido de vuelta!
                        </p>
                        <p
                            className={`login-form__subtitle ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                        >
                            Continuemos optimizando tu logística
                        </p>
                    </div>
                )}

                <div className="login-form__container">
                    <FormGroup className="login-form__input-container">
                        <InputLabel
                            className={`login-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            htmlFor="login-form__email-input"
                        >
                            Correo Electrónico
                        </InputLabel>

                        <TextField
                            id="login-form__email-input"
                            className={`login-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            placeholder="Introduce tu correo electrónico"
                            variant="standard"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLogging}
                            error={!isEmailValid}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit();
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="login-form__input-container">
                        <InputLabel
                            className={`login-form__label-password ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            htmlFor="login-form__password-input"
                        >
                            Contraseña
                        </InputLabel>

                        <TextField
                            id="login-form__password-input"
                            className={`login-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            placeholder="Introduce tu contraseña"
                            variant="standard"
                            onChange={(e) => setPassword(e.target.value)}
                            error={!isPasswordValid}
                            type="password"
                            disabled={isLogging}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit();
                            }}
                        />
                    </FormGroup>

                    <div className="login-form__configuration-container">
                        <FormControlLabel
                            className={`login-form__configuration-keep-connection ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            control={
                                <Switch
                                    defaultChecked
                                    onChange={(e) =>
                                        setKeepConnection(e.target.checked)
                                    }
                                />
                            }
                            label={
                                <Typography
                                    variant="body2"
                                    className={`login-form__configuration-keep-connection-font-size ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                                    style={{ fontSize: "12px" }}
                                >
                                    Mantener Conexión
                                </Typography>
                            }
                        />

                        <div className="login-form__configuration-forgot-password">
                            <Button
                                onClick={() =>
                                    changeLocation("/recover-password")
                                }
                                variant="text"
                            >
                                Obtener Contraseña
                            </Button>
                        </div>
                    </div>

                    <FormGroup className="login-form__login-button-container">
                        <Button
                            className={`login-form__login-button ${platformTheme === PlatformTheme.LIGHT ? "login-form__login-button-light-theme" : "login-form__login-button-dark-theme"}`}
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLogging}
                        >
                            Iniciar Sesión
                        </Button>
                    </FormGroup>
                </div>
            </form>
        </>
    );
}
