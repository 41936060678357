import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { PersonIcon, SummarizeIcon, HistoryIcon } from "./Icons";

interface AdminSectionProps {
    open: boolean;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function AdminSection({
    open,
    currentPathLocation,
    changeLocation,
}: AdminSectionProps) {
    return (
        <>
            <List>
                <ListItem
                    key={"Usuarios"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip title="Gestionar Usuarios" placement="right" arrow>
                        <ListItemButton
                            selected={
                                currentPathLocation === "/admin/user-management"
                            }
                            onClick={() =>
                                changeLocation("/admin/user-management?page=1")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Usuarios"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Reportes"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Reportes de Negocio"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/admin/business-reports"
                            }
                            onClick={() =>
                                changeLocation("/admin/business-reports")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <SummarizeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Reportes"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Registro de Actividades"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Registro de Actividades de Usuarios"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation === "/admin/user-activities"
                            }
                            onClick={() =>
                                changeLocation("/admin/user-activities")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Registro de Actividades"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>

            <Divider />
        </>
    );
}
