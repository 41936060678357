import React, { useState, useEffect } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { CircularProgress, Fade, Typography } from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridPagination,
} from "@mui/x-data-grid";

//Components
import NameCell from "@/admin-portal/components/extras/user-management/UserListNameCell";
import { Box, Button } from "@mui/material";
import { UserInterface } from "@/shared/utils/interfaces";

//Icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface UserListProps {
    pageNumber: number;
    hasNextPage: boolean;
    usersUpdated: boolean;
    users: UserInterface[];
    platformTheme: PlatformTheme;
    setPageSize: (pageSize: number) => void;
    setPageNumber: (pageNumber: number) => void;
    setUsersUpdated: (updated: boolean) => void;
    setSelectedUser: (user: UserInterface | undefined) => void;
}

export default function UserList({
    users,
    pageNumber,
    hasNextPage,
    usersUpdated,
    platformTheme,
    setPageSize,
    setPageNumber,
    setUsersUpdated,
    setSelectedUser,
}: UserListProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataGridRows, setDataGridRows] = useState<GridRowsProp>();
    const [selectionModel, setSelectionModel] = useState<string[]>([]);

    const containerRef = React.createRef<HTMLDivElement>();

    const handleResize = () => {
        if (!containerRef.current) {
            return;
        }

        const containerHeight =
            window.innerHeight - containerRef.current.offsetTop;
        const rowHeight = 65;
        const newPageSize = Math.floor(containerHeight / rowHeight) - 1;

        if (newPageSize <= 0) {
            return;
        }

        setPageSize(newPageSize);
    };

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [containerRef]);

    useEffect(() => {
        if (usersUpdated) {
            setSelectionModel([]);
            setSelectedUser(undefined);
            setUsersUpdated(false);
        }
    }, [usersUpdated]);

    useEffect(() => {
        if (users) {
            const newRows = users.map((user) => {
                return {
                    id: user.id,
                    name: user.fullName,
                    identityDocumentNumber: user.identityDocumentNumber,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    role: getRoleLabel(user.role),
                    company: user.company,
                    address: user.address,
                    avatarUrl: user.profilePictureUrl,
                };
            });

            setDataGridRows(newRows);
        }
    }, [users]);

    useEffect(() => {
        if (dataGridRows) {
            setIsLoading(false);
        }
    }, [dataGridRows]);

    const clickPreviousUsers = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
        }
    };

    const clickNextUsers = () => {
        setPageNumber(pageNumber + 1);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nombre",
            flex: 1,
            minWidth: 175,
            renderCell: (params) => (
                <NameCell
                    name={params.value}
                    avatarUrl={params.row.avatarUrl}
                />
            ),
        },
        {
            field: "identityDocumentNumber",
            headerName: "Documento de identidad",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "role",
            headerName: "Tipo de Usuario",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "company",
            headerName: "Empresa",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "email",
            headerName: "Correo Electrónico",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "phoneNumber",
            headerName: "Número de Teléfono",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "address",
            headerName: "Dirección",
            flex: 1.5,
            minWidth: 150,
            resizable: false,
        },
    ];

    const handleSelectionChange = (newSelection: any) => {
        const newSelectedId = newSelection[0];

        if (selectionModel.includes(newSelectedId)) {
            setSelectedUser(undefined);

            setSelectionModel(
                selectionModel.filter((id) => id !== newSelectedId)
            );
        } else {
            const selectedUser = users.find(
                (user) => user.id === newSelectedId
            );

            setSelectedUser(selectedUser);
            setSelectionModel(newSelection);
        }
    };

    const getRoleLabel = (role: string): string => {
        switch (role) {
            case "Admin":
                return "Administrador";
            case "Carrier":
                return "Transportista";
            case "Customer Service":
                return "Servicio al Cliente";
            case "Warehouse Operator":
                return "Operador de Almacén";
            case "Customer":
                return "Cliente";
            case "Admin Customer":
                return "Administrador de Cliente";
            default:
                return role;
        }
    };

    return (
        <>
            <div
                className={`admin-user-list-container ${platformTheme === PlatformTheme.LIGHT ? "admin-user-list-light" : "admin-user-list-dark"}`}
                style={{ minWidth: 0 }}
                ref={containerRef}
            >
                <DataGrid
                    rows={dataGridRows}
                    columns={columns}
                    autoHeight
                    pageSizeOptions={[]}
                    disableColumnFilter
                    disableColumnSorting
                    disableColumnMenu
                    disableColumnSelector
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelectionChange}
                    slots={{
                        noRowsOverlay: () =>
                            isLoading ? (
                                <Box display="flex" flexDirection="column">
                                    <Fade
                                        in={true}
                                        style={{
                                            transitionDelay: "800ms",
                                        }}
                                        unmountOnExit
                                    >
                                        <CircularProgress
                                            sx={{
                                                marginTop: "15px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                color: `${platformTheme === PlatformTheme.LIGHT ? "#3399FF" : "#00CCFF"}`,
                                            }}
                                        />
                                    </Fade>

                                    <Typography
                                        variant="h5"
                                        sx={{
                                            textAlign: "center",
                                            marginTop: "10px",
                                            fontWeight: "600",
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "#535353" : "#FFFFFF"}`,
                                        }}
                                    >
                                        Cargando Usuarios
                                    </Typography>
                                </Box>
                            ) : (
                                <Box display="flex" justifyContent="center">
                                    <Box sx={{ marginTop: "10px" }}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <WarningAmberIcon
                                                sx={{
                                                    width: "50px",
                                                    height: "50px",
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "#505050" : "#FFD700"}`,
                                                }}
                                            />
                                        </Box>

                                        <Typography
                                            variant="h5"
                                            sx={{
                                                color: `${platformTheme === PlatformTheme.LIGHT ? "#535353" : "#FFFFFF"}`,
                                            }}
                                        >
                                            No hay registros disponibles
                                        </Typography>
                                    </Box>
                                </Box>
                            ),
                        pagination: () => (
                            <GridPagination
                                ActionsComponent={() => (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        mt={2}
                                        sx={{
                                            marginTop: "0px",
                                        }}
                                    >
                                        {(pageNumber > 1 || hasNextPage) && (
                                            <>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="outlined"
                                                    tabIndex={-1}
                                                    onClick={clickPreviousUsers}
                                                    startIcon={
                                                        <ArrowBackIosIcon
                                                            sx={{
                                                                fill: `${platformTheme === PlatformTheme.LIGHT ? (pageNumber > 1 ? "#1976d2" : "#9e9e9e") : pageNumber > 1 ? "#90caf9" : "#888888"}`,
                                                            }}
                                                        />
                                                    }
                                                    disabled={pageNumber <= 1}
                                                    sx={{
                                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#90caf9"}`,
                                                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#90caf9"}`,
                                                        cursor: "pointer",
                                                        "&:hover": {
                                                            color: `${platformTheme === PlatformTheme.LIGHT ? "#1565c0" : "#64b5f6"}`,
                                                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#1565c0" : "#64b5f6"}`,
                                                        },
                                                        "&.Mui-disabled": {
                                                            color: `${platformTheme === PlatformTheme.LIGHT ? "#9e9e9e" : "#bdbdbd"}`,
                                                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#bdbdbd" : "#616161"}`,
                                                        },
                                                    }}
                                                >
                                                    Previo
                                                </Button>

                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="outlined"
                                                    tabIndex={-1}
                                                    onClick={clickNextUsers}
                                                    disabled={!hasNextPage}
                                                    endIcon={
                                                        <ArrowForwardIosIcon
                                                            sx={{
                                                                fill: `${platformTheme === PlatformTheme.LIGHT ? (hasNextPage ? "#1976d2" : "#9e9e9e") : hasNextPage ? "#90caf9" : "#bdbdbd"}`,
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        marginLeft: 1.5,
                                                        color: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#90caf9"}`,
                                                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#90caf9"}`,
                                                        cursor: "pointer",
                                                        "&:hover": {
                                                            color: `${platformTheme === PlatformTheme.LIGHT ? "#1565c0" : "#64b5f6"}`,
                                                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#1565c0" : "#64b5f6"}`,
                                                        },
                                                        "&.Mui-disabled": {
                                                            color: `${platformTheme === PlatformTheme.LIGHT ? "#9e9e9e" : "#bdbdbd"}`,
                                                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "#bdbdbd" : "#616161"}`,
                                                        },
                                                    }}
                                                >
                                                    Próximo
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                )}
                            />
                        ),
                    }}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: () => "",
                        },
                    }}
                    sx={{
                        border: "none",
                    }}
                />
            </div>
        </>
    );
}
