import { useEffect } from "react";

const TAB_COUNT_KEY = "tabCount";
const TAB_ID_KEY = "tabId";

// Función para generar un UUID simple
function generateUUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

// Función para incrementar el contador de pestañas
function incrementTabCount() {
    const tabCount = parseInt(localStorage.getItem(TAB_COUNT_KEY) || "0", 10);
    localStorage.setItem(TAB_COUNT_KEY, (tabCount + 1).toString());
}

// Función para decrementar el contador de pestañas
function decrementTabCount() {
    const tabCount = parseInt(localStorage.getItem(TAB_COUNT_KEY) || "0", 10);
    if (tabCount > 1) {
        localStorage.setItem(TAB_COUNT_KEY, (tabCount - 1).toString());
    } else {
        localStorage.removeItem(TAB_COUNT_KEY);
        clearLocalStorage();
    }
}

// Función para limpiar el localStorage
function clearLocalStorage() {
    localStorage.clear();
}

export function useTabCount(
    shouldUseTabCount: boolean,
    logoutUser: () => void
) {
    useEffect(() => {
        if (!shouldUseTabCount) return;

        // Obtener o generar un ID único para la pestaña
        let tabId = localStorage.getItem(TAB_ID_KEY);
        if (!tabId) {
            tabId = generateUUID();
            localStorage.setItem(TAB_ID_KEY, tabId);
        }

        // Incrementar el contador al cargar la página
        incrementTabCount();

        // Decrementar el contador al cerrar la pestaña
        const handleBeforeUnload = () => {
            decrementTabCount();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Verificar integridad del contador al cargar la página
        const tabCount = parseInt(
            localStorage.getItem(TAB_COUNT_KEY) || "0",
            10
        );
        if (isNaN(tabCount) || tabCount < 1) {
            console.warn("Tab count mismatch. Possible tampering detected.");
            logoutUser();
        }

        // Cleanup
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            decrementTabCount();
        };
    }, [shouldUseTabCount, logoutUser]);
}
