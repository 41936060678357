import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import { AsideMenuItems } from "@/shared/components/aside-menu/AsideMenuItems";
import { AsideMenuFooterSection } from "@/shared/components/aside-menu/AsideMenuFooter";
import {
    drawerWidth,
    openedMixin,
    closedMixin,
    DrawerHeader,
    DrawerFooter,
} from "@/shared/components/aside-menu/AsideMenuStyles";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    MenuIcon,
} from "./aside-menu/Icons";
import { UserInterface } from "../utils/interfaces";
import { PlatformTheme } from "../utils/enums";
import MainLogo from "@/shared/assets/logiflex_logo_v1.png";

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": {
            ...openedMixin(theme),
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": {
            ...closedMixin(theme),
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },
    }),
}));

interface MiniDrawerProps {
    user: UserInterface;
    notificationsCount: number;
    component: React.ReactNode;
    currentPlatformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function MiniDrawer({
    user,
    component,
    notificationsCount,
    currentPlatformTheme,
    setPlatformTheme,
}: MiniDrawerProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        return () => {
            handleDrawerClose();
        };
    }, []);

    useEffect(() => {
        switch (currentPlatformTheme) {
            case PlatformTheme.LIGHT:
                document.documentElement.style.setProperty(
                    "--aside-menu-background-color",
                    "#FFFFFF"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-text-color",
                    "#666666"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-icon-color",
                    "rgba(0, 0, 0, 0.54)"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-avatar-text-color",
                    "#000000"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-divider-color",
                    "rgba(0, 0, 0, 0.12)"
                );
                break;
            case PlatformTheme.DARK:
                document.documentElement.style.setProperty(
                    "--aside-menu-background-color",
                    "#1F1F1F"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-text-color",
                    "#FFFFFF"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-icon-color",
                    "#B0B0B0"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-avatar-text-color",
                    "#FFFFFF"
                );

                document.documentElement.style.setProperty(
                    "--aside-menu-divider-color",
                    "rgba(255, 255, 255, 12%)"
                );
                break;
            default:
                break;
        }
    }, [currentPlatformTheme]);

    const handleDrawerOpen = () => {
        document.documentElement.style.setProperty(
            "--aside-menu-width",
            "275px"
        );

        document.documentElement.style.setProperty(
            "--aside-menu-main-component-padding",
            "8px 24px 24px 57px"
        );

        setOpen(true);
    };

    const handleDrawerClose = () => {
        document.documentElement.style.setProperty(
            "--aside-menu-width",
            "65px"
        );

        document.documentElement.style.setProperty(
            "--aside-menu-main-component-padding",
            "8px 24px 24px 24px"
        );

        setOpen(false);
    };

    return (
        <div className="aside-menu">
            <Box sx={{ display: "flex" }}>
                <CssBaseline />

                <Drawer variant="permanent" open={open}>
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {open ? (
                            <>
                                <Box
                                    sx={{
                                        width: "60%",
                                        marginLeft: "5.5%",
                                        marginTop: "4%",
                                    }}
                                >
                                    <img
                                        className="login-form__logo-image"
                                        src={MainLogo}
                                        alt="Logiflex Logo"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </Box>

                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === "rtl" ? (
                                        <ChevronRightIcon />
                                    ) : (
                                        <ChevronLeftIcon />
                                    )}
                                </IconButton>
                            </>
                        ) : (
                            <IconButton
                                color="inherit"
                                onClick={handleDrawerOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </DrawerHeader>

                    <Divider />

                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        <AsideMenuItems userRole={user.role} open={open} />
                    </Box>

                    <DrawerFooter className="aside-menu-footer">
                        <AsideMenuFooterSection
                            open={open}
                            user={user}
                            notificationsCount={notificationsCount}
                            platformTheme={currentPlatformTheme}
                            setPlatformTheme={setPlatformTheme}
                        />
                    </DrawerFooter>
                </Drawer>

                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {component}
                </Box>
            </Box>
        </div>
    );
}
