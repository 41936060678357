import React from "react";
import { Menu, MenuItem } from "@mui/material";

interface ProfileMenuProps {
    asideMenuOpen: boolean;
    anchorEl: null | HTMLElement;
    isAccountMenuOpen: boolean;
    handleProfileImageClose: () => void;
}

export const ProfileMenu = ({
    anchorEl,
    asideMenuOpen,
    isAccountMenuOpen,
    handleProfileImageClose,
}: ProfileMenuProps) => (
    <Menu
        id="profile-picture-menu"
        anchorEl={anchorEl}
        open={isAccountMenuOpen}
        sx={{
            marginTop: "10px",
            marginLeft: asideMenuOpen ? "115px" : "49px",
        }}
        onClose={handleProfileImageClose}
    >
        <MenuItem id="profile-picture-menu-account-settings">
            Configuración de Cuenta
        </MenuItem>
        <MenuItem id="profile-picture-menu-logout">Cerrar Sesión</MenuItem>
    </Menu>
);
