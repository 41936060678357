import React, { useCallback, useMemo } from "react";
import {
    Unstable_NumberInput as BaseNumberInput,
    NumberInputProps,
    numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import { PlatformTheme } from "@/shared/utils/enums";

const CustomNumber = React.memo(
    React.forwardRef(function NumberInput(
        props: NumberInputProps & {
            platformTheme: PlatformTheme;
            handleChange: (
                event:
                    | React.FocusEvent<HTMLInputElement>
                    | React.PointerEvent<Element>
                    | React.KeyboardEvent<Element>
                    | React.ChangeEvent<HTMLInputElement>,
                value: number | null
            ) => void;
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) {
        const { platformTheme, handleChange, ...otherProps } = props;

        const slots = useMemo(
            () => ({
                root: (slotProps: any) => (
                    <StyledInputRoot
                        {...slotProps}
                        platformTheme={platformTheme}
                    />
                ),
                input: (slotProps: any) => (
                    <StyledInputElement
                        {...slotProps}
                        platformTheme={platformTheme}
                    />
                ),
                incrementButton: (slotProps: any) => (
                    <StyledButton
                        {...slotProps}
                        platformTheme={platformTheme}
                    />
                ),
                decrementButton: (slotProps: any) => (
                    <StyledButton
                        {...slotProps}
                        platformTheme={platformTheme}
                    />
                ),
            }),
            [platformTheme]
        );

        const slotProps = useMemo(
            () => ({
                incrementButton: {
                    children: "▴",
                },
                decrementButton: {
                    children: "▾",
                },
            }),
            []
        );

        return (
            <BaseNumberInput
                onChange={handleChange}
                slots={slots}
                slotProps={slotProps}
                {...otherProps}
                ref={ref}
            />
        );
    })
);

interface CustomNumberInputProps {
    min?: number;
    max?: number;
    placeholder: string;
    platformTheme: PlatformTheme;
    setNumberProperty: (value: number | null) => void;
}

const CustomNumberInput = React.memo(function CustomNumberInput({
    min,
    max,
    placeholder,
    platformTheme,
    setNumberProperty,
}: CustomNumberInputProps) {
    const handleChange = useCallback(
        (
            event:
                | React.FocusEvent<HTMLInputElement>
                | React.PointerEvent<Element>
                | React.KeyboardEvent<Element>
                | React.ChangeEvent<HTMLInputElement>,
            value: number | null
        ) => {
            setNumberProperty(value);
        },
        [setNumberProperty]
    );

    return (
        <CustomNumber
            min={min}
            max={max}
            aria-label={placeholder}
            placeholder={placeholder}
            platformTheme={platformTheme}
            handleChange={handleChange}
        />
    );
});

const blue = {
    100: "#DAECFF",
    200: "#80BFFF",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    700: "#0059B2",
};

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
};

const StyledInputRoot = styled("div")<{ platformTheme: PlatformTheme }>(
    ({ platformTheme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${platformTheme === PlatformTheme.DARK ? grey[300] : grey[900]};
  background: ${platformTheme === PlatformTheme.DARK ? grey[900] : "#fff"};
  border: 1px solid ${platformTheme === PlatformTheme.DARK ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
      platformTheme === PlatformTheme.DARK
          ? "rgba(0,0,0, 0.5)"
          : "rgba(0,0,0, 0.05)"
  };
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${platformTheme === PlatformTheme.DARK ? blue[700] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledInputElement = styled("input")<{ platformTheme: PlatformTheme }>(
    ({ platformTheme }) => `
  width: 100%;
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${platformTheme === PlatformTheme.DARK ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);

const StyledButton = styled("button")<{ platformTheme: PlatformTheme }>(
    ({ platformTheme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${platformTheme === PlatformTheme.DARK ? grey[900] : "#fff"};
  border: 0;
  color: ${platformTheme === PlatformTheme.DARK ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${platformTheme === PlatformTheme.DARK ? grey[800] : grey[50]};
    border-color: ${platformTheme === PlatformTheme.DARK ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${platformTheme === PlatformTheme.DARK ? grey[700] : grey[200]};
    background: ${platformTheme === PlatformTheme.DARK ? grey[900] : grey[50]};
    color: ${platformTheme === PlatformTheme.DARK ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${platformTheme === PlatformTheme.DARK ? blue[600] : blue[500]};
      border-color: ${platformTheme === PlatformTheme.DARK ? blue[400] : blue[600]};
    }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    border-color: ${platformTheme === PlatformTheme.DARK ? grey[700] : grey[200]};
    background: ${platformTheme === PlatformTheme.DARK ? grey[900] : grey[50]};
    color: ${platformTheme === PlatformTheme.DARK ? grey[200] : grey[900]};

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${platformTheme === PlatformTheme.DARK ? blue[600] : blue[500]};
      border-color: ${platformTheme === PlatformTheme.DARK ? blue[400] : blue[600]};
    }
  }

  & .arrow {
    transform: translateY(-1px);
  }
`
);

export default CustomNumberInput;
