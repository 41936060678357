import React from "react";
import { Box, Typography } from "@mui/material";
import { UserInterface } from "@/shared/utils/interfaces";
import {
    capitalizeString,
    getTranslatedRole,
} from "@/shared/components/aside-menu/AvatarUtils";

interface UserDetailsProps {
    user: UserInterface;
}

export const UserDetails = ({ user }: UserDetailsProps) => (
    <Box sx={{ display: "block", width: "100%" }}>
        <Typography
            sx={{
                marginLeft: "6%",
                fontSize: "0.8rem",
                maxWidth: "160px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {user.fullName}
        </Typography>

        <Typography
            sx={{
                marginLeft: "6%",
                fontSize: "0.70rem",
                maxWidth: "160px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {user.role.toLowerCase() === "admin customer" ||
            user.role.toLowerCase() === "customer"
                ? user.company
                : capitalizeString(getTranslatedRole(user.role))}
        </Typography>
    </Box>
);
