import { useAxios } from "@/shared/utils/classes";
import { GetAllCompaniesResponseInterface } from "@/customer-portal/actions/get-all-companies-admin/GetAllCompaniesResponse.interface";

export async function GetAllCompanies(): Promise<GetAllCompaniesResponseInterface> {
    const axios = new useAxios();

    const response = await axios.get("customer/companies-admin");

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
        companies: response.data,
    };
}
