import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const useCurrentTime = (timezone: string) => {
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    const [isCurrentTimeLoading, setIsCurrentTimeLoading] =
        useState<boolean>(true);

    useEffect(() => {
        const fetchTime = async () => {
            try {
                const response = await axios.get(
                    `http://worldtimeapi.org/api/timezone/${timezone}`
                );
                const initialTime = dayjs(response.data.datetime).toDate();
                setCurrentTime(initialTime);
                setIsCurrentTimeLoading(false);

                const intervalId = setInterval(() => {
                    const now = dayjs().tz(timezone).toDate();
                    setCurrentTime(now);
                }, 1000);

                return () => clearInterval(intervalId);
            } catch (error) {
                console.error("Error fetching time:", error);
                setIsCurrentTimeLoading(false);
            }
        };

        fetchTime();
    }, [timezone]);

    return { currentTime, isCurrentTimeLoading };
};

export default useCurrentTime;
