import * as Axios from "axios";
import { logout } from "@/shared/utils/classes";
import { Constants } from "@/shared/utils/constants";
import { UseAxiosInterface } from "@/shared/utils/classes/axios/UseAxios.interface";
import { UseAxiosActionInterface } from "@/shared/utils/classes/axios/UseAxiosAction.interface";
import { RetryUserAxiosInterface } from "./RetryUseAxios.interface";

export class useAxios implements UseAxiosInterface {
    private readonly axiosUrl = Constants.API_URL;
    private readonly axiosInstance = Axios.default.create();

    constructor() {
        this.axiosInstance.interceptors.request.use((config) => {
            const token = localStorage.getItem("accessToken");

            if (token) {
                config.headers["Authorization"] = token;
            }

            return config;
        });
    }

    public async get(
        path: string,
        params?: any,
        signal?: AbortSignal
    ): Promise<UseAxiosActionInterface> {
        return this.requestHandler("GET", path, { params, signal });
    }

    public async post(
        path: string,
        data?: any,
        signal?: AbortSignal
    ): Promise<UseAxiosActionInterface> {
        return this.requestHandler("POST", path, { data, signal });
    }

    public async put(
        path: string,
        data?: any,
        signal?: AbortSignal
    ): Promise<UseAxiosActionInterface> {
        return this.requestHandler("PUT", path, { data, signal });
    }

    public async delete(
        path: string,
        data?: any,
        signal?: AbortSignal
    ): Promise<UseAxiosActionInterface> {
        return this.requestHandler("DELETE", path, { data, signal });
    }

    public async patch(
        path: string,
        data?: any,
        signal?: AbortSignal
    ): Promise<UseAxiosActionInterface> {
        return this.requestHandler("PATCH", path, { data, signal });
    }

    private async requestHandler(
        method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
        path: string,
        config: any
    ): Promise<UseAxiosActionInterface> {
        try {
            const response = await this.axiosInstance.request({
                method,
                url: `${this.axiosUrl}/${path}`,
                ...config,
                withCredentials: true,
            });

            return {
                httpStatus: response.status,
                data: response.data,
            };
        } catch (error: any) {
            if (Axios.default.isCancel(error)) {
                return {
                    httpStatus: 499, // Código HTTP no oficial para Cancelled
                    message: "Request was cancelled",
                };
            }

            this.checkLogout(error.response?.status || 500);

            if (error.response?.status === 401 && !config._retry) {
                config._retry = true;
                return await this.checkInvalidAccessToken(
                    error.response?.status,
                    {
                        method,
                        path,
                        data: config.data,
                    }
                );
            }

            return {
                httpStatus: error.response?.status || 500,
                message:
                    error.response?.data?.message ||
                    "Un error inesperado ha ocurrido, por favor inténtelo de nuevo más tarde.",
            };
        }
    }

    private async checkInvalidAccessToken(
        httpStatusCode: number,
        requestData: RetryUserAxiosInterface
    ): Promise<UseAxiosActionInterface> {
        const newAccessToken = await this.post("access-token");

        if (newAccessToken.httpStatus === 200) {
            localStorage.setItem(
                "accessToken",
                newAccessToken.data.accessToken
            );
            return await this.retryRequest(requestData);
        }

        return {
            httpStatus: httpStatusCode,
            message:
                "Un error inesperado ha ocurrido, por favor inténtelo de nuevo más tarde.",
        };
    }

    private async retryRequest(
        requestData: RetryUserAxiosInterface
    ): Promise<UseAxiosActionInterface> {
        switch (requestData.method) {
            case "GET":
                return this.get(requestData.path, requestData.data);
            case "POST":
                return this.post(requestData.path, requestData.data);
            case "PUT":
                return this.put(requestData.path, requestData.data);
            case "DELETE":
                return this.delete(requestData.path, requestData.data);
            case "PATCH":
                return this.patch(requestData.path, requestData.data);
        }
    }

    private checkLogout(httpStatusCode: number): void {
        if (httpStatusCode === 403) {
            logout();
        }
    }
}
