import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
    allowedRoles: string[] | "*";
    userRole: string;
    element: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    allowedRoles,
    userRole,
    element,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAllowed, setIsAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (!userRole) {
            setIsAllowed(false);
            setIsLoading(false);
            return;
        }

        if (allowedRoles === "*") {
            setIsAllowed(true);
            setIsLoading(false);
            return;
        }

        if (allowedRoles.includes(userRole.toLowerCase())) {
            setIsAllowed(true);
            setIsLoading(false);
            return;
        }

        setIsAllowed(false);
        setIsLoading(false);
    }, [allowedRoles, userRole]);

    return (
        <>
            {isLoading ? (
                <></>
            ) : isAllowed ? (
                <>{element}</>
            ) : (
                <Navigate to="/" replace />
            )}
        </>
    );
};

export default PrivateRoute;
