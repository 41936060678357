import { useAxios } from "@/shared/utils/classes";
import { CreateEmployeeRequestInterface } from "@/admin-portal/actions/create-employee/CreateEmployeeRequest.interface";
import { CreateEmployeeResponseInterface } from "@/admin-portal/actions/create-employee/CreateEmployeeResponse.interface";

export async function CreateEmployee(
    employeeData: CreateEmployeeRequestInterface
): Promise<CreateEmployeeResponseInterface> {
    const {
        firstName,
        lastName,
        employeeType,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    } = employeeData;

    const axios = new useAxios();

    const response = await axios.post("user/create-employee", {
        firstName,
        lastName,
        employeeType,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
