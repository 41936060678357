import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import UpdateEmployeeForm from "@/admin-portal/components/extras/user-management/UpdateEmployeeForm";
import UpdateCompanyUserForm from "@/admin-portal/components/extras/user-management/UpdateCustomerForm";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { UserInterface } from "@/shared/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";

interface UpdateUserFormProps {
    updatingUser: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    cities: Map<string, CityInterface[]>;
    selectedUser: UserInterface;
    resetPageNumberAndFetchUsers: () => void;
    setUpdatingUser: (updatingUser: boolean) => void;
    setUsersUpdated: (usersUpdated: boolean) => void;
    setIsUpdateUserDrawerOpen: (isOpen: boolean) => void;
}

const UpdateUserForm = ({
    regions,
    cities,
    updatingUser,
    selectedUser,
    platformTheme,
    setUpdatingUser,
    setUsersUpdated,
    setIsUpdateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: UpdateUserFormProps) => {
    const isUserEmployeeOrCustomer = (): "Employee" | "Customer" => {
        return selectedUser.role === "Customer" ||
            selectedUser.role === "Admin Customer"
            ? "Customer"
            : "Employee";
    };

    return (
        <Box>
            {selectedUser && isUserEmployeeOrCustomer() === "Employee" && (
                <UpdateEmployeeForm
                    cities={cities}
                    regions={regions}
                    selectedUser={selectedUser}
                    updatingUser={updatingUser}
                    platformTheme={platformTheme}
                    setUpdatingUser={setUpdatingUser}
                    setUsersUpdated={setUsersUpdated}
                    setIsUpdateUserDrawerOpen={setIsUpdateUserDrawerOpen}
                    resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
                />
            )}

            {selectedUser && isUserEmployeeOrCustomer() === "Customer" && (
                <UpdateCompanyUserForm
                    cities={cities}
                    regions={regions}
                    selectedUser={selectedUser}
                    updatingUser={updatingUser}
                    platformTheme={platformTheme}
                    setUpdatingUser={setUpdatingUser}
                    setUsersUpdated={setUsersUpdated}
                    setIsUpdateUserDrawerOpen={setIsUpdateUserDrawerOpen}
                    resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
                />
            )}
        </Box>
    );
};

export default UpdateUserForm;
