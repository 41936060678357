import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DarkModeIcon, LightModeIcon } from "./Icons";
import { PlatformTheme } from "@/shared/utils/enums";
import { setPlatformTheme } from "@/shared/utils/classes";

interface PlatformThemeSectionProps {
    open: boolean;
    platformTheme: PlatformTheme;
    setPlatformThemeState: (theme: PlatformTheme) => void;
}

export function PlatformThemeSection({
    open,
    platformTheme,
    setPlatformThemeState,
}: PlatformThemeSectionProps) {
    const handlePlatformThemeChange = () => {
        setPlatformThemeState(
            platformTheme === PlatformTheme.LIGHT
                ? PlatformTheme.DARK
                : PlatformTheme.LIGHT
        );

        setPlatformTheme(
            platformTheme === PlatformTheme.LIGHT
                ? PlatformTheme.DARK
                : PlatformTheme.LIGHT
        );
    };

    return (
        <ListItem key={"Tema"} disablePadding sx={{ display: "block" }}>
            <Tooltip
                title="Cambiar Tema de la Plataforma"
                placement="right"
                arrow
            >
                <ListItemButton
                    onClick={handlePlatformThemeChange}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {platformTheme === PlatformTheme.LIGHT ? (
                            <LightModeIcon />
                        ) : (
                            <DarkModeIcon />
                        )}
                    </ListItemIcon>

                    <ListItemText
                        primary={`${platformTheme === PlatformTheme.LIGHT ? "Modo Claro" : "Modo Oscuro"}`}
                        sx={{ opacity: open ? 1 : 0 }}
                    />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}
