import { PlatformTheme } from "@/shared/utils/enums";
import { Box, Button, ButtonGroup, InputLabel } from "@mui/material";

interface UserTypeSelectorProps {
    platformTheme: PlatformTheme;
    selectedUserType: "Employee" | "Admin Customer" | "Customer";
    setSelectedUserType: (
        type: "Employee" | "Admin Customer" | "Customer"
    ) => void;
}

const UserTypeSelector = ({
    platformTheme,
    selectedUserType,
    setSelectedUserType,
}: UserTypeSelectorProps) => {
    return (
        <Box sx={{ width: "100%", marginTop: "17.5px" }}>
            <InputLabel
                htmlFor="admin-create-user-type-input"
                sx={{
                    marginBottom: "5px",
                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                }}
            >
                Tipo de Usuario
            </InputLabel>

            <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
                <Button
                    onClick={() => setSelectedUserType("Employee")}
                    sx={{
                        width: "calc(100% / 3)",
                        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? (selectedUserType === "Employee" ? "#f0f0f0" : "transparent") : selectedUserType === "Employee" ? "rgb(76, 0, 153)" : "#424242"}`,
                        color: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#FFFFFF"}`,
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(25, 118, 210, 0.5)" : "rgb(76, 0, 153)"}`,
                        "&:hover": {
                            backgroundColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Employee" ? "rgb(51, 0, 102)" : "#616161") : undefined}`,
                            borderColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Employee" ? "rgb(76, 0, 153)" : "#720ED8") : undefined}`,
                        },
                    }}
                >
                    Empleado
                </Button>
                <Button
                    onClick={() => setSelectedUserType("Admin Customer")}
                    sx={{
                        width: "calc(100% / 3)",
                        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? (selectedUserType === "Admin Customer" ? "#f0f0f0" : "transparent") : selectedUserType === "Admin Customer" ? "rgb(76, 0, 153)" : "#424242"}`,
                        color: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#FFFFFF"}`,
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(25, 118, 210, 0.5)" : "rgb(76, 0, 153)"}`,
                        "&:hover": {
                            backgroundColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Admin Customer" ? "rgb(51, 0, 102)" : "#616161") : undefined}`,
                            borderColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Admin Customer" ? "rgb(76, 0, 153)" : "#720ED8") : undefined}`,
                        },
                    }}
                >
                    Empresa
                </Button>
                <Button
                    onClick={() => setSelectedUserType("Customer")}
                    sx={{
                        width: "calc(100% / 3)",
                        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? (selectedUserType === "Customer" ? "#f0f0f0" : "transparent") : selectedUserType === "Customer" ? "rgb(76, 0, 153)" : "#424242"}`,
                        color: `${platformTheme === PlatformTheme.LIGHT ? "#1976d2" : "#FFFFFF"}`,
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(25, 118, 210, 0.5)" : "rgb(76, 0, 153)"}`,
                        "&:hover": {
                            backgroundColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Customer" ? "rgb(51, 0, 102)" : "#616161") : undefined}`,
                            borderColor: `${platformTheme === PlatformTheme.DARK ? (selectedUserType === "Customer" ? "rgb(76, 0, 153)" : "#720ED8") : undefined}`,
                        },
                    }}
                >
                    Usuario
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default UserTypeSelector;
