import React from "react";
import { Box, List } from "@mui/material";
import { UserInterface } from "@/shared/utils/interfaces";
import { AvatarSection } from "@/shared/components/aside-menu/AvatarSection";
import { NotificationsSection } from "@/shared/components/aside-menu/NotificationsSection";
import { PlatformThemeSection } from "@/shared/components/aside-menu/PlatformThemeSection";
import { PlatformTheme } from "@/shared/utils/enums";
import { setPlatformTheme } from "@/shared/utils/classes";

interface AsideMenuFooterSectionProps {
    open: boolean;
    user: UserInterface;
    notificationsCount: number;
    platformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export function AsideMenuFooterSection({
    open,
    user,
    platformTheme,
    setPlatformTheme,
    notificationsCount,
}: AsideMenuFooterSectionProps) {
    return (
        <Box sx={{ width: "100%" }}>
            <List>
                <NotificationsSection
                    open={open}
                    notificationsCount={notificationsCount}
                />

                <PlatformThemeSection
                    open={open}
                    platformTheme={platformTheme}
                    setPlatformThemeState={setPlatformTheme}
                />
            </List>

            <AvatarSection open={open} user={user} />
        </Box>
    );
}
