import { useState, useEffect, useRef } from "react";
import { useToast } from "@/shared/utils/classes";
import {
    CityInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import {
    Box,
    TextField,
    InputLabel,
    InputAdornment,
    Typography,
    TableContainer,
    TableHead,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    Grid,
    Button,
    CircularProgress,
    ButtonBase,
    Tooltip,
    IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useBarcodeScanner from "@/hooks/useBarcodeScanner";
import { CreateEmployeeRequestInterface } from "@/admin-portal/actions/create-employee/CreateEmployeeRequest.interface";
import { PlatformTheme } from "@/shared/utils/enums";
import { esES } from "@mui/x-date-pickers/locales";
import CustomNumberInput from "@/shared/components/CustomNumberInput";
import { ProductInterface } from "@/shared/utils/interfaces";
import { GetProductsByCompanyForSelects } from "@/customer-portal/actions/get-products-by-company-for-select/GetProductsByCompanyForSelect";
import { NumberSeparationDots } from "@/shared/utils/classes";

import "dayjs/locale/es";
import isoWeek from "dayjs/plugin/isoWeek";

//Icons
import EmailIcon from "@mui/icons-material/Email";
import InventoryIcon from "@mui/icons-material/Inventory";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

interface CreateOrderFormProps {
    currentTime: Date;
    currentCompany: string;
    creatingOrder: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    isCurrentTimeLoading: boolean;
    cities: Map<string, CityInterface[]>;
    setCreatingOrder: (creatingUser: boolean) => void;
    setIsCreateOrderDrawerOpen: (isOpen: boolean) => void;
}

const CreateOrderForm = ({
    cities,
    regions,
    currentTime,
    creatingOrder,
    platformTheme,
    currentCompany,
    setCreatingOrder,
    isCurrentTimeLoading,
    setIsCreateOrderDrawerOpen,
}: CreateOrderFormProps) => {
    //#region States
    const [searchedProducts, setSearchedProducts] = useState<
        ProductInterface[]
    >([]);
    const [alreadyValidated, setAlreadyValidated] = useState<boolean>(false);
    const [productInputSearchValue, setProductInputSearchValue] =
        useState<string>("");
    const [isSearchingProducts, setIsSearchingProducts] =
        useState<boolean>(false);
    const [internalOrderNumber, setInternalOrderNumber] = useState<string>("");
    const [internalOrderNumberValid, setInternalOrderNumberValid] =
        useState<boolean>(true);
    const [selectedProducts, setSelectedProducts] = useState<
        ProductInterface[]
    >([]);

    const productSearchInputWidthRef = useRef<number>(0);

    //#endregion States
    dayjs.locale("es");
    dayjs.extend(isoWeek);

    const productAbortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        resizeForm();

        window.addEventListener("resize", resizeForm);

        return () => {
            window.removeEventListener("resize", resizeForm);
        };
    }, []);

    const resizeForm = () => {
        assignProductInputWidth();
    };

    const assignProductInputWidth = () => {
        productSearchInputWidthRef.current =
            document.getElementById(
                "customer-portal-create-order-products-input"
            )?.parentElement?.parentElement?.clientWidth || 0;
    };

    // useEffect(() => {
    //     if (creatingUser) {
    //         setAlreadyValidated(true);

    //         const isFormValid = validateEmployeeForm();

    //         if (!isFormValid) {
    //             useToast.toastRequest({
    //                 message:
    //                     "Por favor, revisa los campos del formulario y asegúrate de que toda la información sea correcta antes de continuar.",
    //                 type: "warning",
    //             });

    //             setCreatingUser(false);
    //             return;
    //         }

    //         createEmployee({
    //             firstName,
    //             lastName,
    //             employeeType: translateEmployeeType(employeeType),
    //             email,
    //             identityDocumentNumber:
    //                 selectedDocumentType === "nationalIdentityNumber"
    //                     ? identityDocumentNumber
    //                     : passport,
    //             phoneNumber,
    //             cityId: userCityId,
    //             street,
    //             streetNumber,
    //             apartmentNumber: apartment,
    //         });
    //     }
    // }, [creatingUser]);

    const validateOrderForm = (): boolean => {
        // const validations = [
        //     validateFirstName(firstName, setFirstNameValid),
        //     validateLastName(lastName, setLastNameValid),
        //     validateEmail(email, setEmailValid),
        //     validateEmployeeType(employeeType, setEmployeeTypeValid),
        //     selectedDocumentType === "nationalIdentityNumber"
        //         ? validateIdentityNumber(
        //               identityDocumentNumber,
        //               setIdentityDocumentNumberValid
        //           )
        //         : validatePassport(passport, setPassportValid),
        //     validatePhoneNumber(phoneNumber, setPhoneNumberValid),
        //     validateRegion(userRegionId, setUserRegionIdValid),
        //     validateCity(userCityId, setUserCityIdValid),
        //     validateStreet(street, setStreetValid),
        //     validateStreetNumber(streetNumber, setStreetNumberValid),
        //     validateApartmentNumber(apartment, setApartmentValid),
        // ];

        //return validations.every((isValid) => isValid);
        return false;
    };

    const createEmployee = async (
        employeeData: CreateEmployeeRequestInterface
    ) => {
        // const { httpStatusCode }: CreateEmployeeResponseInterface =
        //     await useToast.toastPromiseRequest({
        //         promise: CreateEmployee(employeeData),
        //         pendingMessage: "Creación de empleado en progreso",
        //         successMessage: "Creación de empleado exitosa",
        //     });
        // handleEmployeeCreationResult(httpStatusCode);
    };

    const handleEmployeeCreationResult = (httpStatusCode: number) => {
        // const wasEmployeeCreationSuccessful =
        //     checkIfEmployeeCreationWasSuccessful(httpStatusCode);

        // if (wasEmployeeCreationSuccessful) {
        //     setIsCreateUserDrawerOpen(false);
        //     resetPageNumberAndFetchUsers();
        // }

        setCreatingOrder(false);
    };

    const shouldDisableDate = (date: dayjs.Dayjs) => {
        const selectedDate = date.toDate();
        const dayOfWeek = selectedDate.getDay();

        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return true;
        }

        if (isCurrentTimeLoading) {
            const deviceDate = new Date();
            return selectedDate < deviceDate || selectedDate >= deviceDate;
        }

        const today = new Date(currentTime);
        today.setHours(0, 0, 0, 0);

        if (selectedDate < today) {
            return true;
        }

        const currentHour = currentTime.getHours();
        if (selectedDate.getTime() === today.getTime() && currentHour >= 12) {
            return true;
        }

        return false;
    };

    const handleBarcodeScan = (data: string) => {
        if (data) {
            console.log(`Barcode data received: ${data}`);
        }
    };

    const handleBarcodeError = (error: string) => {
        useToast.toastRequest({
            message: error,
            type: "warning",
        });
    };

    useBarcodeScanner({
        onScan: handleBarcodeScan,
        onError: handleBarcodeError,
    });

    const searchProducts = async (searchText: string) => {
        setIsSearchingProducts(true);
        setProductInputSearchValue(searchText);

        if (productAbortControllerRef.current) {
            productAbortControllerRef.current.abort();
        }

        if (searchText.length === 0) {
            setSearchedProducts([]);
            setIsSearchingProducts(false);
            return;
        }

        const newAbortController = new AbortController();
        productAbortControllerRef.current = newAbortController;

        const products = await GetProductsByCompanyForSelects({
            searchText,
            companyName: currentCompany,
            abortController: productAbortControllerRef.current,
        });

        if (products !== undefined) {
            setSearchedProducts(products);
            setIsSearchingProducts(false);
        }
    };

    const selectProduct = (product: ProductInterface) => {
        console.log(selectedProducts);

        if (
            selectedProducts.some(
                (selectedProduct) => selectedProduct.id === product.id
            )
        ) {
            return;
        }

        console.log([...selectedProducts, product]);

        setSelectedProducts([...selectedProducts, product]);

        setSearchedProducts([]);
        setProductInputSearchValue("");
        (
            document.getElementById(
                "customer-portal-create-order-products-input"
            ) as HTMLInputElement
        ).value = "";
    };

    const deleteProduct = (productId: string) => {
        const newSelectedProducts = selectedProducts.filter(
            (product) => product.id !== productId
        );

        setSelectedProducts(newSelectedProducts);
    };

    return (
        <>
            <Box>
                <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                    <InputLabel
                        htmlFor="customer-create-order-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número de Orden
                    </InputLabel>

                    <TextField
                        id="customer-create-order-order-number-input"
                        size="small"
                        placeholder="Ingresa el número de orden"
                        autoComplete="off"
                        error={!internalOrderNumberValid}
                        value={internalOrderNumber}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                //validateEmail(e.target.value, setEmailValid);
                            }

                            setInternalOrderNumber(e.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginTop: "20px" }}>
                    <InputLabel
                        htmlFor="customer-create-order-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Fecha de Ejecución
                    </InputLabel>

                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="es"
                        localeText={
                            esES.components.MuiLocalizationProvider.defaultProps
                                .localeText
                        }
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <DesktopDatePicker
                                format="DD/MM/YYYY"
                                localeText={{}}
                                shouldDisableDate={(date: dayjs.Dayjs) =>
                                    shouldDisableDate(date)
                                }
                                sx={{
                                    width: "100%",
                                    ".MuiInputBase-input": {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        height: "45px",
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover": {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    "& .MuiSvgIcon-root": {
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    },
                                }}
                                slotProps={{
                                    textField: { size: "small" },
                                    field: {
                                        clearable: true,
                                    },
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>

                <Box
                    sx={{
                        marginTop: "20px",
                    }}
                >
                    <InputLabel
                        htmlFor="customer-portal-create-order-products-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Productos
                    </InputLabel>

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            marginTop: "0px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <Grid xs={8}>
                            <TextField
                                id="customer-portal-create-order-products-input"
                                size="small"
                                placeholder="Nombre o código de barra del producto"
                                autoComplete="off"
                                fullWidth
                                onFocus={() =>
                                    searchedProducts.length > 0 &&
                                    setProductInputSearchValue(" ")
                                }
                                onBlur={(e) => {
                                    if (
                                        !e.relatedTarget?.id.startsWith(
                                            "customer-portal-create-order-select-product"
                                        )
                                    ) {
                                        setProductInputSearchValue("");
                                    }
                                }}
                                onChange={(e) => {
                                    searchProducts(e.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InventoryIcon
                                                sx={{
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                }}
                                sx={{
                                    marginTop: "5px",
                                    marginBottom: "10px",
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                            },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                },
                                        },
                                    },
                                }}
                            />

                            {searchedProducts &&
                            searchedProducts.length > 0 &&
                            productInputSearchValue.length > 0 ? (
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        position: "fixed",
                                        width: `${productSearchInputWidthRef.current}px`,
                                        transform: "translateY(-8px)",
                                        zIndex: 100,
                                    }}
                                >
                                    <Table>
                                        <TableBody>
                                            {searchedProducts.map(
                                                (searchedProduct) => (
                                                    <ButtonBase
                                                        id={`customer-portal-create-order-select-product-${searchedProduct.id}`}
                                                        sx={{
                                                            width: `${productSearchInputWidthRef.current}px`,
                                                        }}
                                                    >
                                                        <TableRow
                                                            key={
                                                                searchedProduct.name
                                                            }
                                                            onClick={() =>
                                                                selectProduct(
                                                                    searchedProduct
                                                                )
                                                            }
                                                            sx={{
                                                                width: `${productSearchInputWidthRef.current}px`,
                                                                cursor: "pointer",
                                                                "&:hover": {
                                                                    backgroundColor:
                                                                        "#f1f1f1",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                sx={{
                                                                    maxWidth:
                                                                        "175px",
                                                                    overflow:
                                                                        "hidden",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        searchedProduct.name
                                                                    }
                                                                    placement="top-start"
                                                                >
                                                                    <span>
                                                                        {
                                                                            searchedProduct.name
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    width: "101px",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        searchedProduct.sku
                                                                    }
                                                                    placement="top-start"
                                                                >
                                                                    <span>
                                                                        {
                                                                            searchedProduct.sku
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    maxWidth:
                                                                        "250px",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={`CLP $${NumberSeparationDots(searchedProduct.price)}`}
                                                                    placement="top-start"
                                                                >
                                                                    <span>
                                                                        CLP $
                                                                        {NumberSeparationDots(
                                                                            searchedProduct.price
                                                                        )}
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    </ButtonBase>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : productInputSearchValue.length > 0 &&
                              !isSearchingProducts ? (
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        position: "fixed",
                                        width: `${productSearchInputWidthRef.current}px`,
                                        transform: "translateY(-8px)",
                                        zIndex: 100,
                                    }}
                                >
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    sx={{ textAlign: "center" }}
                                                >
                                                    No se encontraron productos
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                productInputSearchValue.length > 0 &&
                                isSearchingProducts && (
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            position: "fixed",
                                            width: `${productSearchInputWidthRef.current}px`,
                                            transform: "translateY(-8px)",
                                        }}
                                    >
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        Cargando Productos
                                                        <CircularProgress
                                                            sx={{
                                                                maxWidth:
                                                                    "24px",
                                                                maxHeight:
                                                                    "24px",
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            )}
                        </Grid>

                        <Grid
                            xs={4}
                            sx={{
                                marginTop: "5px",
                                paddingLeft: "5px",
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                sx={{
                                    height: "40px",
                                    backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(55, 129, 239)" : "rgb(76, 0, 153)"}`,
                                    "&:hover": {
                                        backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#2877CC" : "#3B007A"}`,
                                    },
                                }}
                            >
                                Crear Producto
                            </Button>
                        </Grid>
                    </Grid>

                    <TableContainer
                        component={Paper}
                        sx={{
                            minWidth: 600,
                            maxWidth: 600,
                            margin: "10px auto",
                            borderRadius: 2,
                            boxShadow: 3,
                            backgroundColor:
                                platformTheme === PlatformTheme.LIGHT
                                    ? "#fff"
                                    : "#333",
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor:
                                            platformTheme ===
                                            PlatformTheme.LIGHT
                                                ? "#f4f4f4"
                                                : "#444",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: 16,
                                            color:
                                                platformTheme ===
                                                PlatformTheme.LIGHT
                                                    ? "#000"
                                                    : "#ddd",
                                            borderRight: "1px solid #ddd",
                                        }}
                                    >
                                        Nombre
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            width: "90px",
                                            fontWeight: 700,
                                            fontSize: 16,
                                            color:
                                                platformTheme ===
                                                PlatformTheme.LIGHT
                                                    ? "#000"
                                                    : "#ddd",
                                            borderRight: "1px solid #ddd",
                                        }}
                                    >
                                        SKU
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            width: "150px",
                                            fontWeight: 700,
                                            fontSize: 16,
                                            color:
                                                platformTheme ===
                                                PlatformTheme.LIGHT
                                                    ? "#000"
                                                    : "#ddd",
                                            borderRight: "1px solid #ddd",
                                        }}
                                    >
                                        Código de barra
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            width: "123px",
                                            fontWeight: 700,
                                            fontSize: 16,
                                            color:
                                                platformTheme ===
                                                PlatformTheme.LIGHT
                                                    ? "#000"
                                                    : "#ddd",
                                        }}
                                    >
                                        Cantidad
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderLeft: "1px solid #ddd",
                                            color:
                                                platformTheme ===
                                                PlatformTheme.LIGHT
                                                    ? "#000"
                                                    : "#ddd",
                                        }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            {selectedProducts && selectedProducts.length > 0 ? (
                                <TableBody
                                    sx={{
                                        ".css-1rhil68-MuiTableRow-root": {
                                            borderRight: "none",
                                        },
                                    }}
                                >
                                    {selectedProducts.map((selectedProduct) => (
                                        <TableRow
                                            key={selectedProduct.id}
                                            sx={{
                                                borderRight: "1px solid #ddd",
                                                "&:nth-of-type(odd)": {
                                                    backgroundColor:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#f9f9f9"
                                                            : "#444",
                                                },
                                                "&:hover": {
                                                    backgroundColor:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#f1f1f1"
                                                            : "#555",
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{
                                                    borderRight:
                                                        "1px solid #ddd",
                                                    color:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#000"
                                                            : "#ddd",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: 150,
                                                }}
                                                title={selectedProduct.name}
                                            >
                                                {selectedProduct.name}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderRight:
                                                        "1px solid #ddd",
                                                    color:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#000"
                                                            : "#ddd",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: 100,
                                                }}
                                                title={selectedProduct.sku}
                                            >
                                                {selectedProduct.sku}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderRight:
                                                        "1px solid #ddd",
                                                    color:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#000"
                                                            : "#ddd",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    minWidth: 160,
                                                }}
                                                title={selectedProduct.barcode}
                                            >
                                                {selectedProduct.barcode}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderRight: "none",
                                                    textAlign: "center",
                                                    color:
                                                        platformTheme ===
                                                        PlatformTheme.LIGHT
                                                            ? "#000"
                                                            : "#ddd",
                                                }}
                                            >
                                                <CustomNumberInput
                                                    key={selectedProduct.id}
                                                    placeholder="Cant"
                                                    min={1}
                                                    max={999}
                                                    platformTheme={
                                                        platformTheme
                                                    }
                                                    setNumberProperty={(
                                                        quantity: number | null
                                                    ) => {
                                                        console.log(quantity);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderLeft:
                                                        "1px solid #ddd",
                                                    paddingLeft: "5px",
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <Tooltip
                                                    title="Eliminar"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteProduct(
                                                                selectedProduct.id
                                                            )
                                                        }
                                                    >
                                                        <ClearIcon
                                                            sx={{ fill: "red" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                textAlign: "center",
                                                borderBottom: "none",
                                            }}
                                        >
                                            <WarningAmberOutlinedIcon
                                                sx={{
                                                    scale: "2.5",
                                                    marginTop: "15px",
                                                    marginBottom: "15px",
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "#FFFFFF"}`,
                                                }}
                                            />

                                            <Typography
                                                sx={{
                                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "#FFFFFF"}`,
                                                }}
                                            >
                                                No hay productos agregados a la
                                                orden
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};

export default CreateOrderForm;
