import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useAxios } from "@/shared/utils/classes";
import { UserInterface } from "@/shared/utils/interfaces";
import { ExportUsersToXlsxInterface } from "@/admin-portal/actions/export-users-to-xlsx/ExportUsersToXlsx.interface";
import { ExportUsersToXlsxResponseInterface } from "@/admin-portal/actions/export-users-to-xlsx/ExportUsersToXlsxResponse.interface";

export async function ExportUsersToXlsx(): Promise<ExportUsersToXlsxResponseInterface> {
    try {
        const axios = new useAxios();
        const usersAxios = await axios.get("users");

        if (usersAxios.httpStatus > 400) {
            return {
                httpStatusCode: usersAxios.httpStatus,
                message:
                    "Hubo un error al descargar los usuarios, intente más tarde.",
            };
        }

        const users: UserInterface[] = usersAxios.data.users;
        const usersMapped = usersMapperForExcel(users);

        const worksheet = XLSX.utils.json_to_sheet(usersMapped);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Usuarios");

        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
            type: "application/octet-stream",
        });
        saveAs(dataBlob, "Logiflex_Usuarios.xlsx");

        return {
            httpStatusCode: 200,
        };
    } catch (error) {
        return {
            httpStatusCode: 500,
            message:
                "Hubo un error al descargar los usuarios, intente más tarde.",
        };
    }
}

function usersMapperForExcel(
    users: UserInterface[]
): ExportUsersToXlsxInterface[] {
    const usersToExcel: ExportUsersToXlsxInterface[] = [];

    for (const user of users) {
        usersToExcel.push({
            "Nombre Completo": user.fullName,
            "Número de Documento": user.identityDocumentNumber,
            "Correo Electrónico": user.email,
            "Número de Teléfono": user.phoneNumber,
            "Tipo de Usuario": translateRole(user.role),
            Empresa: user.company,
            Dirección: user.address,
        });
    }

    return usersToExcel;
}

function translateRole(role: string): string {
    switch (role) {
        case "Admin":
            return "Administrador";
        case "Carrier":
            return "Transportista";
        case "Customer Service":
            return "Servicio al Cliente";
        case "Warehouse Operator":
            return "Operador de Almacén";
        case "Customer":
            return "Cliente";
        case "Admin Customer":
            return "Administrador de Cliente";
        default:
            return role;
    }
}
