import { useAxios } from "@/shared/utils/classes";
import { AuthRequestInterface } from "@/central-app/actions/auth/AuthRequest.interface";
import { AuthResponseInterface } from "@/central-app/actions/auth/AuthResponse.interface";

export async function Auth(
    authData: AuthRequestInterface
): Promise<AuthResponseInterface> {
    const { email, password } = authData;
    const axios = new useAxios();

    const response = await axios.post("auth", {
        email,
        password,
    });

    return {
        httpStatusCode: response.httpStatus,
        accessToken: response.data?.accessToken,
        message: response.message,
    };
}
