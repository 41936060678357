import React, { useState } from "react";
import { Avatar } from "@mui/material";
import { UserInterface } from "@/shared/utils/interfaces";
import { getRandomAvatarColor } from "@/shared/components/aside-menu/AvatarUtils";

interface AvatarContentProps {
    user: UserInterface;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    open: boolean;
}

export const AvatarContent = ({ user, onClick, open }: AvatarContentProps) => {
    const [avatarName] = useState<string>(
        `${user.fullName.split(" ")[0][0]}${user.fullName.split(" ")[1][0]}`
    );
    const [randomColor] = useState<{ background: string; text: string }>(
        getRandomAvatarColor()
    );

    return (
        <Avatar
            alt={user.fullName}
            src={user.profilePictureUrl || undefined}
            onClick={onClick}
            sx={{
                marginLeft: open ? "6%" : "auto",
                marginRight: open ? "0" : "auto",
                marginBottom: "7.5px",
                color: user.profilePictureUrl
                    ? undefined
                    : `${randomColor.text}`,
                bgcolor: user.profilePictureUrl
                    ? undefined
                    : `${randomColor.background}`,
                cursor: open ? "default" : "pointer",
                pointerEvents: open ? "none" : "auto",
            }}
        >
            {!user.profilePictureUrl && avatarName}
        </Avatar>
    );
};
