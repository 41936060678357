import { useState, useEffect, useRef } from "react";
import { useAxios } from "@/shared/utils/classes";
import { PlatformTheme } from "@/shared/utils/enums";
import {
    CriteriaObjectInterface,
    UserInterface,
} from "@/shared/utils/interfaces";
import {
    CityInterface,
    RegionInterface,
    CompanyInterface,
} from "@/admin-portal/utils/interfaces";
import { ColumnFilter } from "@/shared/utils/interfaces";

//components
import { Box } from "@mui/material";
import PageHeader from "@/shared/components/PageHeader";
import UserList from "@/admin-portal/components/extras/user-management/UserList";
import UserListHeader from "@/admin-portal/components/extras/user-management/UserListHeader";
import { useSearchParams, useNavigate } from "react-router-dom";

const INITIAL_COLUMN_FILTERS: ColumnFilter[] = [
    { field: "firstName", isActive: true, operator: "prefix" },
    { field: "lastName", isActive: false, operator: "none" },
    { field: "identityDocumentNumber", isActive: false, operator: "none" },
    { field: "company", isActive: false, operator: "none" },
    { field: "email", isActive: false, operator: "none" },
    { field: "phoneNumber", isActive: false, operator: "none" },
];

interface UserManagementPageProps {
    user: UserInterface;
    platformTheme: PlatformTheme;
}

export default function UserManagementPage({
    user,
    platformTheme,
}: UserManagementPageProps) {
    const queryParams = useSearchParams();
    const navigate = useNavigate();

    const [pageSize, setPageSize] = useState<number>();
    const [users, setUsers] = useState<UserInterface[]>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchInput, setSearchInput] = useState<string>();
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [regions, setRegions] = useState<RegionInterface[]>([]);
    const [usersUpdated, setUsersUpdated] = useState<boolean>(false);
    const [companies, setCompanies] = useState<CompanyInterface[]>([]);
    const [selectedUser, setSelectedUser] = useState<
        UserInterface | undefined
    >();
    const [cities, setCities] = useState<Map<string, CityInterface[]>>(
        new Map<string, CityInterface[]>()
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(
        INITIAL_COLUMN_FILTERS
    );

    const effectExecuted = useRef(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        let pageNumberAux = queryParams[0].get("page")!;

        if (
            !pageNumber ||
            isNaN(Number(pageNumberAux)) ||
            Number(pageNumberAux) <= 0
        ) {
            navigate("/admin/user-management?page=1");
            pageNumberAux = "1";
        }

        if (effectExecuted.current) {
            return;
        }

        effectExecuted.current = true;

        setPageNumber(Number(pageNumberAux));
        fetchLocations();
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (pageSize && pageSize > 0) {
            fetchUsersByCriteria();
        }
    }, [pageSize, searchInput]);

    useEffect(() => {
        if (pageSize && pageNumber && pageNumber > 0) {
            navigate(`/admin/user-management?page=${pageNumber}`);
            fetchUsersByCriteria();
        }
    }, [pageNumber]);

    useEffect(() => {
        if (pageSize) {
            setPageNumber(1);
            setSearchInput("");
            fetchUsersByCriteria();
        }
    }, [columnFilters]);

    const fetchLocations = async (): Promise<void> => {
        const regions = await fetchRegions();
        const cities = await fetchCities(regions);

        setRegions(regions);
        setCities(cities);
    };

    const fetchRegions = async (): Promise<RegionInterface[]> => {
        const useAxiosInstance = new useAxios();

        const activeRegions: RegionInterface[] = await useAxiosInstance
            .get("regions")
            .then((data) => data.data)
            .catch((error) => {
                console.error(error);
                return [];
            });

        return activeRegions;
    };

    const fetchUsersByCriteria = async (pageNumberAux = pageNumber) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;

        const useAxiosInstance = new useAxios();
        const usersUrlByCriteria = getUsersUrlByCriteria(pageNumberAux);

        try {
            const users = await useAxiosInstance
                .get(usersUrlByCriteria, {}, newAbortController.signal)
                .then((data) => data.data);

            if (users === undefined) {
                return;
            }

            setUsers(users?.users || []);
            setHasNextPage(users?.hasNextPage || false);

            if (pageNumber > 1 && users && users.users && !users.users.length) {
                resetPageNumberAndFetchUsers();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchCompanies = async (): Promise<void> => {
        const useAxiosInstance = new useAxios();

        const companies: CompanyInterface[] = await useAxiosInstance
            .get("companies")
            .then((data) => data.data.companies)
            .catch((error) => {
                console.error(error);
                return [];
            });

        setCompanies(companies);
    };

    const getUsersUrlByCriteria = (pageNumberAux: number): string => {
        const criteria: Record<string, any> = {};

        if (searchInput && searchInput.length > 0) {
            const orConditions: Record<string, any>[] = [];

            columnFilters.forEach((columnFilter) => {
                if (columnFilter.isActive && columnFilter.operator !== "none") {
                    const condition: Record<string, any> = {
                        [columnFilter.field]: {
                            [columnFilter.operator]: searchInput,
                        },
                    };
                    orConditions.push(condition);
                }
            });

            if (orConditions.length > 0) {
                criteria["or"] = orConditions;
            }
        }

        const criteriaObject: CriteriaObjectInterface = {
            criteria,
            pageNumber: pageNumberAux,
            pageSize: pageSize!,
        };

        const queryParams = new URLSearchParams();

        if (Object.keys(criteria).length > 0) {
            queryParams.append(
                "criteria",
                JSON.stringify(criteriaObject.criteria)
            );
        }

        queryParams.append("pageNumber", criteriaObject.pageNumber.toString());
        queryParams.append("pageSize", criteriaObject.pageSize!.toString());

        return `users?${queryParams.toString()}`;
    };

    const fetchCities = async (
        regions: RegionInterface[]
    ): Promise<Map<string, CityInterface[]>> => {
        const useAxiosInstance = new useAxios();
        const cities: Map<string, CityInterface[]> = new Map<
            string,
            CityInterface[]
        >();

        for (const region of regions) {
            const citiesByRegion: CityInterface[] = await useAxiosInstance
                .get(`cities/${region.id}`)
                .then((data) => data.data)
                .catch((error) => {
                    console.error(error);
                    return [];
                });

            cities.set(region.id, citiesByRegion);
        }

        return cities;
    };

    const resetPageNumberAndFetchUsers = () => {
        navigate("/admin/user-management?page=1");
        setPageNumber(1);
        fetchUsersByCriteria(1);
    };

    return (
        <Box>
            <PageHeader
                title="Manejo de Usuarios"
                description={`Todos los clientes y empleados de Logiflex Fulfillment.`}
            />

            <UserListHeader
                cities={cities}
                regions={regions}
                companies={companies}
                searchInput={searchInput!}
                selectedUser={selectedUser}
                platformTheme={platformTheme}
                columnFilters={columnFilters}
                setSearchInput={setSearchInput}
                setUsersUpdated={setUsersUpdated}
                setColumnFilters={setColumnFilters}
                resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
            />

            <UserList
                users={users!}
                pageNumber={pageNumber}
                hasNextPage={hasNextPage}
                usersUpdated={usersUpdated}
                platformTheme={platformTheme}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setUsersUpdated={setUsersUpdated}
                setSelectedUser={setSelectedUser}
            />
        </Box>
    );
}
