import { useState, useEffect } from "react";
import {
    Box,
    Drawer,
    IconButton,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    DrawerFooter,
    DrawerHeader,
} from "@/shared/components/aside-menu/AsideMenuStyles";
import UpdateUserForm from "./UpdateUserForm";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";
import { UserInterface } from "@/shared/utils/interfaces";

interface UpdateUserDrawerProps {
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    selectedUser: UserInterface;
    isUpdateUserDrawerOpen: boolean;
    cities: Map<string, CityInterface[]>;
    resetPageNumberAndFetchUsers: () => void;
    setUsersUpdated: (usersUpdated: boolean) => void;
    setIsUpdateUserDrawerOpen: (isOpen: boolean) => void;
}

const UpdateUserDrawer = ({
    cities,
    regions,
    selectedUser,
    platformTheme,
    setUsersUpdated,
    isUpdateUserDrawerOpen,
    setIsUpdateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: UpdateUserDrawerProps) => {
    const [updatingUser, setUpdatingUser] = useState<boolean>(false);

    useEffect(() => {
        const shouldCloseDrawer = (event: MouseEvent) => {
            const targetClass = (event.target as HTMLElement).classList;
            const parentId = (event.target as HTMLElement).parentElement?.id;

            if (
                targetClass.contains("MuiBackdrop-root") &&
                parentId === "admin-Update-user-drawer"
            ) {
                setIsUpdateUserDrawerOpen(false);
            }
        };

        window.addEventListener("mousedown", shouldCloseDrawer);
    }, []);

    const closeUpdateUserDrawer = () => {
        setIsUpdateUserDrawerOpen(false);
    };

    return (
        <Drawer
            id="admin-Update-user-drawer"
            open={isUpdateUserDrawerOpen}
            anchor="right"
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#fff" : "#121212"}`,
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <DrawerHeader
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        onClick={closeUpdateUserDrawer}
                        sx={{
                            transform: "translateX(-145px)",
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.08)" : "#333333"}`,
                            },
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "#FFFFFF"}`,
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="h5"
                        color="#656565"
                        sx={{
                            textAlign: "center",
                            transform: "translateX(-20px)",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#656565" : "#FFFFFF"}`,
                        }}
                    >
                        Actualizar Usuario
                    </Typography>
                </DrawerHeader>

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        padding: "0px 16px 16px 16px",
                        flexGrow: 1,
                        overflowY: "auto",
                    }}
                >
                    <Divider
                        sx={{
                            marginBottom: "20px",
                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                        }}
                    />

                    <UpdateUserForm
                        cities={cities}
                        regions={regions}
                        selectedUser={selectedUser}
                        updatingUser={updatingUser}
                        platformTheme={platformTheme}
                        setUsersUpdated={setUsersUpdated}
                        setUpdatingUser={setUpdatingUser}
                        setIsUpdateUserDrawerOpen={setIsUpdateUserDrawerOpen}
                        resetPageNumberAndFetchUsers={
                            resetPageNumberAndFetchUsers
                        }
                    />
                </Box>

                <DrawerFooter
                    sx={{
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: "100%",
                            margin: "16px 0px",
                            backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(21, 102, 193)" : "rgb(76, 0, 153)"}`,
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210)" : "#720ED8"}`,
                            },
                        }}
                        disabled={updatingUser}
                        onClick={() => setUpdatingUser(true)}
                    >
                        Actualizar Usuario
                    </Button>
                </DrawerFooter>
            </Box>
        </Drawer>
    );
};

export default UpdateUserDrawer;
