import { useAxios } from "@/shared/utils/classes";
import { RecoverPasswordRequestInterface } from "@/central-app/actions/recover-password/RecoverPasswordRequest.interface";
import { RecoverPasswordResponseInterface } from "@/central-app/actions/recover-password/RecoverPasswordResponse.interface";

export async function RecoverPassword(
    recoverPasswordData: RecoverPasswordRequestInterface
): Promise<RecoverPasswordResponseInterface> {
    const { email } = recoverPasswordData;
    const axios = new useAxios();

    const response = await axios.post("email/recover-password", {
        recipientEmail: email,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
