import { getPlatformTheme, setPlatformTheme } from "@/shared/utils/classes";

export function logout() {
    const platformTheme = getPlatformTheme();

    localStorage.clear();

    setPlatformTheme(platformTheme);
    localStorage.setItem("isFirstTime", "false");

    window.location.href = "/";
}
