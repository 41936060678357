import { useState, useEffect } from "react";
import {
    Box,
    Drawer,
    IconButton,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    DrawerFooter,
    DrawerHeader,
} from "@/shared/components/aside-menu/AsideMenuStyles";
import {
    CityInterface,
    OrderInterface,
    RegionInterface,
} from "@/shared/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";
import CreateOrderForm from "@/customer-portal/components/extras/sales-orders/CreateOrderForm";

interface CreateOrderDrawerProps {
    currentTime: Date;
    currentCompany: string;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    isCurrentTimeLoading: boolean;
    isCreateOrderDrawerOpen: boolean;
    cities: Map<string, CityInterface[]>;
    setIsCreateOrderDrawerOpen: (isOpen: boolean) => void;
}

const CreateOrderDrawer = ({
    cities,
    regions,
    currentTime,
    platformTheme,
    currentCompany,
    isCurrentTimeLoading,
    isCreateOrderDrawerOpen,
    setIsCreateOrderDrawerOpen,
}: CreateOrderDrawerProps) => {
    const [creatingOrder, setCreatingOrder] = useState<boolean>(false);

    const closeCreateOrderDrawer = () => {
        setIsCreateOrderDrawerOpen(false);
    };

    return (
        <Drawer
            id="admin-create-user-drawer"
            open={isCreateOrderDrawerOpen}
            anchor="right"
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "#fff" : "#121212"}`,
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <DrawerHeader
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        onClick={closeCreateOrderDrawer}
                        sx={{
                            transform: "translateX(-160px)",
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.08)" : "#333333"}`,
                            },
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "#FFFFFF"}`,
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="h5"
                        color="#656565"
                        sx={{
                            textAlign: "center",
                            transform: "translateX(-20px)",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "#656565" : "#FFFFFF"}`,
                        }}
                    >
                        Crear Orden de Recogida
                    </Typography>
                </DrawerHeader>

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        padding: "0px 16px 16px 16px",
                        flexGrow: 1,
                        overflowY: "auto",
                    }}
                >
                    <Divider
                        sx={{
                            marginBottom: "20px",
                            borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                        }}
                    />

                    <CreateOrderForm
                        cities={cities}
                        regions={regions}
                        currentTime={currentTime}
                        creatingOrder={creatingOrder}
                        platformTheme={platformTheme}
                        currentCompany={currentCompany}
                        setCreatingOrder={setCreatingOrder}
                        isCurrentTimeLoading={isCurrentTimeLoading}
                        setIsCreateOrderDrawerOpen={setIsCreateOrderDrawerOpen}
                    />
                </Box>

                <DrawerFooter
                    sx={{
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.5)"}`,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: "100%",
                            margin: "16px 0px",
                            backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(21, 102, 193)" : "rgb(76, 0, 153)"}`,
                            "&:hover": {
                                backgroundColor: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210)" : "#720ED8"}`,
                            },
                        }}
                        disabled={creatingOrder}
                        onClick={() => setCreatingOrder(true)}
                    >
                        Crear Orden
                    </Button>
                </DrawerFooter>
            </Box>
        </Drawer>
    );
};

export default CreateOrderDrawer;
