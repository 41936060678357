import { useRef } from "react";
import { useAxios } from "@/shared/utils/classes";
import {
    CriteriaObjectInterface,
    ProductInterface,
} from "@/shared/utils/interfaces";
import { GetProductsByCompanyForSelectRequest } from "@/customer-portal/actions/get-products-by-company-for-select/GetProductsByCompanyForSelectRequest.interface";

export async function GetProductsByCompanyForSelects(
    params: GetProductsByCompanyForSelectRequest
): Promise<ProductInterface[]> {
    const { companyName, searchText } = params;

    const useAxiosInstance = new useAxios();
    const productsUrlByCriteria = getProductsUrlByCriteria(
        companyName,
        searchText
    );

    const fetchedProducts = await useAxiosInstance
        .get(productsUrlByCriteria, {}, params.abortController.signal)
        .then((data) => data.data);

    const products: ProductInterface[] = fetchedProducts?.products;
    return products;
}

const getProductsUrlByCriteria = (
    companyName: string,
    searchInput: string
): string => {
    const criteria: Record<string, any> = {
        and: [
            {
                or: [
                    {
                        name: {
                            contains: searchInput,
                        },
                    },
                    {
                        barcode: {
                            contains: searchInput,
                        },
                    },
                ],
            },
            {
                company: {
                    equal: companyName,
                },
            },
        ],
    };

    const criteriaObject: CriteriaObjectInterface = {
        criteria,
        pageNumber: 1,
        pageSize: 5,
    };

    const queryParams = new URLSearchParams();

    if (Object.keys(criteria).length > 0) {
        queryParams.append("criteria", JSON.stringify(criteriaObject.criteria));
    }

    queryParams.append("pageNumber", criteriaObject.pageNumber.toString());
    queryParams.append("pageSize", criteriaObject.pageSize!.toString());

    return `products?${queryParams.toString()}`;
};
