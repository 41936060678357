import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PlatformTheme } from "@/shared/utils/enums";
import { useNavigate } from "react-router-dom";
import { CompanyInterface } from "@/shared/utils/interfaces";
import { GetAllCompanies } from "@/customer-portal/actions/get-all-companies-admin/GetAllCompanies.action";
import {
    Divider,
    FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from "@mui/material";

interface PageHeaderProps {
    title: string;
    description: string;
    platformTheme: PlatformTheme;
    setSelectedCompany: (company: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PageHeaderAdminCompany({
    title,
    description,
    platformTheme,
    setSelectedCompany,
}: PageHeaderProps) {
    const [companies, setCompanies] = useState<CompanyInterface[]>([]);
    const [selectedCompany, setSelectedCompanyState] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanies = async () => {
            const response = await GetAllCompanies();

            if (response.httpStatusCode === 407) {
                navigate("/");
            }

            if (response.httpStatusCode === 200 && response.companies) {
                setCompanies(response.companies);
                const defaultCompany = response.companies[0]?.name || "";
                setSelectedCompany(defaultCompany);
                setSelectedCompanyState(defaultCompany);
            }
        };

        fetchCompanies();
    }, [navigate, setSelectedCompany]);

    const handleCompanyChange = (companyName: string) => {
        setSelectedCompany(companyName);
        setSelectedCompanyState(companyName);
    };

    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    fontSize: "26px",
                    marginBottom: "10px",
                    marginTop: "10px",
                }}
            >
                {title}
            </Typography>

            <Typography>{description}</Typography>

            <FormGroup
                sx={{
                    marginTop: "20px",
                }}
            >
                <InputLabel
                    sx={{
                        color: `${platformTheme === PlatformTheme.LIGHT ? "#000000" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Seleccionar Empresa
                </InputLabel>

                <Select
                    value={selectedCompany}
                    displayEmpty
                    onChange={(e) => handleCompanyChange(e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected: string) => (
                        <Typography
                            sx={{
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                            }}
                        >
                            {selected || "Seleccionar Empresa"}
                        </Typography>
                    )}
                    MenuProps={MenuProps}
                    size="small"
                    inputProps={{
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        width: "350px",
                        marginTop: "5px",
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                            },
                        "& .MuiSvgIcon-root": {
                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                        },
                    }}
                >
                    {companies.map((company) => (
                        <MenuItem key={company.id} value={company.name}>
                            {company.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormGroup>

            <Divider
                sx={{
                    marginTop: "15px",
                    marginBottom: "20px",
                }}
            />
        </Box>
    );
}
