import React, { useState, useEffect, useRef, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { UserInterface } from "@/shared/utils/interfaces";
import { getPlatformTheme, useAxios } from "@/shared/utils/classes";
import { ToastContainer } from "react-toastify";
import useCurrentTime from "@/hooks/useCurrentTime";

// Components
import PrivateRoute from "./shared/components/PrivateRoute";
import CentralAppHome from "@/central-app/pages/Home";
import CentralAppLogin from "@/central-app/pages/Login";
import CentralAppSetPassword from "@/central-app/pages/SetPassword";
import CentralAppRecoverPassword from "@/central-app/pages/RecoverPassword";
import CentralAppAccountSettings from "@/central-app/pages/AccountSettings";
import WarehouseManagementInventory from "@/warehouse-management/pages/Inventory";
import WarehouseManagementStockEntry from "@/warehouse-management/pages/StockEntry";
import WarehouseManagementStockDispatch from "@/warehouse-management/pages/StockDispatch";
import WarehouseManagementMovementHistory from "@/warehouse-management/pages/MovementHistory";
import CustomerServiceClientOrders from "@/customer-service/pages/ClientOrders";
import CustomerServiceClientOrdersHistory from "@/customer-service/pages/ClientOrdersHistory";
import CustomerServiceClientInformation from "@/customer-service/pages/ClientInformation";
import CarrierOperationsRoute from "@/carrier-operations/pages/Route";
import CarrierOperationsPickupOrders from "@/carrier-operations/pages/PickupOrders";
import CarrierOperationsDeliveryOrders from "@/carrier-operations/pages/DeliveryOrders";
import CarrierOperationsActivityHistory from "@/carrier-operations/pages/ActivityHistory";
import CustomerPortalInventory from "@/customer-portal/pages/Inventory";
import CustomerPortalSalesOrders from "@/customer-portal/pages/SalesOrders";
import CustomerPortalPickupOrders from "@/customer-portal/pages/PickupOrders";
import CustomerPortalUserManagement from "@/customer-portal/pages/UserManagement";
import CustomerPortalBilling from "@/customer-portal/pages/Billing";
import AdminUserManagement from "@/admin-portal/pages/UserManagement";
import AdminBusinessReports from "@/admin-portal/pages/BusinessReports";
import AdminUserActivities from "@/admin-portal/pages/UserActivities";

// Classes
import { logout } from "@/shared/utils/classes";
import { useTabCount } from "./shared/hooks/useTabCount";
import { PlatformTheme } from "./shared/utils/enums";

//styles
import "@/shared/styles.css";
import "@/central-app/styles.css";
import "@/admin-portal/styles.css";
import "@/carrier-operations/styles.css";
import "@/customer-portal/styles.css";
import "@/customer-service/styles.css";
import "@/warehouse-management/styles.css";

const App: React.FC = () => {
    const [user, setUser] = useState<UserInterface>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checkAuth, setCheckAuth] = useState<boolean>(false);
    const [platformTheme, setPlatformTheme] =
        useState<PlatformTheme>(getPlatformTheme());

    const effectExecuted = useRef(false);
    const { currentTime, isCurrentTimeLoading } =
        useCurrentTime("America/Santiago");

    useEffect(() => {
        if (effectExecuted.current) {
            return;
        }

        effectExecuted.current = true;

        validateAuth();
    }, []);

    useEffect(() => {
        if (checkAuth) {
            validateAuth();
            setCheckAuth(false);
        }
    }, [checkAuth]);

    const validateAuth = async () => {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            setIsLoading(false);
            return;
        }

        await fetchUser();
    };

    const fetchUser = async () => {
        const axios = new useAxios();
        const userResponse = await axios.get("user");

        setUser(userResponse.data);

        setIsLoading(false);
    };

    const logoutUser = useCallback(() => {
        logout();
    }, []);

    const keepConnectionAlive = localStorage.getItem("keepConnection");
    const shouldUseTabCount = keepConnectionAlive === "false";
    useTabCount(shouldUseTabCount, logoutUser);

    return (
        <>
            {!isLoading && (
                <Routes>
                    <Route
                        path="/"
                        element={
                            user ? (
                                <PrivateRoute
                                    allowedRoles={"*"}
                                    userRole={user?.role || ""}
                                    element={
                                        <CentralAppHome
                                            user={user}
                                            platformTheme={platformTheme}
                                            setPlatformTheme={setPlatformTheme}
                                        />
                                    }
                                />
                            ) : (
                                <CentralAppLogin
                                    setCheckAuth={setCheckAuth}
                                    platformTheme={platformTheme}
                                    setPlatformTheme={setPlatformTheme}
                                />
                            )
                        }
                    />

                    <Route
                        path="/set-password/:accessToken"
                        element={
                            <CentralAppSetPassword
                                platformTheme={platformTheme}
                                setPlatformTheme={setPlatformTheme}
                            />
                        }
                    />

                    <Route
                        path="/recover-password"
                        element={
                            <CentralAppRecoverPassword
                                platformTheme={platformTheme}
                                setPlatformTheme={setPlatformTheme}
                            />
                        }
                    />

                    <Route
                        path="/account-settings"
                        element={
                            <PrivateRoute
                                allowedRoles="*"
                                userRole={user?.role || ""}
                                element={
                                    <CentralAppAccountSettings
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/warehouse-operator/manage-inventory"
                        element={
                            <PrivateRoute
                                allowedRoles={["warehouse operator", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <WarehouseManagementInventory
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/warehouse-operator/stock-entry"
                        element={
                            <PrivateRoute
                                allowedRoles={["warehouse operator", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <WarehouseManagementStockEntry
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/warehouse-operator/stock-dispatch"
                        element={
                            <PrivateRoute
                                allowedRoles={["warehouse operator", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <WarehouseManagementStockDispatch
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/warehouse-operator/warehouse-orders-history"
                        element={
                            <PrivateRoute
                                allowedRoles={["warehouse operator", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <WarehouseManagementMovementHistory
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer-service/manage-orders"
                        element={
                            <PrivateRoute
                                allowedRoles={["customer service", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerServiceClientOrders
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer-service/orders-history"
                        element={
                            <PrivateRoute
                                allowedRoles={["customer service", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerServiceClientOrdersHistory
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer-service/customers"
                        element={
                            <PrivateRoute
                                allowedRoles={["customer service", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerServiceClientInformation
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/carrier/route"
                        element={
                            <PrivateRoute
                                allowedRoles={["carrier", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CarrierOperationsRoute
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/carrier/pickups"
                        element={
                            <PrivateRoute
                                allowedRoles={["carrier", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CarrierOperationsPickupOrders
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/carrier/deliveries"
                        element={
                            <PrivateRoute
                                allowedRoles={["carrier", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CarrierOperationsDeliveryOrders
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/carrier/activity-history"
                        element={
                            <PrivateRoute
                                allowedRoles={["carrier", "admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <CarrierOperationsActivityHistory
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer/inventory"
                        element={
                            <PrivateRoute
                                allowedRoles={[
                                    "customer",
                                    "admin customer",
                                    "admin",
                                ]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerPortalInventory
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer/sales-order"
                        element={
                            <PrivateRoute
                                allowedRoles={[
                                    "customer",
                                    "admin customer",
                                    "admin",
                                ]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerPortalSalesOrders
                                        user={user!}
                                        currentTime={currentTime}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                        isCurrentTimeLoading={
                                            isCurrentTimeLoading
                                        }
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer/request-pickup"
                        element={
                            <PrivateRoute
                                allowedRoles={[
                                    "customer",
                                    "admin customer",
                                    "admin",
                                ]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerPortalPickupOrders
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer/user-management"
                        element={
                            <PrivateRoute
                                allowedRoles={[
                                    "customer",
                                    "admin customer",
                                    "admin",
                                ]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerPortalUserManagement
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/customer/billing"
                        element={
                            <PrivateRoute
                                allowedRoles={[
                                    "customer",
                                    "admin customer",
                                    "admin",
                                ]}
                                userRole={user?.role || ""}
                                element={
                                    <CustomerPortalBilling
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/admin/user-management"
                        element={
                            <PrivateRoute
                                allowedRoles={["admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <AdminUserManagement
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/admin/business-reports"
                        element={
                            <PrivateRoute
                                allowedRoles={["admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <AdminBusinessReports
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />

                    <Route
                        path="/admin/user-activities"
                        element={
                            <PrivateRoute
                                allowedRoles={["admin"]}
                                userRole={user?.role || ""}
                                element={
                                    <AdminUserActivities
                                        user={user!}
                                        platformTheme={platformTheme}
                                        setPlatformTheme={setPlatformTheme}
                                    />
                                }
                            />
                        }
                    />
                </Routes>
            )}

            <ToastContainer />
        </>
    );
};

export default App;
