import * as React from "react";
import { HomeSection } from "@/shared/components/aside-menu/HomeSection";
import { AdminSection } from "@/shared/components/aside-menu/AdminSection";
import { CustomerSection } from "@/shared/components/aside-menu/CustomerSection";
import { CarrierSection } from "@/shared/components/aside-menu/CarrierSection";
import { WarehouseOperatorSection } from "@/shared/components/aside-menu/WarehouseOperatorSection";
import { CustomerServiceManagerSection } from "@/shared/components/aside-menu/CustomerServiceManagerSection";
import { useNavigate, useLocation } from "react-router-dom";

interface AsideMenuItemsProps {
    userRole: string;
    open: boolean;
}

export function AsideMenuItems({ userRole, open }: AsideMenuItemsProps) {
    const currentPathLocation = useLocation().pathname;
    const navigate = useNavigate();

    const changeLocation = (path: string) => {
        navigate(path);
    };

    return (
        <>
            <HomeSection
                open={open}
                currentPathLocation={currentPathLocation}
                changeLocation={changeLocation}
            />

            {userRole.toLowerCase() === "warehouse operator" ||
            userRole.toLowerCase() === "admin" ? (
                <WarehouseOperatorSection
                    open={open}
                    currentPathLocation={currentPathLocation}
                    changeLocation={changeLocation}
                />
            ) : null}

            {userRole.toLowerCase() === "customer service" ||
            userRole.toLowerCase() === "admin" ? (
                <CustomerServiceManagerSection
                    open={open}
                    currentPathLocation={currentPathLocation}
                    changeLocation={changeLocation}
                />
            ) : null}

            {userRole.toLowerCase() === "carrier" ||
            userRole.toLowerCase() === "admin" ? (
                <CarrierSection
                    open={open}
                    currentPathLocation={currentPathLocation}
                    changeLocation={changeLocation}
                />
            ) : null}

            {userRole.toLowerCase() === "customer" ||
            userRole.toLowerCase() === "admin customer" ||
            userRole.toLowerCase() === "admin" ? (
                <CustomerSection
                    open={open}
                    userRole={userRole}
                    currentPathLocation={currentPathLocation}
                    changeLocation={changeLocation}
                />
            ) : null}

            {userRole.toLowerCase() === "admin" ? (
                <AdminSection
                    open={open}
                    currentPathLocation={currentPathLocation}
                    changeLocation={changeLocation}
                />
            ) : null}
        </>
    );
}
