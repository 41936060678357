import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReorderIcon, FormatListBulletedIcon, GroupIcon } from "./Icons";

interface CustomerServiceManagerSectionProps {
    open: boolean;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function CustomerServiceManagerSection({
    open,
    currentPathLocation,
    changeLocation,
}: CustomerServiceManagerSectionProps) {
    return (
        <>
            <List>
                <ListItem
                    key={"Órdenes de Clientes"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Gestionar Órdenes de Clientes"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/customer-service/manage-orders"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/customer-service/manage-orders"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <ReorderIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Órdenes de Clientes"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Historial de Órdenes"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Historial de Órdenes de Clientes"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/customer-service/orders-history"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/customer-service/orders-history"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Historial de Órdenes"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Clientes"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Información de Clientes"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/customer-service/customers"
                            }
                            onClick={() =>
                                changeLocation("/customer-service/customers")
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Clientes"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>

            <Divider />
        </>
    );
}
