import { useAxios } from "@/shared/utils/classes";
import { UpdatePasswordRequestInterface } from "@/central-app/actions/update-password/UpdatePasswordRequest.interface";
import { UpdatePasswordResponseInterface } from "@/central-app/actions/update-password/UpdatePasswordResponse.interface";

export async function UpdatePassword(
    updatePasswordData: UpdatePasswordRequestInterface
): Promise<UpdatePasswordResponseInterface> {
    const { accessToken, password } = updatePasswordData;
    const axios = new useAxios();

    const response = await axios.put("user/update-password", {
        accessToken,
        password,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
