import { useEffect, useState } from "react";
import {} from "@/admin-portal/utils/interfaces";
import {
    Box,
    TextField,
    InputLabel,
    InputAdornment,
    Select,
    MenuItem,
    OutlinedInput,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
} from "@mui/material";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import {
    formatPhoneNumber,
    formatPassportNumber,
    formatIdentityDocumentNumber,
    validateFirstName,
    validateLastName,
    validateEmail,
    validateIdentityNumber,
    validatePassport,
    validatePhoneNumber,
    validateStreet,
    formatStreetNumber,
    validateStreetNumber,
    validateApartmentNumber,
    formatApartmentNumber,
    validateRegion,
    validateCity,
    validateCompanyId,
} from "@/admin-portal/utils/classes";
import { CreateCompanyUser } from "@/admin-portal/actions/create-company-user/CreateCompanyUser.action";
import { CreateCompanyUserRequestInterface } from "@/admin-portal/actions/create-company-user/CreateCompanyUserRequest.interface";
import { CreateCompanyUserResponseInterface } from "@/admin-portal/actions/create-company-user/CreateCompanyUserResponse.interface";

//Icons
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import HomeIcon from "@mui/icons-material/Home";
import TagIcon from "@mui/icons-material/Tag";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { useToast } from "@/shared/utils/classes";
import { PlatformTheme } from "@/shared/utils/enums";

interface CustomerFormProps {
    creatingUser: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    companies: CompanyInterface[];
    cities: Map<string, CityInterface[]>;
    resetPageNumberAndFetchUsers: () => void;
    setCreatingUser: (creatingUser: boolean) => void;
    setIsCreateUserDrawerOpen: (isOpen: boolean) => void;
}

const CustomerForm = ({
    regions,
    cities,
    companies,
    creatingUser,
    platformTheme,
    setCreatingUser,
    setIsCreateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: CustomerFormProps) => {
    //#region States
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameValid, setFirstNameValid] = useState<boolean>(true);

    const [lastName, setLastName] = useState<string>("");
    const [lastNameValid, setLastNameValid] = useState<boolean>(true);

    const [email, setEmail] = useState<string>("");
    const [emailValid, setEmailValid] = useState<boolean>(true);

    const [companyId, setCompanyId] = useState<string>("");
    const [companyIdValid, setCompanyIdValid] = useState<boolean>(true);

    const [identityDocumentNumber, setIdentityDocumentNumber] =
        useState<string>("");
    const [identityDocumentNumberValid, setIdentityDocumentNumberValid] =
        useState<boolean>(true);

    const [passport, setPassport] = useState<string>("");
    const [passportValid, setPassportValid] = useState<boolean>(true);

    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);

    const [userRegionId, setUserRegionId] = useState<string>("");
    const [userRegionIdValid, setUserRegionIdValid] = useState<boolean>(true);

    const [userCityId, setUserCityId] = useState<string>("");
    const [userCityIdValid, setUserCityIdValid] = useState<boolean>(true);

    const [street, setStreet] = useState<string>("");
    const [streetValid, setStreetValid] = useState<boolean>(true);

    const [streetNumber, setStreetNumber] = useState<string>("");
    const [streetNumberValid, setStreetNumberValid] = useState<boolean>(true);

    const [apartment, setApartment] = useState<string>("");
    const [apartmentValid, setApartmentValid] = useState<boolean>(true);

    const [selectedDocumentType, setSelectedDocumentType] = useState<
        "nationalIdentityNumber" | "passport"
    >("nationalIdentityNumber");

    const [alreadyValidated, setAlreadyValidated] = useState<boolean>(false);
    //#endregion States

    useEffect(() => {
        if (creatingUser) {
            setAlreadyValidated(true);

            const isFormValid = validateCustomerForm();

            if (!isFormValid) {
                useToast.toastRequest({
                    message:
                        "Por favor, revisa los campos del formulario y asegúrate de que toda la información sea correcta antes de continuar.",
                    type: "warning",
                });

                setCreatingUser(false);
                return;
            }

            createCompanyUser({
                firstName,
                lastName,
                email,
                companyId,
                identityDocumentNumber:
                    selectedDocumentType === "nationalIdentityNumber"
                        ? identityDocumentNumber
                        : passport,
                phoneNumber,
                cityId: userCityId,
                street,
                streetNumber,
                apartmentNumber: apartment,
            });
        }
    }, [creatingUser]);

    const validateCustomerForm = (): boolean => {
        const validations = [
            validateFirstName(firstName, setFirstNameValid),
            validateLastName(lastName, setLastNameValid),
            validateEmail(email, setEmailValid),
            validateCompanyId(companyId, setCompanyIdValid),
            selectedDocumentType === "nationalIdentityNumber"
                ? validateIdentityNumber(
                      identityDocumentNumber,
                      setIdentityDocumentNumberValid
                  )
                : validatePassport(passport, setPassportValid),
            validatePhoneNumber(phoneNumber, setPhoneNumberValid),
            validateRegion(userRegionId, setUserRegionIdValid),
            validateCity(userCityId, setUserCityIdValid),
            validateStreet(street, setStreetValid),
            validateStreetNumber(streetNumber, setStreetNumberValid),
            validateApartmentNumber(apartment, setApartmentValid),
        ];

        return validations.every((isValid) => isValid);
    };

    const createCompanyUser = async (
        companyUserData: CreateCompanyUserRequestInterface
    ) => {
        const { httpStatusCode }: CreateCompanyUserResponseInterface =
            await useToast.toastPromiseRequest({
                promise: CreateCompanyUser(companyUserData),
                pendingMessage: "Creación de usuario de empresa en progreso",
                successMessage: "Creación de usuario de empresa exitosa",
            });

        handleCompanyUserCreationResult(httpStatusCode);
    };

    const handleCompanyUserCreationResult = (httpStatusCode: number) => {
        const wasEmployeeCreationSuccessful =
            checkIfCompanyUserCreationWasSuccessful(httpStatusCode);

        if (wasEmployeeCreationSuccessful) {
            setIsCreateUserDrawerOpen(false);
            resetPageNumberAndFetchUsers();
        }

        setCreatingUser(false);
    };

    const checkIfCompanyUserCreationWasSuccessful = (
        httpStatusCode: number
    ): boolean => {
        if (httpStatusCode === 200 || httpStatusCode === 201) {
            return true;
        }

        return false;
    };

    return (
        <Box>
            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-name-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Nombre
                    </InputLabel>

                    <TextField
                        id="admin-create-user-name-input"
                        size="small"
                        placeholder="Ingresa el nombre"
                        autoComplete="nope"
                        value={firstName}
                        error={!firstNameValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateFirstName(
                                    e.target.value,
                                    setFirstNameValid
                                );
                            }

                            setFirstName(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-lastname-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Apellido
                    </InputLabel>

                    <TextField
                        id="admin-create-user-lastname-input"
                        size="small"
                        placeholder="Ingresa el apellido"
                        autoComplete="nope"
                        value={lastName}
                        error={!lastNameValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateLastName(
                                    e.target.value,
                                    setLastNameValid
                                );
                            }

                            setLastName(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-email-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Correo Electrónico
                </InputLabel>
                <TextField
                    id="admin-create-user-email-input"
                    size="small"
                    placeholder="Ingresa el correo electrónico"
                    autoComplete="nope"
                    value={email}
                    error={!emailValid}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateEmail(e.target.value, setEmailValid);
                        }

                        setEmail(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-customer-company-select"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Empresa
                </InputLabel>

                <Select
                    id="admin-create-user-customer-company-select"
                    displayEmpty
                    error={!companyIdValid}
                    onChange={(e) => {
                        const companyId =
                            companies.find(
                                (company) => company.name === e.target.value
                            )?.id || "";

                        if (alreadyValidated) {
                            validateCompanyId(companyId, setUserRegionIdValid);
                        }

                        setCompanyId(companyId);
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected: string) =>
                        !selected ? (
                            <Typography
                                sx={{
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                }}
                            >
                                Selecciona la empresa del usuario
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                }}
                            >
                                {selected}
                            </Typography>
                        )
                    }
                    size="small"
                    inputProps={{
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        width: "100%",
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                            },
                        "& .MuiSvgIcon-root": {
                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                        },
                    }}
                >
                    <MenuItem disabled>
                        <em>Selecciona la empresa del usuario</em>
                    </MenuItem>
                    {companies &&
                        companies.map((company) => (
                            <MenuItem key={company.id} value={company.name}>
                                {company.name}
                            </MenuItem>
                        ))}
                </Select>
            </Box>

            <Box sx={{ display: "flex" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <RadioGroup
                        row
                        value={selectedDocumentType}
                        onChange={() =>
                            setSelectedDocumentType(
                                selectedDocumentType ===
                                    "nationalIdentityNumber"
                                    ? "passport"
                                    : "nationalIdentityNumber"
                            )
                        }
                        sx={{ marginTop: "10px" }}
                    >
                        <FormControlLabel
                            value="nationalIdentityNumber"
                            control={
                                <Radio
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                />
                            }
                            label="Rut"
                            sx={{
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                ".Mui-checked": {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                },
                            }}
                        />
                        <FormControlLabel
                            value="passport"
                            control={
                                <Radio
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                />
                            }
                            label="Pasaporte"
                            sx={{
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                ".Mui-checked": {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                },
                            }}
                        />
                    </RadioGroup>

                    {selectedDocumentType === "nationalIdentityNumber" ? (
                        <Box sx={{ width: "100%" }}>
                            <InputLabel
                                htmlFor="admin-create-user-identity-input"
                                sx={{
                                    marginBottom: "5px",
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                }}
                            >
                                Rut
                            </InputLabel>
                            <TextField
                                id="admin-create-user-identity-input"
                                size="small"
                                placeholder="Ingresa el Rut"
                                autoComplete="nope"
                                fullWidth
                                error={!identityDocumentNumberValid}
                                value={identityDocumentNumber}
                                onChange={(e) => {
                                    const lastChar =
                                        e.target.value[
                                            e.target.value.length - 1
                                        ];
                                    const lastCharRegex = /^[\s\S]*[0-9kK.-]$/;

                                    if (
                                        alreadyValidated &&
                                        lastCharRegex.test(lastChar)
                                    ) {
                                        validateIdentityNumber(
                                            e.target.value,
                                            setIdentityDocumentNumberValid
                                        );
                                    }

                                    formatIdentityDocumentNumber(
                                        e,
                                        setIdentityDocumentNumber
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RecentActorsIcon
                                                sx={{
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                            },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ width: "100%" }}>
                            <InputLabel
                                htmlFor="admin-create-user-passport-input"
                                sx={{
                                    marginBottom: "5px",
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                }}
                            >
                                Pasaporte
                            </InputLabel>
                            <TextField
                                id="admin-create-user-passport-input"
                                size="small"
                                placeholder="Ingresa el Pasaporte"
                                autoComplete="nope"
                                fullWidth
                                value={passport}
                                error={!passportValid}
                                onChange={(e) => {
                                    const lastChar =
                                        e.target.value[
                                            e.target.value.length - 1
                                        ];
                                    const lastCharRegex =
                                        /^[\s\S]*[a-zA-Z0-9]$/;

                                    if (
                                        alreadyValidated &&
                                        lastCharRegex.test(lastChar)
                                    ) {
                                        validatePassport(
                                            e.target.value,
                                            setPassportValid
                                        );
                                    }

                                    formatPassportNumber(e, setPassport);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <RecentActorsIcon
                                                sx={{
                                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                            },
                                        "&.Mui-focused": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    )}
                </Box>

                <Box sx={{ marginLeft: "10px", marginTop: "52px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-phone-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número de Teléfono
                    </InputLabel>
                    <TextField
                        id="admin-create-user-phone-number-input"
                        size="small"
                        placeholder="Ingresa el número"
                        autoComplete="nope"
                        value={phoneNumber}
                        error={!phoneNumberValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validatePhoneNumber(
                                    e.target.value,
                                    setPhoneNumberValid
                                );
                            }

                            formatPhoneNumber(e.target.value, setPhoneNumber);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ContactPhoneIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box
                    sx={{ marginRight: "10px", maxWidth: "50%", width: "50%" }}
                >
                    <InputLabel
                        htmlFor="admin-create-user-user-region-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Región
                    </InputLabel>
                    <Select
                        id="admin-create-user-user-region-select"
                        displayEmpty
                        error={!userRegionIdValid}
                        onChange={(e) => {
                            const regionId =
                                regions.find(
                                    (region) => region.name === e.target.value
                                )?.id || "";

                            if (alreadyValidated) {
                                validateRegion(regionId, setUserRegionIdValid);
                            }

                            setUserRegionId(regionId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la región
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        size="small"
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la región</em>
                        </MenuItem>
                        {regions.map((region) => (
                            <MenuItem key={region.id} value={region.name}>
                                {region.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box sx={{ marginLeft: "10px", maxWidth: "50%", width: "50%" }}>
                    <InputLabel
                        htmlFor="admin-create-user-user-city-select"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Comuna
                    </InputLabel>
                    <Select
                        id="admin-create-user-user-city-select"
                        displayEmpty
                        error={!userCityIdValid}
                        onChange={(e) => {
                            const cityId =
                                cities
                                    .get(userRegionId!)!
                                    .find(
                                        (city) => city.name === e.target.value
                                    )?.id || "";

                            if (alreadyValidated) {
                                validateCity(cityId, setUserCityIdValid);
                            }

                            setUserCityId(cityId);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona la comuna
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        disabled={!userRegionId}
                        size="small"
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && userRegionId && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "#333333"}`,
                            },
                            "&.Mui-disabled .MuiInputBase-input": {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                                "-webkit-text-fill-color": `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona la comuna</em>
                        </MenuItem>
                        {userRegionId &&
                            cities.get(userRegionId!)!.map((city) => (
                                <MenuItem key={city.id} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
            </Box>

            <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                <InputLabel
                    htmlFor="admin-create-user-street-input"
                    sx={{
                        marginBottom: "5px",
                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                    }}
                >
                    Calle
                </InputLabel>
                <TextField
                    id="admin-create-user-street-input"
                    size="small"
                    placeholder="Ingresa la calle"
                    autoComplete="nope"
                    value={street}
                    error={!streetValid}
                    onChange={(e) => {
                        if (alreadyValidated) {
                            validateStreet(e.target.value, setStreetValid);
                        }

                        setStreet(e.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HomeIcon
                                    sx={{
                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: {
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                <Box sx={{ marginRight: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-street-number-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número de Calle
                    </InputLabel>
                    <TextField
                        id="admin-create-user-street-number-input"
                        size="small"
                        placeholder="Número de calle"
                        autoComplete="nope"
                        value={streetNumber}
                        error={!streetNumberValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateStreetNumber(
                                    e.target.value,
                                    setStreetNumberValid
                                );
                            }

                            formatStreetNumber(e.target.value, setStreetNumber);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-apartment-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Número (Apto/Oficina) (Opcional)
                    </InputLabel>
                    <TextField
                        id="admin-create-user-apartment-input"
                        size="small"
                        placeholder="Ingresa el número"
                        autoComplete="nope"
                        value={apartment}
                        error={!apartmentValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateApartmentNumber(
                                    e.target.value,
                                    setApartmentValid
                                );
                            }

                            formatApartmentNumber(e.target.value, setApartment);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TagIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CustomerForm;
