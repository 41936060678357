import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";

//Components
import LoginBodyForm from "@/central-app/components/extras/login/body-form";

interface LoginBodyProps {
    platformTheme: PlatformTheme;
    setCheckAuth: (checkAuth: boolean) => void;
}

export default function LoginBody({
    platformTheme,
    setCheckAuth,
}: LoginBodyProps) {
    return (
        <div className="login-body">
            <LoginBodyForm
                platformTheme={platformTheme}
                setCheckAuth={setCheckAuth}
            />
        </div>
    );
}
