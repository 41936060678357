import { PlatformTheme } from "@/shared/utils/enums";

//components
import LoginHeader from "@/central-app/components/page/login/Header";
import LoginBody from "@/central-app/components/page/login/Body";

interface LoginProps {
    platformTheme: PlatformTheme;
    setCheckAuth: (checkAuth: boolean) => void;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function Login({
    setCheckAuth,
    platformTheme,
    setPlatformTheme,
}: LoginProps) {
    return (
        <div
            className={`central-app ${platformTheme === PlatformTheme.LIGHT ? "login-light" : "login-dark"}`}
        >
            <LoginHeader setPlatformTheme={setPlatformTheme} />
            <LoginBody
                platformTheme={platformTheme}
                setCheckAuth={setCheckAuth}
            />
        </div>
    );
}
