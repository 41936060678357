import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import MainLogo from "@/shared/assets/logiflex_logo_v1.png";
import { useToast } from "@/shared/utils/classes";
import { useNavigate } from "react-router-dom";
import { RecoverPassword } from "@/central-app/actions/recover-password/RecoverPassword.action";
import { RecoverPasswordResponseInterface } from "@/central-app/actions/recover-password/RecoverPasswordResponse.interface";
import {
    InputLabel,
    Button,
    FormGroup,
    TextField,
    IconButton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface RecoverPasswordBodyProps {
    platformTheme: PlatformTheme;
}

export default function RecoverPasswordBody({
    platformTheme,
}: RecoverPasswordBodyProps) {
    const [email, setEmail] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsSendingEmail(true);
        const isFormValid = checkIfFormIsValid();

        if (isFormValid) {
            await sendRecoverPasswordEmail();
        } else {
            useToast.toastRequest({
                message:
                    "El correo electrónico ingresado no es válido. Por favor, verifica y vuelve a intentarlo.",
                type: "warning",
            });
        }

        setIsSendingEmail(false);
    };

    const sendRecoverPasswordEmail = async () => {
        const { httpStatusCode }: RecoverPasswordResponseInterface =
            await useToast.toastPromiseRequest({
                promise: RecoverPassword({ email }),
                pendingMessage:
                    "Estamos enviando tu correo de restablecimiento de contraseña. ¡Un momento por favor!",
                successMessage:
                    "¡Correo enviado! Revisa tu bandeja de entrada y la carpeta de spam si no lo ves.",
            });

        handleSendRecoverPasswordEmailResult(httpStatusCode);
    };

    const handleSendRecoverPasswordEmailResult = (httpStatusCode: number) => {
        const wasSendRecoverPasswordEmailSuccessful =
            checkIfSendRecoverPasswordEmailWasSuccessful(httpStatusCode);

        if (wasSendRecoverPasswordEmailSuccessful) {
            changeLocation("/");
        }
    };

    const checkIfSendRecoverPasswordEmailWasSuccessful = (
        httpStatusCode: number
    ): boolean => {
        if (httpStatusCode === 201) {
            return true;
        }

        return false;
    };

    const verifyEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    const checkIfFormIsValid = (): boolean => {
        const isEmailValid = verifyEmail(email);

        setIsEmailValid(isEmailValid);

        if (!isEmailValid) {
            return false;
        }

        return true;
    };

    const changeLocation = (path: string): void => {
        navigate(path);
    };

    return (
        <>
            <form
                className={`recover-password__recover-password-form ${platformTheme === PlatformTheme.LIGHT ? "recover-password__recover-password-form-light" : "recover-password__recover-password-form-dark"}`}
            >
                <div className="recover-password-form__recover-password-image-container">
                    <IconButton
                        onClick={() => changeLocation("/")}
                        sx={{
                            width: "40px",
                            height: "40px",
                            transform: "translate(-120px, -30px)",
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                width: "24px",
                                height: "24px",
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "#39A7A7" : "#FFFFFF"}`,
                            }}
                        />
                    </IconButton>

                    <img
                        className="recover-password-form__recover-password-image"
                        src={MainLogo}
                        alt="Logiflex Logo"
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>

                <div className="recover-password-form__subtitle-container">
                    <p
                        className={`recover-password-form__title ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                    >
                        Gestión de Contraseña
                    </p>
                    <p
                        className={`recover-password-form__subtitle ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                    >
                        Configura o recupera tu contraseña para acceder
                    </p>
                </div>

                <div className="recover-password-form__container">
                    <FormGroup className="recover-password-form__input-container">
                        <InputLabel
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            htmlFor="recover-password-form__email-input"
                        >
                            Correo Electrónico
                        </InputLabel>

                        <TextField
                            id="recover-password-form__email-input"
                            className={`recover-password-form__input ${platformTheme === PlatformTheme.LIGHT ? "light-theme-text-color" : "dark-theme-text-color"}`}
                            placeholder="Introduce tu correo electrónico registrado"
                            variant="standard"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isSendingEmail}
                            error={!isEmailValid}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit();
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="recover-password-form__recover-password-button-container">
                        <Button
                            className={`recover-password-form__recover-password-button-container ${platformTheme === PlatformTheme.LIGHT ? "recover-password-form__recover-password-button-light-theme" : "recover-password-form__recover-password-button-dark-theme"}`}
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isSendingEmail}
                        >
                            Enviar Instrucciones
                        </Button>
                    </FormGroup>
                </div>
            </form>
        </>
    );
}
