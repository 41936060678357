import { Box } from "@mui/material";
import CreateEmployeeForm from "./CreateEmployeeForm";
import CreateAdminCustomerForm from "./CreateAdminCustomerForm";
import CreateCustomerForm from "./CreateCustomerForm";
import {
    CityInterface,
    CompanyInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import { PlatformTheme } from "@/shared/utils/enums";

interface CreateUserFormProps {
    creatingUser: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    companies: CompanyInterface[];
    cities: Map<string, CityInterface[]>;
    selectedUserType: "Employee" | "Admin Customer" | "Customer";
    resetPageNumberAndFetchUsers: () => void;
    setCreatingUser: (creatingUser: boolean) => void;
    setIsCreateUserDrawerOpen: (isOpen: boolean) => void;
}

const CreateUserForm = ({
    regions,
    cities,
    companies,
    creatingUser,
    platformTheme,
    setCreatingUser,
    selectedUserType,
    setIsCreateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: CreateUserFormProps) => {
    return (
        <Box>
            {selectedUserType === "Employee" && (
                <CreateEmployeeForm
                    regions={regions}
                    cities={cities}
                    creatingUser={creatingUser}
                    platformTheme={platformTheme}
                    setCreatingUser={setCreatingUser}
                    setIsCreateUserDrawerOpen={setIsCreateUserDrawerOpen}
                    resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
                />
            )}
            {selectedUserType === "Admin Customer" && (
                <CreateAdminCustomerForm
                    regions={regions}
                    cities={cities}
                    creatingUser={creatingUser}
                    platformTheme={platformTheme}
                    setCreatingUser={setCreatingUser}
                    setIsCreateUserDrawerOpen={setIsCreateUserDrawerOpen}
                    resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
                />
            )}
            {selectedUserType === "Customer" && (
                <CreateCustomerForm
                    cities={cities}
                    regions={regions}
                    companies={companies}
                    creatingUser={creatingUser}
                    platformTheme={platformTheme}
                    setCreatingUser={setCreatingUser}
                    setIsCreateUserDrawerOpen={setIsCreateUserDrawerOpen}
                    resetPageNumberAndFetchUsers={resetPageNumberAndFetchUsers}
                />
            )}
        </Box>
    );
};

export default CreateUserForm;
