import React from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { UserInterface } from "@/shared/utils/interfaces";

//components
import ComponentWithAsideMenu from "@/shared/components/ComponentWithAsideMenu";
import DeliveryOrdersPage from "@/carrier-operations/components/page/delivery-orders";

interface DeliveryOrdersProps {
    user: UserInterface;
    platformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function DeliveryOrders({
    user,
    platformTheme,
    setPlatformTheme,
}: DeliveryOrdersProps) {
    return (
        <div
            className={`main-page ${platformTheme === PlatformTheme.LIGHT ? "home-light" : "home-dark"}`}
        >
            <ComponentWithAsideMenu
                user={user}
                notificationsCount={3}
                currentPlatformTheme={platformTheme}
                setPlatformTheme={setPlatformTheme}
                component={
                    <DeliveryOrdersPage
                        user={user}
                        platformTheme={platformTheme}
                    />
                }
            />
        </div>
    );
}
