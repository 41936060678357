import * as React from "react";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { HomeIcon } from "./Icons";

interface HomeSectionProps {
    open: boolean;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function HomeSection({
    open,
    currentPathLocation,
    changeLocation,
}: HomeSectionProps) {
    return (
        <>
            <List>
                <ListItem
                    key={"Inicio"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip title="Inicio" placement="right" arrow>
                        <ListItemButton
                            selected={currentPathLocation === "/"}
                            onClick={() => changeLocation("/")}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Inicio"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>

            <Divider />
        </>
    );
}
