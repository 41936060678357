import { PlatformTheme } from "@/shared/utils/enums";

//components
import RecoverPasswordHeader from "@/central-app/components/page/recover-password/Header";
import RecoverPasswordBody from "@/central-app/components/page/recover-password/Body";

interface RecoverPasswordProps {
    platformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function RecoverPassword({
    platformTheme,
    setPlatformTheme,
}: RecoverPasswordProps) {
    return (
        <div
            className={`central-app ${platformTheme === PlatformTheme.LIGHT ? "login-light" : "login-dark"}`}
        >
            <RecoverPasswordHeader setPlatformTheme={setPlatformTheme} />
            <RecoverPasswordBody platformTheme={platformTheme} />
        </div>
    );
}
