import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";

//Components
import SetPasswordBodyForm from "@/central-app/components/extras/set-password/body-form";

interface SetPasswordBodyProps {
    platformTheme: PlatformTheme;
}

export default function SetPasswordBody({
    platformTheme,
}: SetPasswordBodyProps) {
    return (
        <div className="set-password-body">
            <SetPasswordBodyForm platformTheme={platformTheme} />
        </div>
    );
}
