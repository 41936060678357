import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";

//Components
import RecoverPasswordBodyForm from "@/central-app/components/extras/recover-password/body-form";

interface RecoverPasswordBodyProps {
    platformTheme: PlatformTheme;
}

export default function RecoverPasswordBody({
    platformTheme,
}: RecoverPasswordBodyProps) {
    return (
        <div className="recover-password-body">
            <RecoverPasswordBodyForm platformTheme={platformTheme} />
        </div>
    );
}
