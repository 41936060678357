import { PlatformTheme } from "@/shared/utils/enums";

//components
import SetPasswordHeader from "@/central-app/components/page/set-password/Header";
import SetPasswordBody from "@/central-app/components/page/set-password/Body";

interface SetPasswordProps {
    platformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function SetPassword({
    platformTheme,
    setPlatformTheme,
}: SetPasswordProps) {
    return (
        <div
            className={`central-app ${platformTheme === PlatformTheme.LIGHT ? "login-light" : "login-dark"}`}
        >
            <SetPasswordHeader setPlatformTheme={setPlatformTheme} />
            <SetPasswordBody platformTheme={platformTheme} />
        </div>
    );
}
