import { useState, useEffect } from "react";
import { useToast } from "@/shared/utils/classes";
import { CreateEmployee } from "@/admin-portal/actions/create-employee/CreateEmployee.action";
import {
    CityInterface,
    RegionInterface,
} from "@/admin-portal/utils/interfaces";
import {
    formatPhoneNumber,
    formatPassportNumber,
    formatIdentityDocumentNumber,
    validateFirstName,
    validateLastName,
    validateEmail,
    validateEmployeeType,
    validateIdentityNumber,
    validatePassport,
    validatePhoneNumber,
    validateStreet,
    formatStreetNumber,
    validateStreetNumber,
    validateApartmentNumber,
    formatApartmentNumber,
    validateRegion,
    validateCity,
} from "@/admin-portal/utils/classes";
import {
    Box,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from "@mui/material";

//Icons
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import HomeIcon from "@mui/icons-material/Home";
import TagIcon from "@mui/icons-material/Tag";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { CreateEmployeeRequestInterface } from "@/admin-portal/actions/create-employee/CreateEmployeeRequest.interface";
import { CreateEmployeeResponseInterface } from "@/admin-portal/actions/create-employee/CreateEmployeeResponse.interface";
import { PlatformTheme } from "@/shared/utils/enums";

interface CreateEmployeeFormProps {
    creatingUser: boolean;
    regions: RegionInterface[];
    platformTheme: PlatformTheme;
    cities: Map<string, CityInterface[]>;
    resetPageNumberAndFetchUsers: () => void;
    setCreatingUser: (creatingUser: boolean) => void;
    setIsCreateUserDrawerOpen: (isOpen: boolean) => void;
}

const CreateEmployeeForm = ({
    cities,
    regions,
    creatingUser,
    platformTheme,
    setCreatingUser,
    setIsCreateUserDrawerOpen,
    resetPageNumberAndFetchUsers,
}: CreateEmployeeFormProps) => {
    //#region States
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameValid, setFirstNameValid] = useState<boolean>(true);

    const [lastName, setLastName] = useState<string>("");
    const [lastNameValid, setLastNameValid] = useState<boolean>(true);

    const [email, setEmail] = useState<string>("");
    const [emailValid, setEmailValid] = useState<boolean>(true);

    const [employeeType, setEmployeeType] = useState<string>("");
    const [employeeTypeValid, setEmployeeTypeValid] = useState<boolean>(true);

    const [identityDocumentNumber, setIdentityDocumentNumber] =
        useState<string>("");
    const [identityDocumentNumberValid, setIdentityDocumentNumberValid] =
        useState<boolean>(true);

    const [passport, setPassport] = useState<string>("");
    const [passportValid, setPassportValid] = useState<boolean>(true);

    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);

    const [userRegionId, setUserRegionId] = useState<string>("");
    const [userRegionIdValid, setUserRegionIdValid] = useState<boolean>(true);

    const [userCityId, setUserCityId] = useState<string>("");
    const [userCityIdValid, setUserCityIdValid] = useState<boolean>(true);

    const [street, setStreet] = useState<string>("");
    const [streetValid, setStreetValid] = useState<boolean>(true);

    const [streetNumber, setStreetNumber] = useState<string>("");
    const [streetNumberValid, setStreetNumberValid] = useState<boolean>(true);

    const [apartment, setApartment] = useState<string>("");
    const [apartmentValid, setApartmentValid] = useState<boolean>(true);

    const [selectedDocumentType, setSelectedDocumentType] = useState<
        "nationalIdentityNumber" | "passport"
    >("nationalIdentityNumber");

    const [alreadyValidated, setAlreadyValidated] = useState<boolean>(false);

    //#endregion States

    const employeeTypes = [
        "Administrador",
        "Operador de Almacén",
        "Servicio al Cliente",
        "Transportista",
    ];

    useEffect(() => {
        if (creatingUser) {
            setAlreadyValidated(true);

            const isFormValid = validateEmployeeForm();

            if (!isFormValid) {
                useToast.toastRequest({
                    message:
                        "Por favor, revisa los campos del formulario y asegúrate de que toda la información sea correcta antes de continuar.",
                    type: "warning",
                });

                setCreatingUser(false);
                return;
            }

            createEmployee({
                firstName,
                lastName,
                employeeType: translateEmployeeType(employeeType),
                email,
                identityDocumentNumber:
                    selectedDocumentType === "nationalIdentityNumber"
                        ? identityDocumentNumber
                        : passport,
                phoneNumber,
                cityId: userCityId,
                street,
                streetNumber,
                apartmentNumber: apartment,
            });
        }
    }, [creatingUser]);

    const validateEmployeeForm = (): boolean => {
        const validations = [
            validateFirstName(firstName, setFirstNameValid),
            validateLastName(lastName, setLastNameValid),
            validateEmail(email, setEmailValid),
            validateEmployeeType(employeeType, setEmployeeTypeValid),
            selectedDocumentType === "nationalIdentityNumber"
                ? validateIdentityNumber(
                      identityDocumentNumber,
                      setIdentityDocumentNumberValid
                  )
                : validatePassport(passport, setPassportValid),
            validatePhoneNumber(phoneNumber, setPhoneNumberValid),
            validateRegion(userRegionId, setUserRegionIdValid),
            validateCity(userCityId, setUserCityIdValid),
            validateStreet(street, setStreetValid),
            validateStreetNumber(streetNumber, setStreetNumberValid),
            validateApartmentNumber(apartment, setApartmentValid),
        ];

        return validations.every((isValid) => isValid);
    };

    const createEmployee = async (
        employeeData: CreateEmployeeRequestInterface
    ) => {
        const { httpStatusCode }: CreateEmployeeResponseInterface =
            await useToast.toastPromiseRequest({
                promise: CreateEmployee(employeeData),
                pendingMessage: "Creación de empleado en progreso",
                successMessage: "Creación de empleado exitosa",
            });

        handleEmployeeCreationResult(httpStatusCode);
    };

    const handleEmployeeCreationResult = (httpStatusCode: number) => {
        const wasEmployeeCreationSuccessful =
            checkIfEmployeeCreationWasSuccessful(httpStatusCode);

        if (wasEmployeeCreationSuccessful) {
            setIsCreateUserDrawerOpen(false);
            resetPageNumberAndFetchUsers();
        }

        setCreatingUser(false);
    };

    const translateEmployeeType = (employeeType: string): string => {
        switch (employeeType) {
            case "Administrador":
                return "Admin";
            case "Operador de Almacén":
                return "Warehouse Operator";
            case "Servicio al Cliente":
                return "Customer Service";
            case "Transportista":
                return "Carrier";
            default:
                return "";
        }
    };

    const checkIfEmployeeCreationWasSuccessful = (
        httpStatusCode: number
    ): boolean => {
        if (httpStatusCode === 200 || httpStatusCode === 201) {
            return true;
        }

        return false;
    };

    return (
        <>
            <Box>
                <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                    <Box sx={{ marginRight: "10px" }}>
                        <InputLabel
                            htmlFor="admin-create-user-name-input"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Nombre
                        </InputLabel>
                        <TextField
                            id="admin-create-user-name-input"
                            size="small"
                            placeholder="Ingresa el nombre"
                            autoComplete="nope"
                            error={!firstNameValid}
                            value={firstName}
                            onChange={(e) => {
                                if (alreadyValidated) {
                                    validateFirstName(
                                        e.target.value,
                                        setFirstNameValid
                                    );
                                }

                                setFirstName(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon
                                            sx={{
                                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Box sx={{ marginLeft: "10px" }}>
                        <InputLabel
                            htmlFor="admin-create-user-lastname-input"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Apellido
                        </InputLabel>
                        <TextField
                            id="admin-create-user-lastname-input"
                            size="small"
                            placeholder="Ingresa el apellido"
                            autoComplete="nope"
                            error={!lastNameValid}
                            value={lastName}
                            onChange={(e) => {
                                if (alreadyValidated) {
                                    validateLastName(
                                        e.target.value,
                                        setLastNameValid
                                    );
                                }

                                setLastName(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon
                                            sx={{
                                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-employee-type-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Tipo de Empleado
                    </InputLabel>
                    <Select
                        id="admin-create-user-employee-type-select"
                        displayEmpty
                        error={!employeeTypeValid}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateEmployeeType(
                                    e.target.value as string,
                                    setEmployeeTypeValid
                                );
                            }

                            setEmployeeType(e.target.value as string);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected: string) =>
                            !selected ? (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    Selecciona el tipo de empleado
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                    }}
                                >
                                    {selected}
                                </Typography>
                            )
                        }
                        size="small"
                        inputProps={{
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                },
                            "& .MuiSvgIcon-root": {
                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                            },
                        }}
                    >
                        <MenuItem disabled>
                            <em>Selecciona el tipo de empleado</em>
                        </MenuItem>
                        {employeeTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-email-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Correo Electrónico
                    </InputLabel>
                    <TextField
                        id="admin-create-user-email-input"
                        size="small"
                        placeholder="Ingresa el correo electrónico"
                        autoComplete="nope"
                        error={!emailValid}
                        value={email}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateEmail(e.target.value, setEmailValid);
                            }

                            setEmail(e.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginRight: "10px" }}>
                        <RadioGroup
                            row
                            value={selectedDocumentType}
                            onChange={() =>
                                setSelectedDocumentType(
                                    selectedDocumentType ===
                                        "nationalIdentityNumber"
                                        ? "passport"
                                        : "nationalIdentityNumber"
                                )
                            }
                            sx={{ marginTop: "10px" }}
                        >
                            <FormControlLabel
                                value="nationalIdentityNumber"
                                control={
                                    <Radio
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                        }}
                                    />
                                }
                                label="Rut"
                                sx={{
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                    ".Mui-checked": {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="passport"
                                control={
                                    <Radio
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                        }}
                                    />
                                }
                                label="Pasaporte"
                                sx={{
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}`,
                                    ".Mui-checked": {
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgb(25, 118, 210) !important" : "#7B32E0 !important"}`,
                                    },
                                }}
                            />
                        </RadioGroup>

                        {selectedDocumentType === "nationalIdentityNumber" ? (
                            <Box sx={{ width: "100%" }}>
                                <InputLabel
                                    htmlFor="admin-create-user-identity-input"
                                    sx={{
                                        marginBottom: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                >
                                    Rut
                                </InputLabel>

                                <TextField
                                    id="admin-create-user-identity-input"
                                    size="small"
                                    placeholder="Ingresa el Rut"
                                    autoComplete="nope"
                                    fullWidth
                                    error={!identityDocumentNumberValid}
                                    value={identityDocumentNumber}
                                    onChange={(e) => {
                                        const lastChar =
                                            e.target.value[
                                                e.target.value.length - 1
                                            ];
                                        const lastCharRegex =
                                            /^[\s\S]*[0-9kK.-]$/;

                                        if (
                                            alreadyValidated &&
                                            lastCharRegex.test(lastChar)
                                        ) {
                                            validateIdentityNumber(
                                                e.target.value,
                                                setIdentityDocumentNumberValid
                                            );
                                        }

                                        formatIdentityDocumentNumber(
                                            e,
                                            setIdentityDocumentNumber
                                        );
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RecentActorsIcon
                                                    sx={{
                                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                                },
                                            "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                                },
                                            "&.Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                    },
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ width: "100%" }}>
                                <InputLabel
                                    htmlFor="admin-create-user-passport-input"
                                    sx={{
                                        marginBottom: "5px",
                                        color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                                    }}
                                >
                                    Pasaporte
                                </InputLabel>
                                <TextField
                                    id="admin-create-user-passport-input"
                                    size="small"
                                    placeholder="Ingresa el Pasaporte"
                                    autoComplete="nope"
                                    fullWidth
                                    value={passport}
                                    error={!passportValid}
                                    onChange={(e) => {
                                        const lastChar =
                                            e.target.value[
                                                e.target.value.length - 1
                                            ];
                                        const lastCharRegex =
                                            /^[\s\S]*[a-zA-Z0-9]$/;

                                        if (
                                            alreadyValidated &&
                                            lastCharRegex.test(lastChar)
                                        ) {
                                            validatePassport(
                                                e.target.value,
                                                setPassportValid
                                            );
                                        }

                                        formatPassportNumber(e, setPassport);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RecentActorsIcon
                                                    sx={{
                                                        fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                                },
                                            "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                                },
                                            "&.Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                                    },
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginLeft: "10px", marginTop: "52px" }}>
                        <InputLabel
                            htmlFor="admin-create-user-phone-number-input"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Número de Teléfono
                        </InputLabel>
                        <TextField
                            id="admin-create-user-phone-number-input"
                            size="small"
                            placeholder="Ingresa el número"
                            autoComplete="nope"
                            value={phoneNumber}
                            error={!phoneNumberValid}
                            onChange={(e) => {
                                if (alreadyValidated) {
                                    validatePhoneNumber(
                                        e.target.value,
                                        setPhoneNumberValid
                                    );
                                }

                                formatPhoneNumber(
                                    e.target.value,
                                    setPhoneNumber
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ContactPhoneIcon
                                            sx={{
                                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                    <Box
                        sx={{
                            marginRight: "10px",
                            maxWidth: "50%",
                            width: "50%",
                        }}
                    >
                        <InputLabel
                            htmlFor="admin-create-user-user-region-select"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Región
                        </InputLabel>
                        <Select
                            id="admin-create-user-user-region-select"
                            displayEmpty
                            error={!userRegionIdValid}
                            onChange={(e) => {
                                const regionId =
                                    regions.find(
                                        (region) =>
                                            region.name === e.target.value
                                    )?.id || "";

                                if (alreadyValidated) {
                                    validateRegion(
                                        regionId,
                                        setUserRegionIdValid
                                    );
                                }

                                setUserRegionId(regionId);
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected: string) =>
                                !selected ? (
                                    <Typography
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                        }}
                                    >
                                        Selecciona la región
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                        }}
                                    >
                                        {selected}
                                    </Typography>
                                )
                            }
                            size="small"
                            sx={{
                                width: "100%",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                    },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                "& .MuiSvgIcon-root": {
                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                },
                            }}
                        >
                            <MenuItem disabled>
                                <em>Selecciona la región</em>
                            </MenuItem>
                            {regions.map((region) => (
                                <MenuItem key={region.id} value={region.name}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box
                        sx={{
                            marginLeft: "10px",
                            maxWidth: "50%",
                            width: "50%",
                        }}
                    >
                        <InputLabel
                            htmlFor="admin-create-user-user-city-select"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Comuna
                        </InputLabel>
                        <Select
                            id="admin-create-user-user-city-select"
                            displayEmpty
                            error={!userCityIdValid}
                            onChange={(e) => {
                                const cityId =
                                    cities
                                        .get(userRegionId!)!
                                        .find(
                                            (city) =>
                                                city.name === e.target.value
                                        )?.id || "";

                                if (alreadyValidated) {
                                    validateCity(cityId, setUserCityIdValid);
                                }

                                setUserCityId(cityId);
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected: string) =>
                                !selected ? (
                                    <Typography
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                        }}
                                    >
                                        Selecciona la comuna
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)"}`,
                                        }}
                                    >
                                        {selected}
                                    </Typography>
                                )
                            }
                            disabled={!userRegionId}
                            size="small"
                            sx={{
                                width: "100%",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && userRegionId && "#FFFFFF"}`,
                                    },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                "& .MuiSvgIcon-root": {
                                    fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                },
                                "&.Mui-disabled .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "#333333"}`,
                                    },
                                "&.Mui-disabled .MuiInputBase-input": {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                                    "-webkit-text-fill-color": `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.38)"}`,
                                },
                            }}
                        >
                            <MenuItem disabled>
                                <em>Selecciona la comuna</em>
                            </MenuItem>
                            {userRegionId &&
                                cities.get(userRegionId!)!.map((city) => (
                                    <MenuItem key={city.id} value={city.name}>
                                        {city.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                </Box>

                <Box sx={{ width: "100%", marginTop: "17.5px" }}>
                    <InputLabel
                        htmlFor="admin-create-user-street-input"
                        sx={{
                            marginBottom: "5px",
                            color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                        }}
                    >
                        Calle
                    </InputLabel>
                    <TextField
                        id="admin-create-user-street-input"
                        size="small"
                        placeholder="Ingresa la calle"
                        autoComplete="nope"
                        error={!streetValid}
                        value={street}
                        onChange={(e) => {
                            if (alreadyValidated) {
                                validateStreet(e.target.value, setStreetValid);
                            }

                            setStreet(e.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon
                                        sx={{
                                            fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                },
                                "&.Mui-focused": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                <Box sx={{ display: "flex", marginTop: "17.5px" }}>
                    <Box sx={{ marginRight: "10px" }}>
                        <InputLabel
                            htmlFor="admin-create-user-street-number-input"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Número de Calle
                        </InputLabel>
                        <TextField
                            id="admin-create-user-street-number-input"
                            size="small"
                            placeholder="Número de calle"
                            autoComplete="nope"
                            value={streetNumber}
                            error={!streetNumberValid}
                            onChange={(e) => {
                                if (alreadyValidated) {
                                    validateStreetNumber(
                                        e.target.value,
                                        setStreetNumberValid
                                    );
                                }

                                formatStreetNumber(
                                    e.target.value,
                                    setStreetNumber
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TagIcon
                                            sx={{
                                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Box sx={{ marginLeft: "10px" }}>
                        <InputLabel
                            htmlFor="admin-create-user-apartment-input"
                            sx={{
                                marginBottom: "5px",
                                color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.9)"}`,
                            }}
                        >
                            Número (Apto/Oficina) (Opcional)
                        </InputLabel>
                        <TextField
                            id="admin-create-user-apartment-input"
                            size="small"
                            placeholder="Ingresa el número"
                            autoComplete="nope"
                            value={apartment}
                            error={!apartmentValid}
                            onChange={(e) => {
                                if (alreadyValidated) {
                                    validateApartmentNumber(
                                        e.target.value,
                                        setApartmentValid
                                    );
                                }

                                formatApartmentNumber(
                                    e.target.value,
                                    setApartment
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TagIcon
                                            sx={{
                                                fill: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.75)"}`,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    color: `${platformTheme === PlatformTheme.LIGHT ? "rgba(0, 0, 0, 0.87)" : "#fff"}`,
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `${platformTheme === PlatformTheme.DARK && "#333333"}`,
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "#FFFFFF"}`,
                                        },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: `${platformTheme === PlatformTheme.DARK && "rgb(76, 0, 153)"}`,
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CreateEmployeeForm;
