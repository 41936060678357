import { useAxios } from "@/shared/utils/classes";
import { UpdateCompanyUserRequestInterface } from "@/admin-portal/actions/update-company-user/UpdateCompanyRequest.interface";
import { UpdateCompanyUserResponseInterface } from "@/admin-portal/actions/update-company-user/UpdateCompanyResponse.interface";

export async function UpdateCompanyUser(
    companyUserData: UpdateCompanyUserRequestInterface
): Promise<UpdateCompanyUserResponseInterface> {
    const {
        id,
        firstName,
        lastName,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    } = companyUserData;

    const axios = new useAxios();

    const response = await axios.put("user/update-company-user", {
        id,
        firstName,
        lastName,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
