import { useAxios } from "@/shared/utils/classes";
import { UpdateEmployeeRequestInterface } from "@/admin-portal/actions/update-employee/UpdateEmployeeRequest.interface";
import { UpdateEmployeeResponseInterface } from "@/admin-portal/actions/update-employee/UpdateEmployeeResponse.interface";

export async function UpdateEmployee(
    employeeData: UpdateEmployeeRequestInterface
): Promise<UpdateEmployeeResponseInterface> {
    const {
        id,
        firstName,
        lastName,
        employeeType,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    } = employeeData;

    const axios = new useAxios();

    const response = await axios.put("user/update-employee", {
        id,
        firstName,
        lastName,
        employeeType,
        email,
        identityDocumentNumber,
        phoneNumber,
        cityId,
        street,
        streetNumber,
        apartmentNumber,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
