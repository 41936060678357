import { useAxios } from "@/shared/utils/classes";
import { DeleteUserRequestInterface } from "@/admin-portal/actions/delete-user/DeleteUserRequest.interface";
import { DeleteUserResponseInterface } from "@/admin-portal/actions/delete-user/DeleteUserResponse.interface";

export async function DeleteUser(
    userData: DeleteUserRequestInterface
): Promise<DeleteUserResponseInterface> {
    const { id } = userData;

    const axios = new useAxios();

    const response = await axios.delete("user/delete", {
        id,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
