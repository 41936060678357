import React, { useState } from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { getPlatformTheme } from "@/shared/utils/classes";

//components
import SwitchTheme from "@/shared/components/SwitchTheme";

interface SetPasswordHeaderProps {
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function SetPasswordHeader({
    setPlatformTheme,
}: SetPasswordHeaderProps) {
    const [platformTheme] = useState<PlatformTheme>(getPlatformTheme());

    return (
        <header className="set-password-header">
            <SwitchTheme
                currentTheme={platformTheme!}
                setPlatformThemeState={setPlatformTheme}
            />
        </header>
    );
}
