import { useState, useEffect, useRef } from "react";

type UseBarcodeScannerProps = {
    onScan: (data: string) => void;
    onError: (error: string) => void;
};

const useBarcodeScanner = ({ onScan, onError }: UseBarcodeScannerProps) => {
    const [scanning, setScanning] = useState(false);
    const barcodeRef = useRef<string>("");
    const lastKeyTimeRef = useRef<number>(0);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.repeat) return;

            const currentTime = Date.now();
            const timeDiff = currentTime - lastKeyTimeRef.current;

            if (!barcodeRef.current) {
                initiateScan(event.key, currentTime);
                return;
            }

            if (timeDiff < 30 && event.key !== "Enter") {
                appendKeyToBarcode(event.key, currentTime);
                return;
            }

            if (event.key === "Enter") {
                processBarcode();
            } else {
                resetScanner();
            }
        };

        const eventListener = (event: KeyboardEvent) => handleKeyDown(event);
        window.addEventListener("keydown", eventListener);
        return () => window.removeEventListener("keydown", eventListener);
    }, []);

    const initiateScan = (key: string, currentTime: number) => {
        setScanning(true);
        barcodeRef.current = key;
        lastKeyTimeRef.current = currentTime;
    };

    const appendKeyToBarcode = (key: string, currentTime: number) => {
        barcodeRef.current += key;
        lastKeyTimeRef.current = currentTime;
    };

    const processBarcode = () => {
        if (isValidBarcode(barcodeRef.current)) {
            onScan(barcodeRef.current);
        } else {
            onError(
                "El código escaneado no es válido. Por favor, intente nuevamente."
            );
        }

        resetScanner();
    };

    const resetScanner = () => {
        setScanning(false);
        barcodeRef.current = "";
        lastKeyTimeRef.current = 0;
    };

    const isValidBarcode = (barcode: string) => {
        const barcodeRegex = /^(?:\d{8}|\d{12}|\d{13}|\d{14})$/;
        return barcodeRegex.test(barcode);
    };

    return { scanning };
};

export default useBarcodeScanner;
