import React from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { UserInterface } from "@/shared/utils/interfaces";

//components
import ComponentWithAsideMenu from "@/shared/components/ComponentWithAsideMenu";
import StockEntryPage from "@/warehouse-management/components/page/stock-entry";

interface StockEntryProps {
    user: UserInterface;
    platformTheme: PlatformTheme;
    setPlatformTheme: (theme: PlatformTheme) => void;
}

export default function StockEntry({
    user,
    platformTheme,
    setPlatformTheme,
}: StockEntryProps) {
    return (
        <div
            className={`main-page ${platformTheme === PlatformTheme.LIGHT ? "home-light" : "home-dark"}`}
        >
            <ComponentWithAsideMenu
                user={user}
                notificationsCount={3}
                currentPlatformTheme={platformTheme}
                setPlatformTheme={setPlatformTheme}
                component={
                    <StockEntryPage user={user} platformTheme={platformTheme} />
                }
            />
        </div>
    );
}
