import React from "react";
import { PlatformTheme } from "@/shared/utils/enums";
import { UserInterface } from "@/shared/utils/interfaces";

//components
import { Box } from "@mui/material";
import PageHeader from "@/shared/components/PageHeader";

interface AccountSettingsPageProps {
    user: UserInterface;
    platformTheme: PlatformTheme;
}

export default function AccountSettingsPage({
    user,
    platformTheme,
}: AccountSettingsPageProps) {
    return (
        <Box>
            <PageHeader
                title="Configuración de la Cuenta - Central App"
                description={`¡Hola, ${user.fullName.split(" ")[0]}! En esta plataforma podrás gestionar eficientemente todas las operaciones y usuarios, incluyendo clientes, servicio al cliente, transportistas y operadores de almacén. Tendrás acceso a estadísticas clave, monitoreo de inventarios, coordinación de transportes y verificación de reportes en tiempo real.`}
            />
        </Box>
    );
}
