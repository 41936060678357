import { useAxios } from "@/shared/utils/classes";
import { CreateCompanyRequestInterface } from "@/admin-portal/actions/create-company/CreateCompanyRequest.interface";
import { CreateCompanyResponseInterface } from "@/admin-portal/actions/create-company/CreateCompanyResponse.interface";

export async function CreateCompany(
    companyData: CreateCompanyRequestInterface
): Promise<CreateCompanyResponseInterface> {
    const {
        companyName,
        companyIdentityDocumentNumber,
        companyEmail,
        companyWebsite,
        companyCityId,
        companyStreet,
        companyStreetNumber,
        companyApartmentNumber,
        adminFirstName,
        adminLastName,
        adminEmail,
        adminIdentityDocumentNumber,
        adminPhoneNumber,
        adminCityId,
        adminStreet,
        adminStreetNumber,
        adminApartmentNumber,
    } = companyData;

    const axios = new useAxios();

    const response = await axios.post("company/create", {
        companyName,
        companyIdentityDocumentNumber,
        companyEmail,
        companyWebsite,
        companyCityId,
        companyStreet,
        companyStreetNumber,
        companyApartmentNumber,
        adminFirstName,
        adminLastName,
        adminEmail,
        adminIdentityDocumentNumber,
        adminPhoneNumber,
        adminCityId,
        adminStreet,
        adminStreetNumber,
        adminApartmentNumber,
    });

    return {
        httpStatusCode: response.httpStatus,
        message: response.message,
    };
}
