import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import {
    InventoryIcon,
    AddCircleIcon,
    RemoveCircleIcon,
    FormatListBulletedIcon,
} from "./Icons";

interface WarehouseOperatorSectionProps {
    open: boolean;
    currentPathLocation: string;
    changeLocation: (path: string) => void;
}

export function WarehouseOperatorSection({
    open,
    currentPathLocation,
    changeLocation,
}: WarehouseOperatorSectionProps) {
    return (
        <>
            <List>
                <ListItem
                    key={"Inventario"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Gestionar Inventario de Almacén"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/warehouse-operator/manage-inventory"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/warehouse-operator/manage-inventory"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <InventoryIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Inventario"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Ingreso de Productos"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Ingreso de Productos a Almacén"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/warehouse-operator/stock-entry"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/warehouse-operator/stock-entry"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <AddCircleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Ingreso de Productos"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Salida de Productos"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Salida de Productos del Almacén"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/warehouse-operator/stock-dispatch"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/warehouse-operator/stock-dispatch"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <RemoveCircleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Salida de Productos"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>

                <ListItem
                    key={"Historial de Movimientos"}
                    disablePadding
                    sx={{ display: "block" }}
                >
                    <Tooltip
                        title="Verificar Historial de Movimientos de Almacén"
                        placement="right"
                        arrow
                    >
                        <ListItemButton
                            selected={
                                currentPathLocation ===
                                "/warehouse-operator/warehouse-orders-history"
                            }
                            onClick={() =>
                                changeLocation(
                                    "/warehouse-operator/warehouse-orders-history"
                                )
                            }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Historial de Movimientos"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>

            <Divider />
        </>
    );
}
