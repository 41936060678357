import { PlatformTheme } from "@/shared/utils/enums";

export const getPlatformTheme = (): PlatformTheme => {
    let platformTheme = localStorage.getItem("platform-theme");

    if (!platformTheme) {
        const lightThemeText = PlatformTheme.LIGHT.toString();

        localStorage.setItem("platform-theme", lightThemeText);
        platformTheme = lightThemeText;
    }

    const themeEnum: PlatformTheme =
        getPlatformThemeEnum(platformTheme) || PlatformTheme.LIGHT;
    return themeEnum;
};

const getPlatformThemeEnum = (theme: string): PlatformTheme | undefined => {
    if (
        Object.values(PlatformTheme).includes(theme as unknown as PlatformTheme)
    ) {
        return theme as unknown as PlatformTheme;
    }

    return undefined;
};
